import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "et", default = false)
val EtStrings = Strings(
    projectNameDialogIntro1 = "Tere! Siin loome numbripiletite teenuse",
	projectNameDialogIntro2 = "Palun sisestage oma poe nimi:",
	projectNameDialogShopName = "Poenimi",
	submit = "Esita",
	projectOptionsDialogIntro = { "Tere, ${it}, millist teenust soovite:" },
	projectOptionsDialogShopType0Title = "Lihtne numbrivalimisteenus",
	projectOptionsDialogShopType0Description = "Poepidaja saab numbreid helistada lihtsa puudutusega rakendusele, muutes protsessi kiireks ja tõhusaks.",
	projectOptionsDialogShopType1Title = "Kliendi numbriteenindus",
	projectOptionsDialogShopType1Description = "Kliendid saavad võtta numbri ja oodata numbri kutsumist, tagades, et ooteprotsess on sujuv ja kiire.",
	projectOptionsDialogShopType2Title = "Pakkuge numbriteenust",
	projectOptionsDialogShopType2Description = "Poepidaja annab numbri ja kohandab numbrikõne kogemust vastavalt teie ettevõtte vajadustele kohandatavate seadistustega.",
	vendorConsoleStartRunning = "Alusta jooksmist",
	numberDisplayScreenTitle = "Võtke number siit!",
	qrCodeDescription = { "QR-kood, mis viitab ${it} veebisaidile" },
	takeNumberScreenInputPhoneNumber = "Sisestage oma telefoninumber",
	takeNumberScreenPhoneNumber = "Telefoninumber",
	provideNumberPadGetNumber = "Hangi number",
	provideNumberPadCurrentNumber = "Praegune number",
	provideNumberPadYourNumber = { "Teie number on ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Praegune ootenumber: ${it}" },
	displayNumberTitle = "Numbrinäidiku leht",
	customerTakeNumberTitle = "Kliendi numbri leht",
	provideNumberTitle = "Pakkuge numbrileht",
	yourNumberTitle = "Teie numbrileht",
	callNumberTitle = "Kõnenumbri leht",
	setting = "Seadistus",
	callNumberPadSoundSwitch = "Heli Sees/Väljas",
	shutDown = "Lülita välja",
	ok = "Ok",
	cancel = "Tühista",
	startRunningShop = "Alusta poe käitamist",
	getToWork = "Hakkame tööle!",
	uploadSuccess = "Üleslaadimine õnnestus!",
	uploadFailed = "Üleslaadimine nurjus!",
	imagePickerDialogTitle = "Uuenda oma poe logo",
	imagePickerDialogAction = "Valige pilt",
	cropImageDialogTitle = "Kinnitage oma logo kuvamine",
	editShopNameDialogTitle = "Uuenda oma poe nime"
)