package tim.huang.genlayout.ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import genlayout.composeapp.generated.resources.Res
import genlayout.composeapp.generated.resources.arrow_downward
import genlayout.composeapp.generated.resources.bell_ringing
import genlayout.composeapp.generated.resources.ic_close
import org.jetbrains.compose.resources.painterResource
import tim.huang.genlayout.model.NumberPad

@Composable
fun CallNumberScreen(data: NumberPad, modifier: Modifier = Modifier) {

    val (model, numbers, curNumber, clickAction) = data

    Layout(
        modifier = modifier,
        content = {
            NumberDisplayLED(
                number = curNumber.value,
                model = model,
                modifier = Modifier.border(2.dp, MaterialTheme.colors.primary)
            )
            ButtonPad(
                clickAction,
                numbers,
                modifier = Modifier.border(2.dp, MaterialTheme.colors.primary)
            )
        }){ measurables, constraints ->

        val width = (constraints.maxHeight * 3 / 4).coerceAtMost(constraints.maxWidth)

        val placeables = measurables.mapIndexed { index, measurable ->
            // NumberDisplayScreen take 1/4 of the height, NumberPad take 3/4 of the height
            if (index == 0){
                measurable.measure(constraints.copy(
                    minWidth = width,
                    maxWidth = width,
                    minHeight = constraints.maxHeight / 4,
                    maxHeight = constraints.maxHeight / 4,
                ))
            }else{
                measurable.measure(constraints.copy(
                    minWidth = width,
                    maxWidth = width,
                    minHeight = constraints.maxHeight * 3 / 4,
                    maxHeight = constraints.maxHeight * 3 / 4 ,
                ))
            }
        }

        layout(constraints.maxWidth, constraints.maxHeight) {

            val xPosition = ((constraints.maxWidth - width) / 2).coerceAtLeast(0)
            var yPosition = 0
            for (placeable in placeables) {
                placeable.place(xPosition, yPosition)
                yPosition += placeable.height
            }
        }
    }

}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ButtonPad(clickAction: (String) -> Unit, numbers: List<String>, modifier: Modifier = Modifier) {

    val windowSizeClass = calculateWindowSizeClass()

    val gap = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact -> 4.dp
        else -> 8.dp
    }

    val halfGap = gap / 2

    val fontStyle = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact -> MaterialTheme.typography.h6
        WindowWidthSizeClass.Medium -> MaterialTheme.typography.h5
        else -> MaterialTheme.typography.h4
    }



    Column(
        modifier = modifier.fillMaxSize().background(MaterialTheme.colors.background),
        verticalArrangement = Arrangement.SpaceAround
    ) {

        Spacer(modifier = Modifier.height(gap))

        for (i in numbers.indices step 3) {
            Row(
                modifier = Modifier
                    .weight(1f)
                    .fillMaxWidth()
                    .padding(gap),
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                Spacer(modifier = Modifier.width(gap))

                for (j in i until (i + 3).coerceAtMost(numbers.size)) {

                    Button(
                        onClick = { clickAction(numbers[j]) },
                        modifier = Modifier
                            .weight(1f)
                            .clip(RoundedCornerShape(50))
                            .fillMaxSize()

                    ) {
                        when {
                            numbers[j] == "X" -> {
                                Image(
                                    painterResource(Res.drawable.ic_close),
                                    modifier = Modifier.fillMaxSize().padding(halfGap),
                                    contentDescription = "Close",
                                )
                            }
                            numbers[j] == "↑" -> {
                                Image(
                                    painterResource(Res.drawable.arrow_downward),
                                    modifier = Modifier.fillMaxSize().rotate(180f).padding(halfGap),
                                    contentDescription = "Up Arrow",
                                )
                            }
                            numbers[j] == "↓" -> {
                                Image(
                                    painterResource(Res.drawable.arrow_downward),
                                    modifier = Modifier.fillMaxSize().padding(halfGap),
                                    contentDescription = "Down Arrow",
                                )
                            }
                            numbers[j] == "R" -> {
                                Image(
                                    painterResource(Res.drawable.bell_ringing),
                                    modifier = Modifier.fillMaxSize().padding(halfGap),
                                    contentDescription = "Ring Bell",
                                )
                            }
                            else -> {
                                Text(
                                    text = numbers[j],
                                    style = fontStyle,
                                    fontWeight = FontWeight.Bold,
                                    color = Color.White,
                                )
                            }
                        }
                    }

                    Spacer(modifier = Modifier.width(gap))
                }
            }
        }
        Spacer(modifier = Modifier.height(gap))
    }
}