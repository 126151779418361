import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "te", default = false)
val TeStrings = Strings(
    projectNameDialogIntro1 = "హలో! ఇక్కడ మేము ఒక నంబర్ టికెట్ సేవను సృష్టించబోతున్నాము",
	projectNameDialogIntro2 = "దయచేసి మీ షాప్ పేరును నమోదు చేయండి:",
	projectNameDialogShopName = "షాప్ పేరు",
	submit = "సమర్పించండి",
	projectOptionsDialogIntro = { "హాయ్, ${it}, మీరు ఏ విధమైన సేవను కోరుకుంటున్నారు:" },
	projectOptionsDialogShopType0Title = "సింపుల్ నంబర్ కాలింగ్ సేవ",
	projectOptionsDialogShopType0Description = "షాప్ యజమాని/తొలి అనువర్తనంలో ఒక సరళమైన ట్యాప్‌తో నంబర్లను కాల్ చేయవచ్చు, ఇది ప్రక్రియను త్వరగా మరియు సమర్థవంతంగా చేస్తుంది.",
	projectOptionsDialogShopType1Title = "కస్టమర్ నంబర్ సేవ తీసుకోండి",
	projectOptionsDialogShopType1Description = "కస్టమర్లు సంఖ్యను తీసుకుని సంఖ్యను పిలవడానికి వేచి ఉండవచ్చు, వేచి ఉండే ప్రక్రియ మృదువుగా మరియు త్వరగా ఉంటుందని నిర్ధారించండి.",
	projectOptionsDialogShopType2Title = "సంఖ్య సేవ అందించండి",
	projectOptionsDialogShopType2Description = "షాప్ యజమాని / కీపర్ నంబర్ అందించి, అనుకూలీకరించిన సెట్టింగ్‌లతో మీ వ్యాపార అవసరాలకు అనుగుణంగా నంబర్ కాలింగ్ అనుభవాన్ని టైలర్ చేయండి.",
	vendorConsoleStartRunning = "పరుగెత్తడం ప్రారంభించండి",
	numberDisplayScreenTitle = "ఇక్కడ సంఖ్య తీసుకోండి!",
	qrCodeDescription = { "${it} వెబ్‌సైట్‌కి సూచించే QR కోడ్" },
	takeNumberScreenInputPhoneNumber = "మీ ఫోన్ నంబర్‌ను నమోదు చేయండి",
	takeNumberScreenPhoneNumber = "ఫోన్ నంబర్",
	provideNumberPadGetNumber = "సంఖ్యను పొందండి",
	provideNumberPadCurrentNumber = "ప్రస్తుత సంఖ్య",
	provideNumberPadYourNumber = { "మీ సంఖ్య ${it}" },
	info = "సమాచారం",
	callNumberPadCurrentWaitingNumber = { "ప్రస్తుత వేచి ఉన్న సంఖ్య: ${it}" },
	displayNumberTitle = "సంఖ్యా ప్రదర్శన పేజీ",
	customerTakeNumberTitle = "కస్టమర్ నంబర్ పేజీ",
	provideNumberTitle = "సంఖ్యా పేజీని అందించండి",
	yourNumberTitle = "మీ నంబర్ పేజీ",
	callNumberTitle = "కాల్ నంబర్ పేజీ",
	setting = "సెట్టింగ్",
	callNumberPadSoundSwitch = "శబ్దం ఆన్/ఆఫ్",
	shutDown = "ఆఫ్ చేయి",
	ok = "సరే",
	cancel = "రద్దు చేయండి",
	startRunningShop = "షాప్ నడిపించడం ప్రారంభించండి",
	getToWork = "పనిలోకి దిగుదాం!",
	uploadSuccess = "అప్లోడ్ విజయవంతం!",
	uploadFailed = "అప్లోడ్ విఫలమైంది!",
	imagePickerDialogTitle = "మీ షాప్ లోగోను అప్‌డేట్ చేయండి",
	imagePickerDialogAction = "చిత్రాన్ని ఎంచుకోండి",
	cropImageDialogTitle = "మీ లోగోను ప్రదర్శించడానికి ధృవీకరించండి",
	editShopNameDialogTitle = "మీ షాప్ పేరును అప్‌డేట్ చేయండి"
)