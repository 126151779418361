import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "bs", default = false)
val BsStrings = Strings(
    projectNameDialogIntro1 = "Zdravo! Ovdje ćemo kreirati uslugu numerisanih karata",
	projectNameDialogIntro2 = "Unesite ime svoje trgovine:",
	projectNameDialogShopName = "Naziv prodavnice",
	submit = "Pošalji",
	projectOptionsDialogIntro = { "Zdravo, ${it}, koju vrstu usluge želite:" },
	projectOptionsDialogShopType0Title = "Jednostavna usluga pozivanja brojeva",
	projectOptionsDialogShopType0Description = "Vlasnik/čuvar prodavnice može pozivati brojeve jednostavnim dodirom na aplikaciju, čineći proces brzim i efikasnim.",
	projectOptionsDialogShopType1Title = "Usluga uzimanja broja kupca",
	projectOptionsDialogShopType1Description = "Kupci mogu uzeti broj i čekati pozivanje broja, osiguravajući da je proces čekanja gladak i brz.",
	projectOptionsDialogShopType2Title = "Pružiti uslugu broja",
	projectOptionsDialogShopType2Description = "Vlasnik/čuvar prodavnice pruža broj i prilagođava iskustvo pozivanja brojeva kako bi odgovaralo potrebama vašeg poslovanja s prilagodljivim postavkama.",
	vendorConsoleStartRunning = "Počni trčati",
	numberDisplayScreenTitle = "Uzmi broj ovdje!",
	qrCodeDescription = { "QR kod koji se odnosi na web stranicu ${it}" },
	takeNumberScreenInputPhoneNumber = "Unesite svoj broj telefona",
	takeNumberScreenPhoneNumber = "Broj telefona",
	provideNumberPadGetNumber = "Uzmi broj",
	provideNumberPadCurrentNumber = "Trenutni broj",
	provideNumberPadYourNumber = { "Vaš broj je ${it}" },
	info = "Informacije",
	callNumberPadCurrentWaitingNumber = { "Trenutni broj čekanja: ${it}" },
	displayNumberTitle = "Stranica za prikaz brojeva",
	customerTakeNumberTitle = "Stranica broja kupaca",
	provideNumberTitle = "Obezbijedite brojčanu stranicu",
	yourNumberTitle = "Vaša stranica broja",
	callNumberTitle = "Stranica broja poziva",
	setting = "Postavka",
	callNumberPadSoundSwitch = "Zvuk Uključeno/Isključeno",
	shutDown = "Isključi",
	ok = "Ok",
	cancel = "Otkaži",
	startRunningShop = "Počni s radom prodavnice",
	getToWork = "Hajde da radimo!",
	uploadSuccess = "Uspješno učitavanje!",
	uploadFailed = "Učitavanje nije uspjelo!",
	imagePickerDialogTitle = "Ažurirajte logo vaše prodavnice",
	imagePickerDialogAction = "Odaberite sliku",
	cropImageDialogTitle = "Potvrdite da prikažete svoj logo",
	editShopNameDialogTitle = "Ažurirajte naziv vaše prodavnice"
)