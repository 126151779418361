import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "my", default = false)
val MyStrings = Strings(
    projectNameDialogIntro1 = "ဟယ်လို! ဒီမှာနံပါတ်လက်မှတ်ဝန်ဆောင်မှုတစ်ခုကိုဖန်တီးမှာဖြစ်ပါတယ်",
	projectNameDialogIntro2 = "ကျေးဇူးပြု၍ သင့်ဆိုင်နာမည်ကိုထည့်ပါ:",
	projectNameDialogShopName = "ဆိုင်အမည်",
	submit = "တင်ပါ",
	projectOptionsDialogIntro = { "ဟလို၊ ${it}၊ သင့်အတွက် ဘယ်လိုဝန်ဆောင်မှု လိုချင်ပါသလဲ:" },
	projectOptionsDialogShopType0Title = "ရိုးရိုးနံပါတ်ခေါ်ဆိုသည့်ဝန်ဆောင်မှု",
	projectOptionsDialogShopType0Description = "ဆိုင်ပိုင်ရှင်/ထိန်းသိမ်းသူသည် အက်ပ်ပလီကေးရှင်းတွင် တစ်ချက်နှိပ်ခြင်းဖြင့် နံပါတ်များကို ခေါ်ဆိုနိုင်ပြီး၊ လုပ်ငန်းစဉ်ကို အမြန်နှင့် ထိရောက်စေပါသည်။",
	projectOptionsDialogShopType1Title = "ဖောက်သည်နံပါတ်ယူခြင်းဝန်ဆောင်မှု",
	projectOptionsDialogShopType1Description = "ဖောက်သည်များသည် နံပါတ်ယူပြီး နံပါတ်ခေါ်ရန် စောင့်ဆိုင်းနိုင်ပါသည်၊ စောင့်ဆိုင်းမှုလုပ်ငန်းစဉ်သည် နှံ့နှံ့လျရှင်းလွယ်ရှိစေရန် သေချာစေပါသည်။",
	projectOptionsDialogShopType2Title = "နံပါတ်ဝန်ဆောင်မှု ပေးပါ",
	projectOptionsDialogShopType2Description = "ဆိုင်ပိုင်ရှင်/ထိန်းသိမ်းသူသည် နံပါတ်များပေးပြီး စီးပွားရေးလိုအပ်ချက်များနှင့် ကိုက်ညီအောင် နံပါတ်ခေါ်ဆိုမှု အတွေ့အကြုံကို ကိုယ်တိုင်ချိန်ညှိနိုင်သည့် ဆက်တင်များဖြင့် ပြင်ဆင်ပေးပါသည်။",
	vendorConsoleStartRunning = "ပြေးရလှုပ်ရွက်ရန် စတင်ပါ",
	numberDisplayScreenTitle = "ဤနေရာတွင်နံပါတ်ယူပါ!",
	qrCodeDescription = { "${it} ဝဘ်ဆိုက်ကိုညွှန်းတဲ့ QR ကုဒ်" },
	takeNumberScreenInputPhoneNumber = "သင်၏ဖုန်းနံပါတ်ကို ထည့်ပါ",
	takeNumberScreenPhoneNumber = "ဖုန်းနံပါတ်",
	provideNumberPadGetNumber = "နံပါတ်ရယူပါ",
	provideNumberPadCurrentNumber = "လက်ရှိနံပါတ်",
	provideNumberPadYourNumber = { "သင့်နံပါတ်မှာ ${it} ဖြစ်ပါတယ်" },
	info = "သတင်းအချက်အလက်",
	callNumberPadCurrentWaitingNumber = { "လက်ရှိစောင့်ဆိုင်းနံပါတ်: ${it}" },
	displayNumberTitle = "နံပါတ်ပြသချက် စာမျက်နှာ",
	customerTakeNumberTitle = "ဖောက်သည်နံပါတ် စာမျက်နှာ",
	provideNumberTitle = "နံပါတ်စာမျက်နှာပေးပါ",
	yourNumberTitle = "သင့်နံပါတ်စာမျက်နှာ",
	callNumberTitle = "ခေါ်ဆိုမည့်နံပါတ်စာမျက်နှာ",
	setting = "ဆက်တင်",
	callNumberPadSoundSwitch = "အသံ ဖွင့်/ပိတ်",
	shutDown = "ပိတ်မည်",
	ok = "သေချာပြီ",
	cancel = "ပယ်ဖျက်",
	startRunningShop = "ဆိုင်ကို စတင်ရန်",
	getToWork = "လုပ်ငန်းစတင်ရအောင်!",
	uploadSuccess = "အပ်လုဒ်အောင်မြင်ခဲ့သည်!",
	uploadFailed = "အပ်လုဒ်မအောင်မြင်ပါ!",
	imagePickerDialogTitle = "သင့်ဆိုင်လိုဂိုကို အပ်ဒိတ်လုပ်ပါ",
	imagePickerDialogAction = "ပုံတစ်ပုံရွေးပါ",
	cropImageDialogTitle = "သင့်လိုဂိုကိုပြရန်အတည်ပြုပါ",
	editShopNameDialogTitle = "သင့်ဆိုင်အမည်ကို အပ်ဒိတ်လုပ်ပါ"
)