import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "lv", default = false)
val LvStrings = Strings(
    projectNameDialogIntro1 = "Sveiki! Šeit mēs izveidosim numurētu biļešu pakalpojumu",
	projectNameDialogIntro2 = "Lūdzu, ievadiet sava veikala nosaukumu:",
	projectNameDialogShopName = "Veikala nosaukums",
	submit = "Iesniegt",
	projectOptionsDialogIntro = { "Sveiki, ${it}, kāda veida pakalpojumu vēlaties:" },
	projectOptionsDialogShopType0Title = "Vienkāršs numuru izsaukšanas pakalpojums",
	projectOptionsDialogShopType0Description = "Veikala īpašnieks/uzraugs var zvanīt uz numuriem ar vienkāršu pieskārienu lietotnē, padarot procesu ātru un efektīvu.",
	projectOptionsDialogShopType1Title = "Klientu numuru pakalpojums",
	projectOptionsDialogShopType1Description = "Klienti var paņemt numuru un gaidīt numura izsaukumu, nodrošinot, ka gaidīšanas process ir gluds un ātrs.",
	projectOptionsDialogShopType2Title = "Nodrošināt numuru pakalpojumu",
	projectOptionsDialogShopType2Description = "Veikala īpašnieks/uzraugs nodrošina numuru un pielāgo numuru izsaukšanas pieredzi, lai tā atbilstu jūsu biznesa vajadzībām ar pielāgojamiem iestatījumiem.",
	vendorConsoleStartRunning = "Sāciet skriet",
	numberDisplayScreenTitle = "Paņemiet numuru šeit!",
	qrCodeDescription = { "QR kods, kas attiecas uz ${it} vietni" },
	takeNumberScreenInputPhoneNumber = "Ievadiet savu tālruņa numuru",
	takeNumberScreenPhoneNumber = "Telefona numurs",
	provideNumberPadGetNumber = "Saņemt numuru",
	provideNumberPadCurrentNumber = "Pašreizējais numurs",
	provideNumberPadYourNumber = { "Jūsu numurs ir ${it}" },
	info = "Informācija",
	callNumberPadCurrentWaitingNumber = { "Pašreizējais gaidīšanas numurs: ${it}" },
	displayNumberTitle = "Numuru displeja lapa",
	customerTakeNumberTitle = "Klientu numura lapa",
	provideNumberTitle = "Nodrošiniet skaitlisko lapu",
	yourNumberTitle = "Jūsu numura lapa",
	callNumberTitle = "Zvanu numura lapa",
	setting = "Iestatījums",
	callNumberPadSoundSwitch = "Skaņa Ieslēgta/Izslēgta",
	shutDown = "Izslēgt",
	ok = "Labi",
	cancel = "Atcelt",
	startRunningShop = "Sāciet vadīt veikalu",
	getToWork = "Sāksim strādāt!",
	uploadSuccess = "Augšupielāde veiksmīga!",
	uploadFailed = "Augšupielāde neizdevās!",
	imagePickerDialogTitle = "Atjauniniet veikala logotipu",
	imagePickerDialogAction = "Izvēlieties attēlu",
	cropImageDialogTitle = "Apstipriniet, lai parādītu savu logotipu",
	editShopNameDialogTitle = "Atjauniniet veikala nosaukumu"
)