import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize

internal val firebaseApp: FirebaseApp by lazy {
    val options = FirebaseOptions(
        apiKey =  "AIzaSyAaYVjqeeWnyZR4E8trh8K9U7rwCFDLkIk",
        authDomain =  "table-order-gpt.firebaseapp.com",
        projectId =  "table-order-gpt",
        storageBucket =  "table-order-gpt.appspot.com",
        gcmSenderId =  "651817867687",
        applicationId =  "1:651817867687:web:3a5f79fe99b29f4b31be82",
        gaTrackingId =  "G-PELN18SW9F",
    )

    Firebase.initialize(options = options, name = "table-order-gpt")
}

