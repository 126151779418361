import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "pa", default = false)
val PaStrings = Strings(
    projectNameDialogIntro1 = "ਹੈਲੋ! ਅਸੀਂ ਇੱਥੇ ਇੱਕ ਨੰਬਰ ਟਿਕਟ ਸੇਵਾ ਬਣਾਉਣ ਜਾ ਰਹੇ ਹਾਂ",
	projectNameDialogIntro2 = "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਦੁਕਾਨ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ:",
	projectNameDialogShopName = "ਦੁਕਾਨ ਦਾ ਨਾਮ",
	submit = "ਜਮ੍ਹਾ ਕਰੋ",
	projectOptionsDialogIntro = { "ਹਾਇ, ${it}, ਤੁਸੀਂ ਕਿਸ ਤਰ੍ਹਾਂ ਦੀ ਸੇਵਾ ਚਾਹੁੰਦੇ ਹੋ:" },
	projectOptionsDialogShopType0Title = "ਸਧਾਰਨ ਨੰਬਰ ਕਾਲਿੰਗ ਸੇਵਾ",
	projectOptionsDialogShopType0Description = "ਦੁਕਾਨ ਦਾ ਮਾਲਕ/ਰੱਖਿਆਕਾਰ ਐਪ 'ਤੇ ਇੱਕ ਸਧਾਰਨ ਟੈਪ ਨਾਲ ਨੰਬਰਾਂ ਨੂੰ ਕਾਲ ਕਰ ਸਕਦਾ ਹੈ, ਪ੍ਰਕਿਰਿਆ ਨੂੰ ਤੇਜ਼ ਅਤੇ ਪ੍ਰਭਾਵਸ਼ਾਲੀ ਬਣਾ ਸਕਦਾ ਹੈ।",
	projectOptionsDialogShopType1Title = "ਗਾਹਕ ਨੰਬਰ ਲੈਣ ਦੀ ਸੇਵਾ",
	projectOptionsDialogShopType1Description = "ਗਾਹਕ ਨੰਬਰ ਲੈ ਸਕਦੇ ਹਨ ਅਤੇ ਨੰਬਰ ਬੁਲਾਉਣ ਦੀ ਉਡੀਕ ਕਰ ਸਕਦੇ ਹਨ, ਯਕੀਨੀ ਬਣਾਓ ਕਿ ਉਡੀਕ ਕਰਨ ਦੀ ਪ੍ਰਕਿਰਿਆ ਸਮੂਥ ਅਤੇ ਤੇਜ਼ ਹੈ।",
	projectOptionsDialogShopType2Title = "ਨੰਬਰ ਸੇਵਾ ਪ੍ਰਦਾਨ ਕਰੋ",
	projectOptionsDialogShopType2Description = "ਦੁਕਾਨ ਦਾ ਮਾਲਕ/ਰੱਖਿਆਕਾਰ ਨੰਬਰ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ, ਅਤੇ ਅਨੁਕੂਲਨਯੋਗ ਸੈਟਿੰਗਾਂ ਨਾਲ ਤੁਹਾਡੀਆਂ ਕਾਰੋਬਾਰੀ ਲੋੜਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਨੰਬਰ ਕਾਲਿੰਗ ਅਨੁਭਵ ਨੂੰ ਅਨੁਕੂਲ ਕਰਦਾ ਹੈ।",
	vendorConsoleStartRunning = "ਦੌੜਨਾ ਸ਼ੁਰੂ ਕਰੋ",
	numberDisplayScreenTitle = "ਇੱਥੇ ਨੰਬਰ ਲਵੋ!",
	qrCodeDescription = { "${it} ਵੈਬਸਾਈਟ ਨੂੰ ਦਰਸਾਉਂਦਾ ਕਿਊਆਰ ਕੋਡ" },
	takeNumberScreenInputPhoneNumber = "ਆਪਣਾ ਫੋਨ ਨੰਬਰ ਦਰਜ ਕਰੋ",
	takeNumberScreenPhoneNumber = "ਫੋਨ ਨੰਬਰ",
	provideNumberPadGetNumber = "ਨੰਬਰ ਲਵੋ",
	provideNumberPadCurrentNumber = "ਮੌਜੂਦਾ ਨੰਬਰ",
	provideNumberPadYourNumber = { "ਤੁਹਾਡਾ ਨੰਬਰ ${it} ਹੈ" },
	info = "ਜਾਣਕਾਰੀ",
	callNumberPadCurrentWaitingNumber = { "ਮੌਜੂਦਾ ਉਡੀਕ ਨੰਬਰ: ${it}" },
	displayNumberTitle = "ਨੰਬਰ ਡਿਸਪਲੇ ਪੇਜ",
	customerTakeNumberTitle = "ਗਾਹਕ ਨੰਬਰ ਪੇਜ",
	provideNumberTitle = "ਨੰਬਰ ਪੰਨਾ ਪ੍ਰਦਾਨ ਕਰੋ",
	yourNumberTitle = "ਤੁਹਾਡਾ ਨੰਬਰ ਪੇਜ",
	callNumberTitle = "ਕਾਲ ਨੰਬਰ ਪੇਜ",
	setting = "ਸੈਟਿੰਗ",
	callNumberPadSoundSwitch = "ਸਾਊਂਡ ਆਨ/ਆਫ",
	shutDown = "ਬੰਦ ਕਰੋ",
	ok = "ਠੀਕ ਹੈ",
	cancel = "ਰੱਦ ਕਰੋ",
	startRunningShop = "ਦੁਕਾਨ ਚਲਾਉਣ ਦੀ ਸ਼ੁਰੂਆਤ ਕਰੋ",
	getToWork = "ਚਲੋ ਕੰਮ ਸ਼ੁਰੂ ਕਰੀਏ!",
	uploadSuccess = "ਅਪਲੋਡ ਸਫਲ ਰਿਹਾ!",
	uploadFailed = "ਅਪਲੋਡ ਅਸਫਲ ਰਿਹਾ!",
	imagePickerDialogTitle = "ਆਪਣੇ ਦੁਕਾਨ ਦਾ ਲੋਗੋ ਅਪਡੇਟ ਕਰੋ",
	imagePickerDialogAction = "ਇੱਕ ਚਿੱਤਰ ਚੁਣੋ",
	cropImageDialogTitle = "ਆਪਣਾ ਲੋਗੋ ਵਿਖਾਉਣ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
	editShopNameDialogTitle = "ਆਪਣੇ ਦੁਕਾਨ ਦਾ ਨਾਂਅ ਅਪਡੇਟ ਕਰੋ"
)