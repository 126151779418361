@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package genlayout.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val arrow_downward: DrawableResource by 
      lazy { init_arrow_downward() }

  public val baseline_add: DrawableResource by 
      lazy { init_baseline_add() }

  public val baseline_add_circle_outline: DrawableResource by 
      lazy { init_baseline_add_circle_outline() }

  public val bell_ringing: DrawableResource by 
      lazy { init_bell_ringing() }

  public val camera: DrawableResource by 
      lazy { init_camera() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val edit: DrawableResource by 
      lazy { init_edit() }

  public val ic_close: DrawableResource by 
      lazy { init_ic_close() }

  public val ic_google_logo: DrawableResource by 
      lazy { init_ic_google_logo() }

  public val image_upload: DrawableResource by 
      lazy { init_image_upload() }

  public val power_settings: DrawableResource by 
      lazy { init_power_settings() }

  public val settings: DrawableResource by 
      lazy { init_settings() }
}

public val Res.drawable.arrow_downward: DrawableResource
  get() = CommonMainDrawable0.arrow_downward

private fun init_arrow_downward(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_downward",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/arrow_downward.xml", -1, -1),
    )
)

public val Res.drawable.baseline_add: DrawableResource
  get() = CommonMainDrawable0.baseline_add

private fun init_baseline_add(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/baseline_add.xml", -1, -1),
    )
)

public val Res.drawable.baseline_add_circle_outline: DrawableResource
  get() = CommonMainDrawable0.baseline_add_circle_outline

private fun init_baseline_add_circle_outline(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_add_circle_outline",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/baseline_add_circle_outline.xml", -1, -1),
    )
)

public val Res.drawable.bell_ringing: DrawableResource
  get() = CommonMainDrawable0.bell_ringing

private fun init_bell_ringing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:bell_ringing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/bell_ringing.xml", -1, -1),
    )
)

public val Res.drawable.camera: DrawableResource
  get() = CommonMainDrawable0.camera

private fun init_camera(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:camera",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/camera.xml", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

public val Res.drawable.edit: DrawableResource
  get() = CommonMainDrawable0.edit

private fun init_edit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/edit.xml", -1, -1),
    )
)

public val Res.drawable.ic_close: DrawableResource
  get() = CommonMainDrawable0.ic_close

private fun init_ic_close(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/ic_close.xml", -1, -1),
    )
)

public val Res.drawable.ic_google_logo: DrawableResource
  get() = CommonMainDrawable0.ic_google_logo

private fun init_ic_google_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_google_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/ic_google_logo.xml", -1, -1),
    )
)

public val Res.drawable.image_upload: DrawableResource
  get() = CommonMainDrawable0.image_upload

private fun init_image_upload(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:image_upload",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/image_upload.xml", -1, -1),
    )
)

public val Res.drawable.power_settings: DrawableResource
  get() = CommonMainDrawable0.power_settings

private fun init_power_settings(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:power_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/power_settings.xml", -1, -1),
    )
)

public val Res.drawable.settings: DrawableResource
  get() = CommonMainDrawable0.settings

private fun init_settings(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/drawable/settings.xml", -1, -1),
    )
)
