package tim.huang.genlayout.data

import dev.gitlive.firebase.firestore.Timestamp
import kotlinx.serialization.Serializable

@Serializable
data class SubscriptionData(
    val subscriptionState: String,
    val regionCode: String,
    val lineItems: List<LineItem>,
)

@Serializable
data class LineItem(val productId: String, val expiryTime: String, val offerDetails: OfferDetails, val autoRenewingPlan: AutoRenewingPlan)

@Serializable
data class AutoRenewingPlan(val autoRenewEnabled: Boolean?)

@Serializable
class OfferDetails(val basePlanId: String, val offerTags: List<String>)


@Serializable
data class VendorProject(
    val shopName: String,
    val shopType: Int,
    val shopLogo: String? = null,
    val createdTime: Timestamp? = null
)

@Serializable
data class VendorShop(
    val name: String,
    val userId: String,
    val logo: String?,
    val shopType: Int = 0,
    val number: Int = 0,
    val waitingNumber: Int = 0,
    val createdTime: Timestamp? = null,
    val updatedTime: Timestamp? = null,
)

@Serializable
data class ShopStatus(val isOnline: Boolean, val name: String, val logo:String?, val shopType: Int)