import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "nl", default = false)
val NlStrings = Strings(
    projectNameDialogIntro1 = "Hallo! Hier gaan we een nummerservices creëren",
	projectNameDialogIntro2 = "Voer de naam van uw winkel in:",
	projectNameDialogShopName = "Winkelnaam",
	submit = "Indienen",
	projectOptionsDialogIntro = { "Hoi, ${it}, wat voor soort dienst wil je:" },
	projectOptionsDialogShopType0Title = "Eenvoudige nummeroproepdienst",
	projectOptionsDialogShopType0Description = "Winkelier/beheerder kan nummers oproepen met een eenvoudige tik op de app, waardoor het proces snel en efficiënt verloopt.",
	projectOptionsDialogShopType1Title = "Klanten Nummer Opname Service",
	projectOptionsDialogShopType1Description = "Klanten kunnen een nummer nemen en wachten op het oproepnummer, zodat het wachtproces soepel en snel verloopt.",
	projectOptionsDialogShopType2Title = "Nummerdienst verlenen",
	projectOptionsDialogShopType2Description = "De winkelier/eigenaar verstrekt nummers en past de oproepervaring aan om aan uw zakelijke behoeften te voldoen met aanpasbare instellingen.",
	vendorConsoleStartRunning = "Begin met rennen",
	numberDisplayScreenTitle = "Neem hier een nummer!",
	qrCodeDescription = { "QR-code die verwijst naar de ${it} website" },
	takeNumberScreenInputPhoneNumber = "Voer uw telefoonnummer in",
	takeNumberScreenPhoneNumber = "Telefoonnummer",
	provideNumberPadGetNumber = "Nummer krijgen",
	provideNumberPadCurrentNumber = "Huidig nummer",
	provideNumberPadYourNumber = { "Uw nummer is ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Huidig wachtend nummer: ${it}" },
	displayNumberTitle = "Nummerweergavepagina",
	customerTakeNumberTitle = "Klantnummerpagina",
	provideNumberTitle = "Voorzie een nummerpagina",
	yourNumberTitle = "Uw nummerpagina",
	callNumberTitle = "Oproepnummerpagina",
	setting = "Instelling",
	callNumberPadSoundSwitch = "Geluid Aan/Uit",
	shutDown = "Afsluiten",
	ok = "Oké",
	cancel = "Annuleren",
	startRunningShop = "Start met het runnen van de winkel",
	getToWork = "Laten we aan het werk gaan!",
	uploadSuccess = "Upload succesvol!",
	uploadFailed = "Upload mislukt!",
	imagePickerDialogTitle = "Werk uw winkellogo bij",
	imagePickerDialogAction = "Kies een afbeelding",
	cropImageDialogTitle = "Bevestig om uw logo weer te geven",
	editShopNameDialogTitle = "Werk uw winkelnaam bij"
)