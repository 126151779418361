import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ha", default = false)
val HaStrings = Strings(
    projectNameDialogIntro1 = "Sannu! Anan zamu ƙirƙiri sabis na tikiti mai lamba",
	projectNameDialogIntro2 = "Don Allah shigar da sunan shagon ku:",
	projectNameDialogShopName = "Sunan Shagon",
	submit = "Gabatar",
	projectOptionsDialogIntro = { "Sannu, ${it}, wane irin sabis kake so:" },
	projectOptionsDialogShopType0Title = "Sabis na Kiran Lamba Mai Sauƙi",
	projectOptionsDialogShopType0Description = "Mai gidan/masanin shagon na iya kira da lambobi tare da sauƙaƙan taɓa na'urar, yana mai sauƙaƙe tsarin cikin sauri da inganci.",
	projectOptionsDialogShopType1Title = "Sabis ɗin Lambar Abokin Ciniki",
	projectOptionsDialogShopType1Description = "Abokan ciniki na iya ɗaukar lamba kuma su jira kiran lamba, tabbatar da cewa aikin jiran yana da sauƙi kuma da sauri.",
	projectOptionsDialogShopType2Title = "Ba da Sabis ɗin Lamba",
	projectOptionsDialogShopType2Description = "Mai gidan kasuwa/mai kula da gidan zai bayar da lamba, sannan ya daidaita kwarewar kiran lamba don dacewa da bukatun kasuwancin ku tare da saitunan da za a iya keɓancewa.",
	vendorConsoleStartRunning = "Fara gudu",
	numberDisplayScreenTitle = "Dauki lamba anan!",
	qrCodeDescription = { "Lambar QR mai nuni zuwa gidan yanar gizon ${it}" },
	takeNumberScreenInputPhoneNumber = "Shigar da lambar wayarka",
	takeNumberScreenPhoneNumber = "Lambar waya",
	provideNumberPadGetNumber = "Samu lamba",
	provideNumberPadCurrentNumber = "Lambar yanzu",
	provideNumberPadYourNumber = { "Lambarka ku shine ${it}" },
	info = "Bayanan",
	callNumberPadCurrentWaitingNumber = { "Lambar jiran yanzu: ${it}" },
	displayNumberTitle = "Shafin Nuni na Lamba",
	customerTakeNumberTitle = "Shafin lambar abokin ciniki",
	provideNumberTitle = "Samar da shafin lamba",
	yourNumberTitle = "Shafin Lambarka",
	callNumberTitle = "Shafin Lambar Kira",
	setting = "Saitin",
	callNumberPadSoundSwitch = "Sauti A kunne/A kashe",
	shutDown = "Rufe",
	ok = "To",
	cancel = "Soke",
	startRunningShop = "Fara gudanar da shago",
	getToWork = "Mu fara aiki!",
	uploadSuccess = "An loda nasara!",
	uploadFailed = "An kasa lodawa!",
	imagePickerDialogTitle = "Sabunta tambarin shagon ku",
	imagePickerDialogAction = "Zaɓi hoto",
	cropImageDialogTitle = "Tabbatar da nunin tambarinku",
	editShopNameDialogTitle = "Sabunta sunan shagon ku"
)