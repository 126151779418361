import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "fi", default = false)
val FiStrings = Strings(
    projectNameDialogIntro1 = "Hei! Tässä luomme numeroituja lippupalveluja",
	projectNameDialogIntro2 = "Anna kauppasi nimi:",
	projectNameDialogShopName = "Kaupan nimi",
	submit = "Lähetä",
	projectOptionsDialogIntro = { "Hei, ${it}, millaista palvelua haluat:" },
	projectOptionsDialogShopType0Title = "Yksinkertainen numeronvalintapalvelu",
	projectOptionsDialogShopType0Description = "Kaupan omistaja/hoitaja voi soittaa numeroita yksinkertaisella napautuksella sovelluksessa, mikä tekee prosessista nopean ja tehokkaan.",
	projectOptionsDialogShopType1Title = "Asiakasnumeropalvelu",
	projectOptionsDialogShopType1Description = "Asiakkaat voivat ottaa numeron ja odottaa numeron kutsumista, varmistaen, että odotusprosessi on sujuva ja nopea.",
	projectOptionsDialogShopType2Title = "Tarjoa numeropalvelua",
	projectOptionsDialogShopType2Description = "Kaupan omistaja/pitäjä tarjoaa numeron ja mukauttaa numeronvalintakokemuksen liiketoimintasi tarpeisiin mukautettavilla asetuksilla.",
	vendorConsoleStartRunning = "Aloita juoksu",
	numberDisplayScreenTitle = "Ota numero täältä!",
	qrCodeDescription = { "QR-koodi, joka viittaa ${it}-sivustoon" },
	takeNumberScreenInputPhoneNumber = "Syötä puhelinnumerosi",
	takeNumberScreenPhoneNumber = "Puhelinnumero",
	provideNumberPadGetNumber = "Hanki numero",
	provideNumberPadCurrentNumber = "Nykyinen numero",
	provideNumberPadYourNumber = { "Numerosi on ${it}" },
	info = "Tieto",
	callNumberPadCurrentWaitingNumber = { "Nykyinen odotusnumero: ${it}" },
	displayNumberTitle = "Numeroiden näyttösivu",
	customerTakeNumberTitle = "Asiakasnumerosivu",
	provideNumberTitle = "Tarjoa numerolehti",
	yourNumberTitle = "Sinun numerolehti",
	callNumberTitle = "Soiton numero sivu",
	setting = "Asetus",
	callNumberPadSoundSwitch = "Ääni Päällä/Pois",
	shutDown = "Sammuta",
	ok = "Ok",
	cancel = "Peruuta",
	startRunningShop = "Aloita kaupan pyörittäminen",
	getToWork = "Aloitetaan työt!",
	uploadSuccess = "Lataus onnistui!",
	uploadFailed = "Lataus epäonnistui!",
	imagePickerDialogTitle = "Päivitä kauppasi logo",
	imagePickerDialogAction = "Valitse kuva",
	cropImageDialogTitle = "Vahvista logosi näyttäminen",
	editShopNameDialogTitle = "Päivitä kauppasi nimi"
)