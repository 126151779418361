import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sq", default = false)
val SqStrings = Strings(
    projectNameDialogIntro1 = "Përshëndetje! Këtu do të krijojmë një shërbim bilete me numra",
	projectNameDialogIntro2 = "Ju lutemi shkruani emrin e dyqanit tuaj:",
	projectNameDialogShopName = "Emri i dyqanit",
	submit = "Dërgo",
	projectOptionsDialogIntro = { "Përshëndetje, ${it}, çfarë lloj shërbimi dëshironi:" },
	projectOptionsDialogShopType0Title = "Shërbimi i thirrjeve të numrave të thjeshtë",
	projectOptionsDialogShopType0Description = "Pronari/mbajtësi i dyqanit mund të thërrasë numra me një trokitje të thjeshtë në aplikacion, duke e bërë procesin të shpejtë dhe efikas.",
	projectOptionsDialogShopType1Title = "Shërbimi i Marrjes së Numrit të Klientit",
	projectOptionsDialogShopType1Description = "Klientët mund të marrin një numër dhe të presin për thirrjen e numrit, duke siguruar që procesi i pritjes të jetë i qetë dhe i shpejtë.",
	projectOptionsDialogShopType2Title = "Ofroni shërbimin e numrave",
	projectOptionsDialogShopType2Description = "Pronari/mbajtësi i dyqanit siguron numrin dhe përshtat përvojën e thirrjes së numrit për t'iu përshtatur nevojave tuaja të biznesit me cilësimet e personalizueshme.",
	vendorConsoleStartRunning = "Filloni të vraponi",
	numberDisplayScreenTitle = "Merr numrin këtu!",
	qrCodeDescription = { "Kodi QR që i referohet faqes së internetit ${it}" },
	takeNumberScreenInputPhoneNumber = "Vendosni numrin tuaj të telefonit",
	takeNumberScreenPhoneNumber = "Numri i telefonit",
	provideNumberPadGetNumber = "Merr numrin",
	provideNumberPadCurrentNumber = "Numri aktual",
	provideNumberPadYourNumber = { "Numri juaj është ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Numri aktual i pritjes: ${it}" },
	displayNumberTitle = "Faqja e shfaqjes së numrave",
	customerTakeNumberTitle = "Faqja e numrit të klientit",
	provideNumberTitle = "Ofroni faqen numerike",
	yourNumberTitle = "Faqja juaj e numrit",
	callNumberTitle = "Faqja e numrit të thirrjes",
	setting = "Cilësim",
	callNumberPadSoundSwitch = "Zëri I ndezur/I fikur",
	shutDown = "Fike",
	ok = "Ok",
	cancel = "Anulo",
	startRunningShop = "Filloni të drejtoni dyqanin",
	getToWork = "Le të fillojmë punën!",
	uploadSuccess = "Ngarkimi i suksesshëm!",
	uploadFailed = "Ngarkimi dështoi!",
	imagePickerDialogTitle = "Përditësoni logon e dyqanit tuaj",
	imagePickerDialogAction = "Zgjidhni një imazh",
	cropImageDialogTitle = "Konfirmo për të shfaqur logon tënde",
	editShopNameDialogTitle = "Përditësoni emrin e dyqanit tuaj"
)