package tim.huang.genlayout.viewmodel

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import tim.huang.genlayout.data.operation.DatabaseOperation
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.model.QRcodeInfo
import tim.huang.genlayout.sound.NumberCallingController

class NumberDisplayPadViewModel(
    private val numberCallingController: NumberCallingController,
    private val coroutineScope: CoroutineScope,
    private val operation: DatabaseOperation,
    private val shopId: String,
) {

    val uiState = coroutineScope.launchMolecule(mode = RecompositionMode.Immediate){
        NumberDisplayUiStatePresenter()
    }

    @Composable
    private fun NumberDisplayUiStatePresenter(): NumberDisplayUiState {
        val shopInfo: NumberTicketShop? by operation.receiveShopInfo(shopId)
            .collectAsState(null)

        return if (shopInfo == null){
            NumberDisplayUiState.Loading
        }else{
            NumberDisplayUiState.Display(shopInfo!!)
        }
    }

    //TODO refactor
    fun getNumber(shopId: String): Flow<NumberTicketShop> {
        return operation.receiveShopInfo(shopId)
    }

    suspend fun callNumber(number: Int) {
        numberCallingController.numberCalling(number)
    }

    fun getQrCodeInfo(shopId: String, shop: NumberTicketShop): QRcodeInfo? {
        return if (shop.shopType == ShopType.USER_TAKE_NUMBER) {
            QRcodeInfo("https://keeppro.io/?tnp=${shopId}")
        }else{ null }
    }

}

sealed interface NumberDisplayUiState{
    data object Loading : NumberDisplayUiState
    data class Display(val shopInfo: NumberTicketShop) : NumberDisplayUiState
}