import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "da", default = false)
val DaStrings = Strings(
    projectNameDialogIntro1 = "Hej! Her skal vi oprette en nummerservice",
	projectNameDialogIntro2 = "Indtast venligst dit butiksnavn:",
	projectNameDialogShopName = "Butiksnavn",
	submit = "Indsend",
	projectOptionsDialogIntro = { "Hej, ${it}, hvilken slags service ønsker du:" },
	projectOptionsDialogShopType0Title = "Enkel nummeropkaldstjeneste",
	projectOptionsDialogShopType0Description = "Butiksejer/passer kan ringe numre med et enkelt tryk på appen, hvilket gør processen hurtig og effektiv.",
	projectOptionsDialogShopType1Title = "Kundenummer Service",
	projectOptionsDialogShopType1Description = "Kunder kan tage et nummer og vente på at blive kaldt op, hvilket sikrer, at ventetiden er glat og hurtig.",
	projectOptionsDialogShopType2Title = "Tilbyde nummerservice",
	projectOptionsDialogShopType2Description = "Butiksejer/passer giver nummer og skræddersyr nummeroplevelsen til at passe dine forretningsbehov med tilpasselige indstillinger.",
	vendorConsoleStartRunning = "Begynd at løbe",
	numberDisplayScreenTitle = "Tag nummer her!",
	qrCodeDescription = { "QR-kode, der henviser til ${it}-webstedet" },
	takeNumberScreenInputPhoneNumber = "Indtast dit telefonnummer",
	takeNumberScreenPhoneNumber = "Telefonnummer",
	provideNumberPadGetNumber = "Få nummer",
	provideNumberPadCurrentNumber = "Nuværende nummer",
	provideNumberPadYourNumber = { "Dit nummer er ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Nuværende ventenummer: ${it}" },
	displayNumberTitle = "Nummerdisplay side",
	customerTakeNumberTitle = "Kundenummer side",
	provideNumberTitle = "Tilvejebring numerisk side",
	yourNumberTitle = "Din nummer side",
	callNumberTitle = "Opkaldsnummer side",
	setting = "Indstilling",
	callNumberPadSoundSwitch = "Lyd Til/Fra",
	shutDown = "Luk ned",
	ok = "Ok",
	cancel = "Annuller",
	startRunningShop = "Start butikdriften",
	getToWork = "Lad os komme i gang med arbejdet!",
	uploadSuccess = "Uploadet med succes!",
	uploadFailed = "Upload mislykkedes!",
	imagePickerDialogTitle = "Opdater dit butiklogo",
	imagePickerDialogAction = "Vælg et billede",
	cropImageDialogTitle = "Bekræft for at vise dit logo",
	editShopNameDialogTitle = "Opdater dit butiknavn"
)