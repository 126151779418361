import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ka", default = false)
val KaStrings = Strings(
    projectNameDialogIntro1 = "გამარჯობა! აქ ჩვენ შევქმნით ნომრის ბილეთის სერვისს",
	projectNameDialogIntro2 = "გთხოვთ შეიყვანოთ თქვენი მაღაზიის სახელი:",
	projectNameDialogShopName = "მაღაზიის სახელი",
	submit = "გაგზავნა",
	projectOptionsDialogIntro = { "გამარჯობა, ${it}, რა სახის მომსახურება გსურთ:" },
	projectOptionsDialogShopType0Title = "მარტივი ნომრის ზარის სერვისი",
	projectOptionsDialogShopType0Description = "მაღაზიის მეპატრონეს/მომვლელს შეუძლია ნომრების გამოძახება აპლიკაციაზე უბრალო შეხებით, რაც პროცესს სწრაფსა და ეფექტურს ხდის.",
	projectOptionsDialogShopType1Title = "მომხმარებლის ნომრის სერვისი",
	projectOptionsDialogShopType1Description = "მომხმარებლებს შეუძლიათ აიღონ ნომერი და დაელოდონ ნომრის გამოძახებას, უზრუნველყონ, რომ მოლოდინის პროცესი იყოს გლუვი და სწრაფი.",
	projectOptionsDialogShopType2Title = "მიაწოდეთ ნომრების სერვისი",
	projectOptionsDialogShopType2Description = "მაღაზიის მფლობელი/მომვლელი უზრუნველყოფს ნომერს და აწესრიგებს ნომრის გამოძახების გამოცდილებას, რომლითაც თქვენი ბიზნესის საჭიროებები მიიღწევა დაკონფიგურირებადი პარამეტრებით.",
	vendorConsoleStartRunning = "დაიწყეთ სირბილი",
	numberDisplayScreenTitle = "აიღე ნომერი აქ!",
	qrCodeDescription = { "${it} ვებგვერდზე მიმანიშნებელი QR კოდი" },
	takeNumberScreenInputPhoneNumber = "შეიყვანეთ თქვენი ტელეფონის ნომერი",
	takeNumberScreenPhoneNumber = "ტელეფონის ნომერი",
	provideNumberPadGetNumber = "მიიღე ნომერი",
	provideNumberPadCurrentNumber = "მიმდინარე ნომერი",
	provideNumberPadYourNumber = { "თქვენი ნომერი არის ${it}" },
	info = "ინფორმაცია",
	callNumberPadCurrentWaitingNumber = { "მიმდინარე ლოდინის ნომერი: ${it}" },
	displayNumberTitle = "ნომრის ჩვენების გვერდი",
	customerTakeNumberTitle = "მომხმარებლის ნომრის გვერდი",
	provideNumberTitle = "მიაწოდეთ ნომრის გვერდი",
	yourNumberTitle = "თქვენი ნომრის გვერდი",
	callNumberTitle = "ზარის ნომრის გვერდი",
	setting = "პარამეტრი",
	callNumberPadSoundSwitch = "ხმა ჩართული/გამორთული",
	shutDown = "გამორთვა",
	ok = "კარგი",
	cancel = "გაუქმება",
	startRunningShop = "მაღაზიის მუშაობის დაწყება",
	getToWork = "მუშაობა დავიწყოთ!",
	uploadSuccess = "ატვირთვა წარმატებით დასრულდა!",
	uploadFailed = "ატვირთვა ვერ მოხერხდა!",
	imagePickerDialogTitle = "განაახლეთ თქვენი მაღაზიის ლოგო",
	imagePickerDialogAction = "აირჩიეთ სურათი",
	cropImageDialogTitle = "დაადასტურეთ თქვენი ლოგოს ჩვენება",
	editShopNameDialogTitle = "განაახლეთ თქვენი მაღაზიის სახელი"
)