package tim.huang.genlayout.viewmodel

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import tim.huang.genlayout.data.operation.DatabaseOperation
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.utils.Base64Hash

class TakeNumberViewModel(
    private val coroutineScope: CoroutineScope,
    private val operation: DatabaseOperation,
    takeNumberPadId: String,
    ) {

    private val _uiState: MutableStateFlow<TakeNumberUiState> = MutableStateFlow(TakeNumberUiState.Loading)
    private val shopId = takeNumberPadId

    init {
        val state = _uiState.value
        if (state is TakeNumberUiState.Loading) {
            coroutineScope.launch {
                val shopInfo = operation.receiveShopInfo(shopId).first()
                _uiState.value = TakeNumberUiState.Data(shopInfo)
            }
        }
    }

    fun uiState(): StateFlow<TakeNumberUiState>  = _uiState

    fun takeNumberByPhone(textValue: String) {
        if (textValue.isEmpty()) return

        coroutineScope.launch {
            val number = operation.atomicFetchWaitingNumber(shopId)
            _uiState.value = TakeNumberUiState.NavigateToYourNumber(Base64Hash.hashNumber(number, shopId))
        }
    }
}

sealed class TakeNumberUiState {
    data object Loading : TakeNumberUiState()
    data class  Data(val shopInfo: NumberTicketShop) : TakeNumberUiState()
    data class NavigateToYourNumber(val numberHash: String) : TakeNumberUiState()
}
