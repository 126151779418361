import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "hr", default = false)
val HrStrings = Strings(
    projectNameDialogIntro1 = "Bok! Ovdje ćemo stvoriti uslugu brojčanih ulaznica",
	projectNameDialogIntro2 = "Unesite naziv svoje trgovine:",
	projectNameDialogShopName = "Ime trgovine",
	submit = "Pošalji",
	projectOptionsDialogIntro = { "Bok, ${it}, kakvu vrstu usluge želite:" },
	projectOptionsDialogShopType0Title = "Jednostavna usluga pozivanja brojeva",
	projectOptionsDialogShopType0Description = "Vlasnik/čuvar trgovine može pozivati brojeve jednostavnim dodirom na aplikaciju, čineći proces brzim i učinkovitim.",
	projectOptionsDialogShopType1Title = "Usluga uzimanja broja kupca",
	projectOptionsDialogShopType1Description = "Kupci mogu uzeti broj i čekati pozivanje broja, osiguravajući da je proces čekanja gladak i brz.",
	projectOptionsDialogShopType2Title = "Pružite uslugu broja",
	projectOptionsDialogShopType2Description = "Vlasnik/čuvar trgovine pruža broj i prilagođava iskustvo pozivanja brojeva kako bi odgovaralo potrebama vašeg poslovanja s prilagodljivim postavkama.",
	vendorConsoleStartRunning = "Počni trčati",
	numberDisplayScreenTitle = "Uzmi broj ovdje!",
	qrCodeDescription = { "QR kod koji se odnosi na web stranicu ${it}" },
	takeNumberScreenInputPhoneNumber = "Unesite svoj telefonski broj",
	takeNumberScreenPhoneNumber = "Telefonski broj",
	provideNumberPadGetNumber = "Uzmi broj",
	provideNumberPadCurrentNumber = "Trenutni broj",
	provideNumberPadYourNumber = { "Vaš broj je ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Trenutni broj čekanja: ${it}" },
	displayNumberTitle = "Stranica prikaza brojeva",
	customerTakeNumberTitle = "Stranica broja kupaca",
	provideNumberTitle = "Osigurajte brojčanu stranicu",
	yourNumberTitle = "Vaša stranica broja",
	callNumberTitle = "Stranica broja poziva",
	setting = "Postavka",
	callNumberPadSoundSwitch = "Zvuk Uključeno/Isključeno",
	shutDown = "Isključiti",
	ok = "U redu",
	cancel = "Odustani",
	startRunningShop = "Počni voditi trgovinu",
	getToWork = "Krenimo na posao!",
	uploadSuccess = "Učitavanje uspješno!",
	uploadFailed = "Učitavanje nije uspjelo!",
	imagePickerDialogTitle = "Ažurirajte logotip svoje trgovine",
	imagePickerDialogAction = "Odaberite sliku",
	cropImageDialogTitle = "Potvrdite prikaz vašeg logotipa",
	editShopNameDialogTitle = "Ažurirajte naziv svoje trgovine"
)