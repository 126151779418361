import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ar", default = false)
val ArStrings = Strings(
    projectNameDialogIntro1 = "مرحبًا! سنقوم هنا بإنشاء خدمة تذاكر الأرقام",
	projectNameDialogIntro2 = "يرجى إدخال اسم متجرك:",
	projectNameDialogShopName = "اسم المتجر",
	submit = "إرسال",
	projectOptionsDialogIntro = { "مرحبًا، ${it}، ما نوع الخدمة التي تريدها:" },
	projectOptionsDialogShopType0Title = "خدمة الاتصال بالرقم البسيطة",
	projectOptionsDialogShopType0Description = "يمكن لمالك/حارس المتجر الاتصال بالأرقام بضغطة بسيطة على التطبيق، مما يجعل العملية سريعة وفعالة.",
	projectOptionsDialogShopType1Title = "خدمة أخذ الرقم للعملاء",
	projectOptionsDialogShopType1Description = "يمكن للعملاء أخذ رقم والانتظار لاستدعاء الرقم، مما يضمن أن تكون عملية الانتظار سلسة وسريعة.",
	projectOptionsDialogShopType2Title = "تقديم خدمة الأرقام",
	projectOptionsDialogShopType2Description = "يوفر مالك/حارس المتجر الرقم ويخصص تجربة الاتصال بالأرقام لتناسب احتياجات عملك مع إعدادات قابلة للتخصيص.",
	vendorConsoleStartRunning = "ابدأ الجري",
	numberDisplayScreenTitle = "خذ رقم هنا!",
	qrCodeDescription = { "رمز الاستجابة السريعة الذي يشير إلى موقع ${it}" },
	takeNumberScreenInputPhoneNumber = "أدخل رقم هاتفك",
	takeNumberScreenPhoneNumber = "رقم الهاتف",
	provideNumberPadGetNumber = "احصل على الرقم",
	provideNumberPadCurrentNumber = "الرقم الحالي",
	provideNumberPadYourNumber = { "رقمك هو ${it}" },
	info = "معلومات",
	callNumberPadCurrentWaitingNumber = { "رقم الانتظار الحالي: ${it}" },
	displayNumberTitle = "صفحة عرض الأرقام",
	customerTakeNumberTitle = "صفحة أخذ رقم العميل",
	provideNumberTitle = "توفير صفحة الرقم",
	yourNumberTitle = "صفحة رقمك",
	callNumberTitle = "صفحة رقم الاتصال",
	setting = "إعداد",
	callNumberPadSoundSwitch = "الصوت تشغيل/إيقاف",
	shutDown = "إيقاف التشغيل",
	ok = "موافق",
	cancel = "إلغاء",
	startRunningShop = "بدء تشغيل المتجر",
	getToWork = "هيا نبدأ العمل!",
	uploadSuccess = "تم الرفع بنجاح!",
	uploadFailed = "فشل الرفع!",
	imagePickerDialogTitle = "قم بتحديث شعار متجرك",
	imagePickerDialogAction = "اختر صورة",
	cropImageDialogTitle = "تأكيد لعرض شعارك",
	editShopNameDialogTitle = "قم بتحديث اسم متجرك"
)