import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "so", default = false)
val SoStrings = Strings(
    projectNameDialogIntro1 = "Hello! Halkan waxaan ka abuuri doonnaa adeeg lambarka tigidhada",
	projectNameDialogIntro2 = "Fadlan geli magaca dukaankaaga:",
	projectNameDialogShopName = "Magaca dukaanka",
	submit = "Gudbi",
	projectOptionsDialogIntro = { "Salaan, ${it}, noocee adeega aad rabto:" },
	projectOptionsDialogShopType0Title = "Adeegga Wicitaanka Nambarka Fudud",
	projectOptionsDialogShopType0Description = "Milkiilaha/ilaaliyaha dukaanku wuxuu ku wici karaa lambarrada hal taabasho oo fudud oo ku saabsan app-ka, taasoo ka dhigaysa habka degdeg iyo hufnaan.",
	projectOptionsDialogShopType1Title = "Adeegga Qaadashada Lambarka Macaamiisha",
	projectOptionsDialogShopType1Description = "Macaamiishu waxay qaadan karaan nambar waxayna sugi karaan nambar wicitaanka, hubi in habka sugitaanka uu yahay mid sahlan oo degdeg ah.",
	projectOptionsDialogShopType2Title = "Bixi Adeegga Lambarka",
	projectOptionsDialogShopType2Description = "Milkiilaha/ilaaliyaha dukaanka ayaa bixiya lambarka, wuxuuna ku habeynayaa khibrada wicitaanka lambarka si ay ugu habboonaato baahiyahaaga ganacsi iyadoo la adeegsanayo dejin la habeyn karo.",
	vendorConsoleStartRunning = "Bilow orodka",
	numberDisplayScreenTitle = "Halkan lambarka qaado!",
	qrCodeDescription = { "Koodhka QR-ka ee tilmaamaya degelka ${it}" },
	takeNumberScreenInputPhoneNumber = "Geli lambarkaaga telefoonka",
	takeNumberScreenPhoneNumber = "Lambarka telefoonka",
	provideNumberPadGetNumber = "Hel lambarka",
	provideNumberPadCurrentNumber = "Lambarka hadda",
	provideNumberPadYourNumber = { "Lambarkaagu waa ${it}" },
	info = "Macluumaad",
	callNumberPadCurrentWaitingNumber = { "Lambarka sugaya hadda: ${it}" },
	displayNumberTitle = "Bogga muujinta lambarka",
	customerTakeNumberTitle = "Bogga lambarka macmiisha",
	provideNumberTitle = "Bixi bogga lambarka",
	yourNumberTitle = "Bogga Lambarkaaga",
	callNumberTitle = "Bogga Lambarka Wicitaanka",
	setting = "Dejin",
	callNumberPadSoundSwitch = "Cod Shid/Dami",
	shutDown = "Demi",
	ok = "Haa",
	cancel = "Ka noqo",
	startRunningShop = "Bilow orodka dukaanka",
	getToWork = "Aan bilowno shaqada!",
	uploadSuccess = "Si guul leh u upload gareeyay!",
	uploadFailed = "Si guul darreeyay u upload gareeyay!",
	imagePickerDialogTitle = "Cusboonaysii astaanta dukaankaaga",
	imagePickerDialogAction = "Dooro sawir",
	cropImageDialogTitle = "Xaqiiji inaad muujiso astaan-ganacsigaaga",
	editShopNameDialogTitle = "Cusboonaysii magaca dukaankaaga"
)