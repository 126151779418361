import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "uk", default = false)
val UkStrings = Strings(
    projectNameDialogIntro1 = "Привіт! Тут ми створимо послугу номерних квитків",
	projectNameDialogIntro2 = "Будь ласка, введіть назву вашого магазину:",
	projectNameDialogShopName = "Назва магазину",
	submit = "Надіслати",
	projectOptionsDialogIntro = { "Привіт, ${it}, який вид послуги ви хочете:" },
	projectOptionsDialogShopType0Title = "Проста служба виклику номерів",
	projectOptionsDialogShopType0Description = "Власник/зберігач магазину може викликати номери простим натисканням на додаток, що робить процес швидким та ефективним.",
	projectOptionsDialogShopType1Title = "Сервіс отримання номерів для клієнтів",
	projectOptionsDialogShopType1Description = "Клієнти можуть взяти номер і чекати виклику номера, забезпечуючи плавність і швидкість процесу очікування.",
	projectOptionsDialogShopType2Title = "Надати номерну послугу",
	projectOptionsDialogShopType2Description = "Власник/охоронець магазину надає номер і налаштовує досвід виклику номерів відповідно до ваших бізнес-потреб за допомогою настроюваних параметрів.",
	vendorConsoleStartRunning = "Почати бігати",
	numberDisplayScreenTitle = "Візьміть номер тут!",
	qrCodeDescription = { "QR-код, що посилається на вебсайт ${it}" },
	takeNumberScreenInputPhoneNumber = "Введіть свій номер телефону",
	takeNumberScreenPhoneNumber = "Номер телефону",
	provideNumberPadGetNumber = "Отримати номер",
	provideNumberPadCurrentNumber = "Поточний номер",
	provideNumberPadYourNumber = { "Ваш номер ${it}" },
	info = "Інформація",
	callNumberPadCurrentWaitingNumber = { "Поточний номер очікування: ${it}" },
	displayNumberTitle = "Сторінка відображення номерів",
	customerTakeNumberTitle = "Сторінка номера клієнта",
	provideNumberTitle = "Надати цифрову сторінку",
	yourNumberTitle = "Ваша сторінка номерів",
	callNumberTitle = "Сторінка виклику номера",
	setting = "Налаштування",
	callNumberPadSoundSwitch = "Звук Увімк/Вимк",
	shutDown = "Вимкнути",
	ok = "Гаразд",
	cancel = "Скасувати",
	startRunningShop = "Почати роботу магазину",
	getToWork = "Давайте до роботи!",
	uploadSuccess = "Завантаження успішне!",
	uploadFailed = "Завантаження не вдалося!",
	imagePickerDialogTitle = "Оновіть логотип вашого магазину",
	imagePickerDialogAction = "Виберіть зображення",
	cropImageDialogTitle = "Підтвердьте показ вашого логотипу",
	editShopNameDialogTitle = "Оновіть назву вашого магазину"
)