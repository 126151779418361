import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "tl", default = false)
val TlStrings = Strings(
    projectNameDialogIntro1 = "Hello! Dito kami lilikha ng isang serbisyo ng tiket na numero",
	projectNameDialogIntro2 = "Paki-enter ang pangalan ng iyong tindahan:",
	projectNameDialogShopName = "Pangalan ng Tindahan",
	submit = "Ipasa",
	projectOptionsDialogIntro = { "Hi, ${it}, anong uri ng serbisyo ang gusto mo:" },
	projectOptionsDialogShopType0Title = "Simpleng Serbisyo ng Tawag sa Numero",
	projectOptionsDialogShopType0Description = "Maaaring tumawag ang may-ari/tagapangalaga ng tindahan ng mga numero sa pamamagitan ng isang simpleng pag-tap sa app, na ginagawang mabilis at mahusay ang proseso.",
	projectOptionsDialogShopType1Title = "Serbisyo sa Pagkuha ng Numero ng Customer",
	projectOptionsDialogShopType1Description = "Maaaring kumuha ng numero ang mga customer at maghintay sa pagtawag ng numero, tiyakin na ang proseso ng paghihintay ay makinis at mabilis.",
	projectOptionsDialogShopType2Title = "Magbigay ng Serbisyo ng Numero",
	projectOptionsDialogShopType2Description = "Ang may-ari/tagapangasiwa ng tindahan ay nagbibigay ng numero, at inaangkop ang karanasan sa pagtawag ng numero upang umangkop sa iyong mga pangangailangan sa negosyo na may mga nako-customize na setting.",
	vendorConsoleStartRunning = "Magsimulang tumakbo",
	numberDisplayScreenTitle = "Kumuha ng numero dito!",
	qrCodeDescription = { "QR code na tumutukoy sa ${it} website" },
	takeNumberScreenInputPhoneNumber = "Ilagay ang iyong numero ng telepono",
	takeNumberScreenPhoneNumber = "Numero ng telepono",
	provideNumberPadGetNumber = "Kumuha ng numero",
	provideNumberPadCurrentNumber = "Kasalukuyang numero",
	provideNumberPadYourNumber = { "Ang numero mo ay ${it}" },
	info = "Impormasyon",
	callNumberPadCurrentWaitingNumber = { "Kasalukuyang naghihintay na numero: ${it}" },
	displayNumberTitle = "Pahina ng pagpapakita ng numero",
	customerTakeNumberTitle = "Pahina ng numero ng customer",
	provideNumberTitle = "Magbigay ng pahina ng numero",
	yourNumberTitle = "Ang iyong pahina ng numero",
	callNumberTitle = "Pahina ng numero ng tawag",
	setting = "Pagtatakda",
	callNumberPadSoundSwitch = "Tunog Buksan/Patayin",
	shutDown = "Patayin",
	ok = "Sige",
	cancel = "Kanselahin",
	startRunningShop = "Simulan ang pagpapatakbo ng tindahan",
	getToWork = "Tara na't magtrabaho!",
	uploadSuccess = "Matagumpay na na-upload!",
	uploadFailed = "Nabigo ang pag-upload!",
	imagePickerDialogTitle = "I-update ang iyong shop logo",
	imagePickerDialogAction = "Pumili ng larawan",
	cropImageDialogTitle = "Kumpirmahin para ipakita ang iyong logo",
	editShopNameDialogTitle = "I-update ang pangalan ng iyong tindahan"
)