package tim.huang.genlayout.ui.widget

import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.layout
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import cafe.adriel.lyricist.strings
import coil3.SingletonImageLoader
import coil3.compose.AsyncImage
import coil3.compose.LocalPlatformContext
import io.keeppro.CropHint
import io.keeppro.Croppable
import io.keeppro.rememberCroppableState
import tim.huang.genlayout.utils.getImageRequest

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalComposeUiApi::class)
@Composable
fun CropImageDialog(selectedFile: ByteArray, onDismiss: () -> Unit, onSubmit: (ByteArray) -> Unit) {

    val windowSizeClass = calculateWindowSizeClass()
    val buttonPadding = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  16.dp
        else ->  32.dp
    }

    val size = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact -> 200.dp
        else -> 300.dp
    }

    val imageRequest = getImageRequest(LocalPlatformContext.current, selectedFile)

    Dialog(
        onDismissRequest = onDismiss,
    ) {

        Surface(
            shape = RoundedCornerShape(16.dp),
        ) {
            val croppableState = rememberCroppableState(contentScale = ContentScale.Crop)
            var aspectRatio by remember { mutableStateOf(4f/3f) }

            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
            ) {
                Text(
                    strings.cropImageDialogTitle,
                    style = MaterialTheme.typography.h5,
                    modifier = Modifier.padding(16.dp)
                )
                Croppable(
                    state = croppableState,
                    cropHint = CropHint.Default,
                    modifier = Modifier
                        .layout { measurable, constraints ->
                            val newConstraints = if (aspectRatio >= 1f){
                                constraints.copy(maxWidth = size.roundToPx(), maxHeight = (size.roundToPx() / aspectRatio).toInt())
                            }else{
                                constraints.copy(maxWidth = (size.roundToPx() * aspectRatio).toInt(), maxHeight = size.roundToPx())
                            }
                            val placeable = measurable.measure(newConstraints)

                            layout(newConstraints.maxWidth, newConstraints.maxHeight){
                                placeable.placeRelative(0, 0)
                            }
                        }
                    ,
                ) {
                    AsyncImage(
                        model = imageRequest,
                        contentDescription = null,
                        imageLoader = SingletonImageLoader.get(LocalPlatformContext.current),
                        contentScale = ContentScale.Inside,
                        onSuccess = { croppableState.prepareImage(it.result.image) },
                    )
                }
                Spacer(modifier = Modifier.height(16.dp))

                //buttons to change aspect ratio to 1:1, 4:3, 16:9, 3:4, 9:16
                Row (modifier = Modifier.horizontalScroll(rememberScrollState())) {

                    listOfAspectRatios.forEach {
                        OutlinedButton(
                            modifier = Modifier.padding(16.dp),
                            onClick = { aspectRatio = it.aspectRatio },

                        ) {
                            Text(it.text)
                        }
                    }
                }


                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceEvenly,
                    modifier = Modifier.fillMaxWidth()
                ) {

                    TextButton(onClick = onDismiss) {
                        Text(
                            text = strings.cancel,
                            modifier = Modifier.padding(
                                horizontal = buttonPadding,
                                vertical = 8.dp
                            ),
                            style = MaterialTheme.typography.button,
                            fontWeight = FontWeight.Bold
                        )
                    }

                    Button(
                        onClick = {
                            //after cropping image
                            onSubmit(croppableState.crop())
                        },
                        modifier = Modifier.padding(16.dp)
                    ) {
                        Text(
                            text = strings.submit,
                            modifier = Modifier.padding(
                                horizontal = buttonPadding,
                                vertical = 8.dp
                            ),
                            style = MaterialTheme.typography.button,
                            fontWeight = FontWeight.Bold
                        )
                    }
                }
            }
        }
    }
}

private class AspectRatioButton(
    val text: String,
    val aspectRatio: Float,
)

private val listOfAspectRatios = listOf(
    AspectRatioButton("1:1", 1f),
    AspectRatioButton("4:3", 4f / 3f),
    AspectRatioButton("16:9", 16f / 9f),
)