import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "it", default = false)
val ItStrings = Strings(
    projectNameDialogIntro1 = "Ciao! Qui creeremo un servizio di biglietti numerati",
	projectNameDialogIntro2 = "Per favore inserisci il nome del tuo negozio:",
	projectNameDialogShopName = "Nome del negozio",
	submit = "Invia",
	projectOptionsDialogIntro = { "Ciao, ${it}, che tipo di servizio vuoi:" },
	projectOptionsDialogShopType0Title = "Servizio di Chiamata Numerica Semplice",
	projectOptionsDialogShopType0Description = "Il proprietario/gestore del negozio può chiamare i numeri con un semplice tocco sull'app, rendendo il processo rapido ed efficiente.",
	projectOptionsDialogShopType1Title = "Servizio di Prelievo Numero per Clienti",
	projectOptionsDialogShopType1Description = "I clienti possono prendere un numero e aspettare la chiamata, assicurando che il processo di attesa sia fluido e veloce.",
	projectOptionsDialogShopType2Title = "Fornire il servizio di numeri",
	projectOptionsDialogShopType2Description = "Il proprietario/gestore del negozio fornisce numeri e adatta l'esperienza di chiamata dei numeri per soddisfare le esigenze della tua attività con impostazioni personalizzabili.",
	vendorConsoleStartRunning = "Iniziare a correre",
	numberDisplayScreenTitle = "Prendi il numero qui!",
	qrCodeDescription = { "Codice QR che fa riferimento al sito web ${it}" },
	takeNumberScreenInputPhoneNumber = "Inserisci il tuo numero di telefono",
	takeNumberScreenPhoneNumber = "Numero di telefono",
	provideNumberPadGetNumber = "Ottieni numero",
	provideNumberPadCurrentNumber = "Numero attuale",
	provideNumberPadYourNumber = { "Il tuo numero è ${it}" },
	info = "Informazioni",
	callNumberPadCurrentWaitingNumber = { "Numero di attesa attuale: ${it}" },
	displayNumberTitle = "Pagina di visualizzazione dei numeri",
	customerTakeNumberTitle = "Pagina del numero cliente",
	provideNumberTitle = "Fornire pagina numerica",
	yourNumberTitle = "La tua pagina numerica",
	callNumberTitle = "Pagina del numero di chiamata",
	setting = "Impostazione",
	callNumberPadSoundSwitch = "Suono Acceso/Spento",
	shutDown = "Spegnere",
	ok = "Ok",
	cancel = "Annulla",
	startRunningShop = "Inizia a gestire il negozio",
	getToWork = "Mettiamoci al lavoro!",
	uploadSuccess = "Caricamento riuscito!",
	uploadFailed = "Caricamento fallito!",
	imagePickerDialogTitle = "Aggiorna il logo del tuo negozio",
	imagePickerDialogAction = "Scegli un'immagine",
	cropImageDialogTitle = "Conferma per visualizzare il tuo logo",
	editShopNameDialogTitle = "Aggiorna il nome del tuo negozio"
)