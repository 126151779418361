import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sk", default = false)
val SkStrings = Strings(
    projectNameDialogIntro1 = "Ahoj! Tu vytvoríme službu číslovaných lístkov",
	projectNameDialogIntro2 = "Prosím, zadajte názov vášho obchodu:",
	projectNameDialogShopName = "Názov obchodu",
	submit = "Odoslať",
	projectOptionsDialogIntro = { "Ahoj, ${it}, aký druh služby chcete:" },
	projectOptionsDialogShopType0Title = "Jednoduchá služba volania čísel",
	projectOptionsDialogShopType0Description = "Majiteľ/opatrovateľ obchodu môže volať čísla jednoduchým klepnutím na aplikáciu, čím sa proces zrýchli a zefektívni.",
	projectOptionsDialogShopType1Title = "Služba na odber čísla zákazníka",
	projectOptionsDialogShopType1Description = "Zákazníci si môžu vziať číslo a čakať na vyvolanie čísla, čím zabezpečia, že čakací proces bude hladký a rýchly.",
	projectOptionsDialogShopType2Title = "Poskytnúť číselnú službu",
	projectOptionsDialogShopType2Description = "Majiteľ/strážca obchodu poskytuje číslo a prispôsobuje zážitok z volania čísel tak, aby vyhovoval vašim obchodným potrebám s prispôsobiteľnými nastaveniami.",
	vendorConsoleStartRunning = "Začnite bežať",
	numberDisplayScreenTitle = "Vezmite si číslo tu!",
	qrCodeDescription = { "QR kód odkazujúci na web ${it}" },
	takeNumberScreenInputPhoneNumber = "Zadajte svoje telefónne číslo",
	takeNumberScreenPhoneNumber = "Telefónne číslo",
	provideNumberPadGetNumber = "Získať číslo",
	provideNumberPadCurrentNumber = "Aktuálne číslo",
	provideNumberPadYourNumber = { "Vaše číslo je ${it}" },
	info = "Informácie",
	callNumberPadCurrentWaitingNumber = { "Aktuálne čakacie číslo: ${it}" },
	displayNumberTitle = "Stránka zobrazenia čísiel",
	customerTakeNumberTitle = "Stránka čísla zákazníka",
	provideNumberTitle = "Poskytnite číselnú stránku",
	yourNumberTitle = "Vaša číselná stránka",
	callNumberTitle = "Stránka volania čísla",
	setting = "Nastavenie",
	callNumberPadSoundSwitch = "Zvuk Zapnutý/Vypnutý",
	shutDown = "Vypnúť",
	ok = "Ok",
	cancel = "Zrušiť",
	startRunningShop = "Začnite prevádzkovať obchod",
	getToWork = "Poďme pracovať!",
	uploadSuccess = "Nahrávanie bolo úspešné!",
	uploadFailed = "Nahrávanie zlyhalo!",
	imagePickerDialogTitle = "Aktualizujte logo svojho obchodu",
	imagePickerDialogAction = "Vyberte obrázok",
	cropImageDialogTitle = "Potvrďte zobrazenie svojho loga",
	editShopNameDialogTitle = "Aktualizujte názov svojho obchodu"
)