import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "tr", default = false)
val TrStrings = Strings(
    projectNameDialogIntro1 = "Merhaba! Burada numaralı bilet hizmeti oluşturacağız",
	projectNameDialogIntro2 = "Lütfen mağaza adınızı girin:",
	projectNameDialogShopName = "Mağaza Adı",
	submit = "Gönder",
	projectOptionsDialogIntro = { "Merhaba, ${it}, ne tür bir hizmet istiyorsunuz:" },
	projectOptionsDialogShopType0Title = "Basit Numara Çağrı Hizmeti",
	projectOptionsDialogShopType0Description = "Mağaza sahibi/işletmecisi, uygulamada basit bir dokunuşla numaraları arayabilir, bu da süreci hızlı ve verimli hale getirir.",
	projectOptionsDialogShopType1Title = "Müşteri Numarası Alma Hizmeti",
	projectOptionsDialogShopType1Description = "Müşteriler numara alabilir ve çağrı numarasını bekleyebilir, bekleme sürecinin sorunsuz ve hızlı olmasını sağlar.",
	projectOptionsDialogShopType2Title = "Numara Hizmeti Sağlayın",
	projectOptionsDialogShopType2Description = "Mağaza sahibi/işletmecisi numara sağlar ve numara çağrı deneyimini özelleştirilebilir ayarlarla iş ihtiyaçlarınıza göre uyarlar.",
	vendorConsoleStartRunning = "Koşmaya başla",
	numberDisplayScreenTitle = "Buradan numara alın!",
	qrCodeDescription = { "${it} web sitesine atıfta bulunan QR kodu" },
	takeNumberScreenInputPhoneNumber = "Telefon numaranızı girin",
	takeNumberScreenPhoneNumber = "Telefon numarası",
	provideNumberPadGetNumber = "Numara al",
	provideNumberPadCurrentNumber = "Mevcut numara",
	provideNumberPadYourNumber = { "Numaranız ${it}" },
	info = "Bilgi",
	callNumberPadCurrentWaitingNumber = { "Mevcut bekleme numarası: ${it}" },
	displayNumberTitle = "Numara Görüntüleme Sayfası",
	customerTakeNumberTitle = "Müşteri Numara Sayfası",
	provideNumberTitle = "Numara Sayfası Sağlayın",
	yourNumberTitle = "Numara Sayfanız",
	callNumberTitle = "Çağrı Numarası Sayfası",
	setting = "Ayar",
	callNumberPadSoundSwitch = "Ses Açık/Kapalı",
	shutDown = "Kapat",
	ok = "Tamam",
	cancel = "İptal",
	startRunningShop = "Mağazayı işletmeye başla",
	getToWork = "Haydi işe koyulalım!",
	uploadSuccess = "Yükleme başarılı!",
	uploadFailed = "Yükleme başarısız!",
	imagePickerDialogTitle = "Mağaza logonuzu güncelleyin",
	imagePickerDialogAction = "Bir resim seçin",
	cropImageDialogTitle = "Logonuzu görüntülemek için onaylayın",
	editShopNameDialogTitle = "Mağaza adınızı güncelleyin"
)