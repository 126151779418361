import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "hi", default = false)
val HiStrings = Strings(
    projectNameDialogIntro1 = "नमस्ते! यहाँ हम एक नंबर टिकट सेवा बनाने जा रहे हैं",
	projectNameDialogIntro2 = "कृपया अपने दुकान का नाम दर्ज करें:",
	projectNameDialogShopName = "दुकान का नाम",
	submit = "जमा करें",
	projectOptionsDialogIntro = { "नमस्ते, ${it}, आप किस प्रकार की सेवा चाहते हैं:" },
	projectOptionsDialogShopType0Title = "सरल नंबर कॉलिंग सेवा",
	projectOptionsDialogShopType0Description = "दुकान का मालिक/रखवाला ऐप पर एक साधारण टैप के साथ नंबर कॉल कर सकता है, जिससे प्रक्रिया तेज और कुशल हो जाती है।",
	projectOptionsDialogShopType1Title = "ग्राहक संख्या सेवा",
	projectOptionsDialogShopType1Description = "ग्राहक नंबर ले सकते हैं और कॉलिंग नंबर की प्रतीक्षा कर सकते हैं, सुनिश्चित करें कि प्रतीक्षा प्रक्रिया सुचारू और तेज़ हो।",
	projectOptionsDialogShopType2Title = "संख्या सेवा प्रदान करें",
	projectOptionsDialogShopType2Description = "दुकान का मालिक/रखवाला नंबर प्रदान करता है, और अनुकूलन योग्य सेटिंग्स के साथ आपके व्यवसाय की जरूरतों के अनुसार नंबर कॉलिंग अनुभव को अनुकूलित करता है।",
	vendorConsoleStartRunning = "दौड़ना शुरू करें",
	numberDisplayScreenTitle = "यहाँ नंबर लें!",
	qrCodeDescription = { "${it} वेबसाइट को संदर्भित करने वाला QR कोड" },
	takeNumberScreenInputPhoneNumber = "अपना फोन नंबर दर्ज करें",
	takeNumberScreenPhoneNumber = "फ़ोन नंबर",
	provideNumberPadGetNumber = "नंबर प्राप्त करें",
	provideNumberPadCurrentNumber = "वर्तमान संख्या",
	provideNumberPadYourNumber = { "आपका नंबर ${it} है" },
	info = "जानकारी",
	callNumberPadCurrentWaitingNumber = { "वर्तमान प्रतीक्षा संख्या: ${it}" },
	displayNumberTitle = "संख्या प्रदर्शन पृष्ठ",
	customerTakeNumberTitle = "ग्राहक संख्या पृष्ठ",
	provideNumberTitle = "संख्या पृष्ठ प्रदान करें",
	yourNumberTitle = "आपका नंबर पृष्ठ",
	callNumberTitle = "कॉल नंबर पृष्ठ",
	setting = "सेटिंग",
	callNumberPadSoundSwitch = "ध्वनि चालू/बंद",
	shutDown = "बंद करें",
	ok = "ठीक",
	cancel = "रद्द करें",
	startRunningShop = "दुकान चलाना शुरू करें",
	getToWork = "चलिए काम पर लगते हैं!",
	uploadSuccess = "अपलोड सफल रहा!",
	uploadFailed = "अपलोड विफल रहा!",
	imagePickerDialogTitle = "अपनी दुकान का लोगो अपडेट करें",
	imagePickerDialogAction = "एक छवि चुनें",
	cropImageDialogTitle = "अपना लोगो प्रदर्शित करने की पुष्टि करें",
	editShopNameDialogTitle = "अपनी दुकान का नाम अपडेट करें"
)