import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ml", default = false)
val MlStrings = Strings(
    projectNameDialogIntro1 = "ഹലോ! ഇവിടെ നമുക്ക് ഒരു നമ്പർ ടിക്കറ്റ് സേവനം സൃഷ്ടിക്കാം",
	projectNameDialogIntro2 = "ദയവായി നിങ്ങളുടെ കടയുടെ പേര് നൽകുക:",
	projectNameDialogShopName = "കടയുടെ പേര്",
	submit = "സമർപ്പിക്കുക",
	projectOptionsDialogIntro = { "ഹായ്, ${it}, നിങ്ങൾക്ക് എങ്ങനെയൊരു സേവനം വേണം:" },
	projectOptionsDialogShopType0Title = "സിമ്പിൾ നമ്പർ കോളിംഗ് സർവീസ്",
	projectOptionsDialogShopType0Description = "ഷോപ്പ് ഉടമ/കീപ്പർ ആപ്പിലെ ഒരു ലളിതമായ ടാപ്പിലൂടെ നമ്പറുകൾ കോൾ ചെയ്യാൻ കഴിയും, പ്രക്രിയ വേഗത്തിലാക്കുകയും കാര്യക്ഷമമാക്കുകയും ചെയ്യും.",
	projectOptionsDialogShopType1Title = "ഉപഭോക്തൃ നമ്പർ സർവീസ്",
	projectOptionsDialogShopType1Description = "ഉപഭോക്താക്കൾക്ക് നമ്പർ എടുക്കാനും വിളിക്കുന്നതിന് കാത്തിരിക്കാനും കഴിയും, കാത്തിരിപ്പിന്റെ പ്രക്രിയ മിതവും വേഗവും ആക്കുക.",
	projectOptionsDialogShopType2Title = "നമ്പർ സേവനം നൽകുക",
	projectOptionsDialogShopType2Description = "ഷോപ്പ് ഉടമ/കീപ്പർ നമ്പർ നൽകുകയും, ഇച്ഛാനുസൃതമായ ക്രമീകരണങ്ങളിലൂടെ നിങ്ങളുടെ ബിസിനസ് ആവശ്യങ്ങൾക്കനുസരിച്ച് നമ്പർ കോൾ ചെയ്തുള്ള അനുഭവം എങ്ങനെ പ്രാപ്തമാക്കണമെന്നത് രൂപകൽപ്പന ചെയ്യുകയും ചെയ്യുന്നു.",
	vendorConsoleStartRunning = "ഓടിത്തുടങ്ങുക",
	numberDisplayScreenTitle = "ഇവിടെ നമ്പർ എടുക്കുക!",
	qrCodeDescription = { "${it} വെബ്‌സൈറ്റ് കാണിക്കുന്ന QR കോഡ്" },
	takeNumberScreenInputPhoneNumber = "നിങ്ങളുടെ ഫോൺ നമ്പർ നൽകുക",
	takeNumberScreenPhoneNumber = "ഫോൺ നമ്പർ",
	provideNumberPadGetNumber = "നമ്പർ നേടുക",
	provideNumberPadCurrentNumber = "നിലവിലെ നമ്പർ",
	provideNumberPadYourNumber = { "നിങ്ങളുടെ നമ്പർ ${it} ആണ്" },
	info = "വിവരം",
	callNumberPadCurrentWaitingNumber = { "നിലവിലെ കാത്തിരിക്കുന്ന നമ്പർ: ${it}" },
	displayNumberTitle = "നമ്പർ ഡിസ്‌പ്ലേ പേജ്",
	customerTakeNumberTitle = "ഉപഭോക്തൃ നമ്പർ പേജ്",
	provideNumberTitle = "നമ്പർ പേജ് നൽകുക",
	yourNumberTitle = "നിങ്ങളുടെ നമ്പർ പേജ്",
	callNumberTitle = "കോൾ നമ്പർ പേജ്",
	setting = "ക്രമീകരണം",
	callNumberPadSoundSwitch = "ശബ്ദം ഓൺ/ഓഫ്",
	shutDown = "അടയ്ക്കുക",
	ok = "ശരി",
	cancel = "റദ്ദാക്കുക",
	startRunningShop = "ഷോപ്പ് പ്രവർത്തനം ആരംഭിക്കുക",
	getToWork = "ചെയ്യാനായി കാത്തിരിക്കുക!",
	uploadSuccess = "അപ്‌ലോഡ് വിജയകരമായി!",
	uploadFailed = "അപ്‌ലോഡ് പരാജയപ്പെട്ടു!",
	imagePickerDialogTitle = "നിങ്ങളുടെ ഷോപ്പ് ലോഗോ അപ്‌ഡേറ്റ് ചെയ്യുക",
	imagePickerDialogAction = "ഒരു ചിത്രം തിരഞ്ഞെടുക്കുക",
	cropImageDialogTitle = "നിങ്ങളുടെ ലോഗോ പ്രദർശിപ്പിക്കാൻ സ്ഥിരീകരിക്കുക",
	editShopNameDialogTitle = "നിങ്ങളുടെ ഷോപ്പ് പേര് അപ്‌ഡേറ്റ് ചെയ്യുക"
)