import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "es", default = false)
val EsStrings = Strings(
    projectNameDialogIntro1 = "¡Hola! Aquí vamos a crear un servicio de tickets numerados",
	projectNameDialogIntro2 = "Por favor ingrese el nombre de su tienda:",
	projectNameDialogShopName = "Nombre de la tienda",
	submit = "Enviar",
	projectOptionsDialogIntro = { "Hola, ${it}, ¿qué tipo de servicio quieres?" },
	projectOptionsDialogShopType0Title = "Servicio de Llamada de Números Simple",
	projectOptionsDialogShopType0Description = "El dueño/encargado de la tienda puede llamar a los números con un simple toque en la aplicación, haciendo el proceso rápido y eficiente.",
	projectOptionsDialogShopType1Title = "Servicio de Toma de Números para Clientes",
	projectOptionsDialogShopType1Description = "Los clientes pueden tomar un número y esperar a que se llame su número, asegurando que el proceso de espera sea fluido y rápido.",
	projectOptionsDialogShopType2Title = "Proveer Servicio de Números",
	projectOptionsDialogShopType2Description = "El dueño/encargado de la tienda proporciona números y adapta la experiencia de llamada de números para satisfacer las necesidades de su negocio con configuraciones personalizables.",
	vendorConsoleStartRunning = "Empezar a correr",
	numberDisplayScreenTitle = "¡Tome número aquí!",
	qrCodeDescription = { "Código QR que refiere al sitio web de ${it}" },
	takeNumberScreenInputPhoneNumber = "Ingrese su número de teléfono",
	takeNumberScreenPhoneNumber = "Número de teléfono",
	provideNumberPadGetNumber = "Obtener número",
	provideNumberPadCurrentNumber = "Número actual",
	provideNumberPadYourNumber = { "Su número es ${it}" },
	info = "Información",
	callNumberPadCurrentWaitingNumber = { "Número de espera actual: ${it}" },
	displayNumberTitle = "Página de visualización de números",
	customerTakeNumberTitle = "Página de tomar número de cliente",
	provideNumberTitle = "Proporcionar página de número",
	yourNumberTitle = "Su página de número",
	callNumberTitle = "Página de número de llamada",
	setting = "Configuración",
	callNumberPadSoundSwitch = "Sonido Encendido/Apagado",
	shutDown = "Apagar",
	ok = "Aceptar",
	cancel = "Cancelar",
	startRunningShop = "Empezar a operar la tienda",
	getToWork = "¡Vamos a trabajar!",
	uploadSuccess = "¡Subida exitosa!",
	uploadFailed = "¡Error en la subida!",
	imagePickerDialogTitle = "Actualiza el logo de tu tienda",
	imagePickerDialogAction = "Elige una imagen",
	cropImageDialogTitle = "Confirma para mostrar tu logo",
	editShopNameDialogTitle = "Actualiza el nombre de tu tienda"
)