import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sv", default = false)
val SvStrings = Strings(
    projectNameDialogIntro1 = "Hej! Här ska vi skapa en nummerservice",
	projectNameDialogIntro2 = "Vänligen ange ditt butiksnamn:",
	projectNameDialogShopName = "Butiksnamn",
	submit = "Skicka in",
	projectOptionsDialogIntro = { "Hej, ${it}, vilken typ av tjänst vill du ha:" },
	projectOptionsDialogShopType0Title = "Enkel Nummeruppringningstjänst",
	projectOptionsDialogShopType0Description = "Butiksägaren/förvaltaren kan ringa nummer med en enkel tryckning på appen, vilket gör processen snabb och effektiv.",
	projectOptionsDialogShopType1Title = "Tjänst för Kundnummer",
	projectOptionsDialogShopType1Description = "Kunder kan ta en nummerlapp och vänta på att numret ropas upp, vilket säkerställer att väntetiden blir smidig och snabb.",
	projectOptionsDialogShopType2Title = "Tillhandahålla numreringstjänst",
	projectOptionsDialogShopType2Description = "Butiksägaren/förvaltaren tillhandahåller nummer och skräddarsyr nummerupplevelsen för att passa dina affärsbehov med anpassningsbara inställningar.",
	vendorConsoleStartRunning = "Börja springa",
	numberDisplayScreenTitle = "Ta nummer här!",
	qrCodeDescription = { "QR-kod som hänvisar till ${it} webbplats" },
	takeNumberScreenInputPhoneNumber = "Ange ditt telefonnummer",
	takeNumberScreenPhoneNumber = "Telefonnummer",
	provideNumberPadGetNumber = "Få nummer",
	provideNumberPadCurrentNumber = "Nuvarande nummer",
	provideNumberPadYourNumber = { "Ditt nummer är ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Nuvarande väntnummer: ${it}" },
	displayNumberTitle = "Nummerdisplay sida",
	customerTakeNumberTitle = "Kundnummer sida",
	provideNumberTitle = "Tillhandahåll nummersida",
	yourNumberTitle = "Din nummersida",
	callNumberTitle = "Anropsnummer sida",
	setting = "Inställning",
	callNumberPadSoundSwitch = "Ljud På/Av",
	shutDown = "Stäng av",
	ok = "Ok",
	cancel = "Avbryt",
	startRunningShop = "Börja driva butik",
	getToWork = "Nu kör vi igång!",
	uploadSuccess = "Uppladdning lyckades!",
	uploadFailed = "Uppladdning misslyckades!",
	imagePickerDialogTitle = "Uppdatera din butikssymbol",
	imagePickerDialogAction = "Välj en bild",
	cropImageDialogTitle = "Bekräfta för att visa din logotyp",
	editShopNameDialogTitle = "Uppdatera ditt butiksnamn"
)