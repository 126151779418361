import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "en", default = true)
val EnStrings = Strings(
    projectNameDialogIntro1 = "Hello! Here we are going to create a number ticket service",
	projectNameDialogIntro2 = "Please enter your shop name:",
	projectNameDialogShopName = "Shop Name",
	submit = "Submit",
	projectOptionsDialogIntro = { "Hi, ${it}, what kind of service you want:" },
	projectOptionsDialogShopType0Title = "Simple Number Calling Service",
	projectOptionsDialogShopType0Description = "Shop owner/keeper can call numbers with a simple tap on the app, making the process quick and efficient.",
	projectOptionsDialogShopType1Title = "Customer Take Number Service",
	projectOptionsDialogShopType1Description = "Customers can take number and wait for calling number, ensure the waiting process is smooth and fast.",
	projectOptionsDialogShopType2Title = "Provide Number Service",
	projectOptionsDialogShopType2Description = "Shop owner/keeper provides number, and tailor the number calling experience to suit your business needs with customizable settings.",
	vendorConsoleStartRunning = "Start running",
	numberDisplayScreenTitle = "Take number here!",
	qrCodeDescription = { "QR code referring to the ${it} website" },
	takeNumberScreenInputPhoneNumber = "Input your phone number",
	takeNumberScreenPhoneNumber = "Phone number",
	provideNumberPadGetNumber = "Get number",
	provideNumberPadCurrentNumber = "Current number",
	provideNumberPadYourNumber = { "Your number is ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Current waiting number: ${it}" },
	displayNumberTitle = "Number Display Page",
	customerTakeNumberTitle = "Customer Take Number Page",
	provideNumberTitle = "Provide Number Page",
	yourNumberTitle = "Your Number Page",
	callNumberTitle = "Call Number Page",
	setting = "Setting",
	callNumberPadSoundSwitch = "Sound On/Off",
	shutDown = "Shut down",
	ok = "Ok",
	cancel = "Cancel",
	startRunningShop = "Start Running Shop",
	getToWork = "let's get to work!",
	uploadSuccess = "Upload successfully!",
	uploadFailed = "Upload Failed!",
	imagePickerDialogTitle = "Update your shop logo",
	imagePickerDialogAction = "Choose an image",
	cropImageDialogTitle = "Confirm to display your logo",
	editShopNameDialogTitle = "Update your shop name"
)