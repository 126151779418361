import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "mn", default = false)
val MnStrings = Strings(
    projectNameDialogIntro1 = "Сайн уу! Энд бид тоон билетийн үйлчилгээг бий болгох гэж байна",
	projectNameDialogIntro2 = "Дэлгүүрийн нэрээ оруулна уу:",
	projectNameDialogShopName = "Дэлгүүрийн нэр",
	submit = "Илгээх",
	projectOptionsDialogIntro = { "Сайн байна уу, ${it}, та ямар төрлийн үйлчилгээ авахыг хүсч байна:" },
	projectOptionsDialogShopType0Title = "Энгийн дуудлагын үйлчилгээ",
	projectOptionsDialogShopType0Description = "Дэлгүүрийн эзэн/хамгаалагч апп дээр энгийн товшилтоор дугаарыг дуудаж, процессыг хурдан бөгөөд үр ашигтай болгодог.",
	projectOptionsDialogShopType1Title = "Үйлчлүүлэгчдийн дугаар авах үйлчилгээ",
	projectOptionsDialogShopType1Description = "Үйлчлүүлэгчид дугаар авч дугаар дуудахыг хүлээх боломжтой бөгөөд хүлээх үйл явцыг жигд, хурдан байлгахыг баталгаажуулна уу.",
	projectOptionsDialogShopType2Title = "Дугаарын үйлчилгээ үзүүлэх",
	projectOptionsDialogShopType2Description = "Дэлгүүрийн эзэн/хамгаалагч дугаар өгч, тохируулж болох тохиргоогоор таны бизнесийн хэрэгцээнд тохируулан дуудлагын туршлагыг тохируулна.",
	vendorConsoleStartRunning = "Гүйж эхлэх",
	numberDisplayScreenTitle = "Энд дугаар аваарай!",
	qrCodeDescription = { "${it} вэбсайтыг заасан QR код" },
	takeNumberScreenInputPhoneNumber = "Утасны дугаараа оруулна уу",
	takeNumberScreenPhoneNumber = "Утасны дугаар",
	provideNumberPadGetNumber = "Дугаар авах",
	provideNumberPadCurrentNumber = "Одоогийн дугаар",
	provideNumberPadYourNumber = { "Таны дугаар ${it}" },
	info = "Мэдээлэл",
	callNumberPadCurrentWaitingNumber = { "Одоогийн хүлээгдэж буй дугаар: ${it}" },
	displayNumberTitle = "Дугаарын дэлгэцийн хуудас",
	customerTakeNumberTitle = "Үйлчлүүлэгчийн дугаарын хуудас",
	provideNumberTitle = "Дугаарын хуудсыг өгнө үү",
	yourNumberTitle = "Таны дугаарын хуудас",
	callNumberTitle = "Дугаарын дуудлагын хуудас",
	setting = "Тохиргоо",
	callNumberPadSoundSwitch = "Дууг Асаах/Унтраах",
	shutDown = "Унтраах",
	ok = "Зөвшөөрөх",
	cancel = "Цуцлах",
	startRunningShop = "Дэлгүүрийн үйл ажиллагааг эхлүүлэх",
	getToWork = "Ажилдаа орцгооё!",
	uploadSuccess = "Амжилттай байршуулсан!",
	uploadFailed = "Байршуулах амжилтгүй боллоо!",
	imagePickerDialogTitle = "Дэлгүүрийн логоо шинэчилнэ үү",
	imagePickerDialogAction = "Зураг сонгох",
	cropImageDialogTitle = "Логоо харуулахыг баталгаажуулна уу",
	editShopNameDialogTitle = "Дэлгүүрийн нэрээ шинэчилнэ үү"
)