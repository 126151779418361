package tim.huang.genlayout.model

import androidx.compose.runtime.Composable
import cafe.adriel.lyricist.strings
import tim.huang.genlayout.viewmodel.ShopType

data class ServiceIntro(
    val title: String,
    val description: String,
    val shopType: ShopType
){
    companion object{
        @Composable
        fun getIntros(): List<ServiceIntro>{
            return with(strings){
                listOf(
                    ServiceIntro(
                        projectOptionsDialogShopType1Title,
                        projectOptionsDialogShopType1Description,
                        ShopType.USER_TAKE_NUMBER),
                    ServiceIntro(
                        projectOptionsDialogShopType2Title,
                        projectOptionsDialogShopType2Description,
                        ShopType.VENDOR_PROVIDE_NUMBER),
                    ServiceIntro(
                        projectOptionsDialogShopType0Title,
                        projectOptionsDialogShopType0Description,
                        ShopType.SIMPLE_CALL_NUMBER),
                )
            }
        }
    }
}



