package tim.huang.genlayout.viewmodel

import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import genlayout.composeapp.generated.resources.Res
import genlayout.composeapp.generated.resources.base_url
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.getString
import tim.huang.genlayout.data.VendorShop
import tim.huang.genlayout.data.network.Worker
import tim.huang.genlayout.data.operation.FirebaseOperation
import tim.huang.genlayout.model.LEDModel
import tim.huang.genlayout.model.NumberPad
import tim.huang.genlayout.model.NumberTicketUiModel
import tim.huang.genlayout.model.NumberTicketVendorConsoleConfigs
import tim.huang.genlayout.model.Page
import tim.huang.genlayout.model.Project
import tim.huang.genlayout.model.Shop
import tim.huang.genlayout.model.UserManager
import tim.huang.genlayout.sound.NumberCallingController
import tim.huang.genlayout.utils.logg

abstract class VendorConsoleViewModel(
    protected val coroutineScope: CoroutineScope,
    protected val shop: Shop?,
    protected val audioController: NumberCallingController,
    protected val operation: FirebaseOperation,
    protected val manager: UserManager,
) {

    protected val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        //TODO handle exception
        // firestore exception, when user not login or fail to find resource on firestore(IllegalArgumentException)
        logg("VendorConsoleViewModelImpl exceptionHandler: $throwable")
    }

    protected val uploadExceptionHandler = CoroutineExceptionHandler { _, throwable ->
        _uploadStatus.value = UploadStatus.Failed(throwable.message ?: "Unknown error")
    }

    protected val hasSound = MutableStateFlow(shop == null)
    protected val vendorState: MutableStateFlow<VendorState> = MutableStateFlow(VendorState.Loading)
    protected var curNumber by mutableStateOf(0)
    private val _uploadStatus = MutableStateFlow<UploadStatus>(UploadStatus.Idle)
    val uploadStatus: StateFlow<UploadStatus> = _uploadStatus

    protected abstract val _uiModel: MutableStateFlow<NumberTicketUiModel>
    val uiModel: StateFlow<NumberTicketUiModel> = _uiModel


    init {
        //if there is a running shop, set vendorState to RunningShop
        if (shop == null){
            refreshVendorState()
        }

        if (shop != null){
            coroutineScope.launch(exceptionHandler) {
                val info = operation.getShopInfo(shop.shopId)
                curNumber = info.number
                vendorState.value = VendorState.RunningShop(shop.shopId, info)
            }
        }
    }

    fun callNumber(number: Int) {
        val state = vendorState.value
        logg("callNumber: $number, state: $state")
        coroutineScope.launch (exceptionHandler) {
            //TODO could make the update number parallel with number calling
            if (state is VendorState.RunningShop){
                operation.updateNumber(state.shopId, number)
            }
            if (!hasSound.value){
                return@launch
            }
            audioController.numberCalling(number)
        }
    }

    fun getConfig(): NumberTicketVendorConsoleConfigs {
        return _uiModel.value.config
    }


    fun vendorStateFlow(): StateFlow<VendorState> {
        return vendorState
    }

    fun startRunningShop(project: Project) {
        coroutineScope.launch(exceptionHandler) {
            val state = vendorState.value
            if (state is VendorState.VendorConsole){
                with(state.project){
                    operation.createShop(project).let { (shopId, shop) ->
                        vendorState.value = VendorState.RunningShop(shopId, shop)
                    }
                }
            }

        }
    }

    fun shutdownShop(controlPadId: String) {
        coroutineScope.launch(exceptionHandler) {
            operation.shutDownShop(controlPadId)
            refreshVendorState(checkShop = false, checkProject = true)
        }
    }

    fun getNumberPad(): NumberPad {
        val model = LEDModel.CallNumber

        return NumberPad(
            model = model,
            numbers = listOf("1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", "X", "↑", "R", "↓"),
            curNumber = mutableStateOf(curNumber),
            clickAction =  { value: String ->
                when (value) {
                    "X" -> {
                        curNumber = 0
                    }
                    "↑" -> {
                        if (curNumber > 0){
                            curNumber--
                            if (curNumber > 0){
                                callNumber(curNumber)
                            }
                        }
                    }
                    "↓" -> {
                        curNumber++
                        callNumber(curNumber)
                    }
                    "R" -> {
                        callNumber(curNumber)
                    }
                    else -> {
                        if (value.toIntOrNull() != null && curNumber.toString().length < model.defaultDigits){
                            curNumber = curNumber * 10 + value.toInt()
                        }
                    }
                }
            }
        )
    }

    fun getNumber(): State<Int> {
        return mutableStateOf(curNumber)
    }

    fun getWaitingNumber(shopId: String): Flow<Int> {
        return operation.getWaitingNumber(shopId).catch {
            //do nothing
            logg("getWaitingNumber error: $it")
        }
    }

    fun getNumberCallingSoundOn() = hasSound

    fun updateNumberCalling(checked: Boolean){
        hasSound.value = checked
    }

    fun createProject(shopName: String, shopType: ShopType) {
        coroutineScope.launch(exceptionHandler) {
            val (projectId, project) = operation.createProject(shopName, shopType)
            vendorState.value = VendorState.VendorConsole(projectId, project)
        }
    }

    fun init() {
        coroutineScope.launch {
            manager.didLaunchApp()
        }
    }

    fun refreshVendorState(checkShop: Boolean = true, checkProject: Boolean = true){
        coroutineScope.launch (exceptionHandler) {
            val project = if(checkProject) async { operation.checkCreatedProject() } else null
            val runningShop = if(checkShop) async { operation.checkRunningShop() } else null

            runningShop?.await()?.let { (id, shop) ->
                curNumber = shop.number
                vendorState.value = VendorState.RunningShop(id, shop)
            } ?: project?.await()?.let { (id, project) ->
                vendorState.value = VendorState.VendorConsole(id, project)
            } ?: run {
                vendorState.value = VendorState.InitConsole
            }
        }
    }

    fun updateProjectName(shopName: String){
        val projectId = if (vendorState.value is VendorState.VendorConsole){
            (vendorState.value as VendorState.VendorConsole).projectId
        } else {
            return
        }
        _uploadStatus.value = UploadStatus.Uploading
        coroutineScope.launch(uploadExceptionHandler) {
            operation.updateProjectName(projectId, shopName)
            val state = vendorState.value as VendorState.VendorConsole
            vendorState.value = state.copy(
                project = state.project.copy(shopName = shopName)
            )
            _uploadStatus.value = UploadStatus.Success
        }
    }

    fun updateProjectLogo(newLogo: ByteArray) {
        val projectId = if (vendorState.value is VendorState.VendorConsole){
            (vendorState.value as VendorState.VendorConsole).projectId
        } else {
            return
        }
        _uploadStatus.value = UploadStatus.Uploading
        coroutineScope.launch(uploadExceptionHandler) {
            val worker = Worker(getString(Res.string.base_url))
            val url = worker.uploadFile(
                path = "projects/$projectId/logo",
                contentType = "image/png",
                byteArray = newLogo
            )
            operation.updateProjectLogo(projectId, url)
            val state = vendorState.value as VendorState.VendorConsole
            vendorState.value = state.copy(
                project = state.project.copy(shopLogo = url)
            )
            _uploadStatus.value = UploadStatus.Success
        }
    }
}


sealed class VendorState{

    data class RunningShop(val shopId: String, val shop: VendorShop): VendorState()
    data class VendorConsole(val projectId: String, val project: Project): VendorState()
    data object InitConsole: VendorState()
    data object Loading: VendorState()
}

/**
 * This order is important, never change it. Should always follow with latest spec
 */
enum class ShopType(val typeValue: Int) {
    SIMPLE_CALL_NUMBER(0),
    USER_TAKE_NUMBER(1),
    VENDOR_PROVIDE_NUMBER(2);

    fun pages(): List<Page> {
        return when(this){
            SIMPLE_CALL_NUMBER -> listOf(Page.NUMBER_DISPLAY, Page.CALL_NUMBER)
            USER_TAKE_NUMBER -> listOf(Page.NUMBER_DISPLAY, Page.CALL_NUMBER, Page.TAKE_NUMBER)
            VENDOR_PROVIDE_NUMBER -> listOf(Page.NUMBER_DISPLAY, Page.CALL_NUMBER, Page.PROVIDE_NUMBER)
        }
    }

    companion object {
        fun fromIndex(index: Int) = entries.first { it.ordinal == index }
        fun fromValue(value: Int) = entries.first { it.typeValue == value }
    }
}

sealed interface UploadStatus {
    data object Success: UploadStatus
    data class Failed(val message: String): UploadStatus
    data object Uploading: UploadStatus
    data object Idle: UploadStatus
}