package tim.huang.genlayout.model

import androidx.compose.runtime.State

data class NumberPad(
    val model :LEDModel,
    val numbers: List<String>,
    val curNumber: State<Int>,
    val clickAction: (String) -> Unit,
)
