import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "gu", default = false)
val GuStrings = Strings(
    projectNameDialogIntro1 = "હેલો! અહીં અમે એક નંબર ટિકિટ સેવા બનાવવાનું છે",
	projectNameDialogIntro2 = "કૃપા કરીને તમારું દુકાન નામ દાખલ કરો:",
	projectNameDialogShopName = "દુકાનનું નામ",
	submit = "સબમિટ કરો",
	projectOptionsDialogIntro = { "હાય, ${it}, તમે કેવી પ્રકારની સેવા ઇચ્છો છો:" },
	projectOptionsDialogShopType0Title = "સરળ નંબર કોલિંગ સેવા",
	projectOptionsDialogShopType0Description = "દુકાનના માલિક/રક્ષક એક સરળ ટૅપ સાથે એપ પર નંબર કૉલ કરી શકે છે, જે પ્રક્રિયાને ઝડપી અને અસરકારક બનાવે છે.",
	projectOptionsDialogShopType1Title = "ગ્રાહક નંબર સેવા લો",
	projectOptionsDialogShopType1Description = "ગ્રાહકો નંબર લઈ શકે છે અને નંબર કૉલ કરવા માટે રાહ જોઈ શકે છે, રાહ જોઈ રહી છે કે પ્રક્રિયા મૌન અને ઝડપી છે.",
	projectOptionsDialogShopType2Title = "નંબર સેવા પૂરી પાડો",
	projectOptionsDialogShopType2Description = "દુકાનના માલિક/રક્ષક નંબર પ્રદાન કરે છે, અને કસ્ટમાઇઝ કરી શકાય તેવી સેટિંગ્સ સાથે તમારા વ્યવસાયની જરૂરિયાતોને અનુકૂલન કરવા માટે નંબર કોલિંગ અનુભવને કસ્ટમાઇઝ કરે છે.",
	vendorConsoleStartRunning = "દોડવાનું શરૂ કરો",
	numberDisplayScreenTitle = "અહીં નંબર લો!",
	qrCodeDescription = { "${it} વેબસાઇટનો સંદર્ભ આપતી QR કોડ" },
	takeNumberScreenInputPhoneNumber = "તમારો ફોન નંબર દાખલ કરો",
	takeNumberScreenPhoneNumber = "ફોન નંબર",
	provideNumberPadGetNumber = "નંબર મેળવો",
	provideNumberPadCurrentNumber = "હાલનો નંબર",
	provideNumberPadYourNumber = { "તમારો નંબર ${it} છે" },
	info = "માહિતી",
	callNumberPadCurrentWaitingNumber = { "વર્તમાન પ્રતિક્ષાસ્થાન નં: ${it}" },
	displayNumberTitle = "નંબર ડિસ્પ્લે પેજ",
	customerTakeNumberTitle = "ગ્રાહક નંબર પેજ",
	provideNumberTitle = "નંબર પેજ પ્રદાન કરો",
	yourNumberTitle = "તમારો નંબર પેજ",
	callNumberTitle = "કૉલ નંબર પેજ",
	setting = "સેટિંગ",
	callNumberPadSoundSwitch = "ધ્વનિ ચાલુ/બંધ",
	shutDown = "બંધ કરો",
	ok = "બરાબર",
	cancel = "રદ કરો",
	startRunningShop = "દુકાન ચલાવવાનું શરૂ કરો",
	getToWork = "ચાલો કામમાં લાગીએ!",
	uploadSuccess = "અપલોડ સફળતાપૂર્વક થયું!",
	uploadFailed = "અપલોડ નિષ્ફળ ગયું!",
	imagePickerDialogTitle = "તમારા દુકાનનો લોગો અપડેટ કરો",
	imagePickerDialogAction = "છબી પસંદ કરો",
	cropImageDialogTitle = "તમારો લોગો બતાવવા માટે પુષ્ટિ કરો",
	editShopNameDialogTitle = "તમારું દુકાનનું નામ અપડેટ કરો"
)