import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "fa", default = false)
val FaStrings = Strings(
    projectNameDialogIntro1 = "سلام! اینجا ما قصد داریم یک سرویس بلیط شماره‌ای ایجاد کنیم",
	projectNameDialogIntro2 = "لطفاً نام فروشگاه خود را وارد کنید:",
	projectNameDialogShopName = "نام فروشگاه",
	submit = "ارسال",
	projectOptionsDialogIntro = { "سلام، ${it}، چه نوع خدماتی می‌خواهید:" },
	projectOptionsDialogShopType0Title = "خدمات تماس شماره ساده",
	projectOptionsDialogShopType0Description = "صاحب/نگهدار فروشگاه می‌تواند با یک ضربه ساده روی برنامه، شماره‌ها را فراخوانی کند و فرآیند را سریع و کارآمد کند.",
	projectOptionsDialogShopType1Title = "خدمات گرفتن شماره مشتری",
	projectOptionsDialogShopType1Description = "مشتریان می‌توانند شماره بگیرند و منتظر شماره تماس باشند، اطمینان حاصل کنید که فرآیند انتظار روان و سریع است.",
	projectOptionsDialogShopType2Title = "ارائه خدمات شماره",
	projectOptionsDialogShopType2Description = "صاحب/نگهدار فروشگاه شماره فراهم می‌کند و تجربه تماس با شماره را با تنظیمات قابل تنظیم برای رفع نیازهای تجاری شما تطبیق می‌دهد.",
	vendorConsoleStartRunning = "شروع به دویدن",
	numberDisplayScreenTitle = "اینجا شماره بگیرید!",
	qrCodeDescription = { "کد QR اشاره کننده به وب سایت ${it}" },
	takeNumberScreenInputPhoneNumber = "شماره تلفن خود را وارد کنید",
	takeNumberScreenPhoneNumber = "شماره تلفن",
	provideNumberPadGetNumber = "دریافت شماره",
	provideNumberPadCurrentNumber = "شماره فعلی",
	provideNumberPadYourNumber = { "شماره شما ${it} است" },
	info = "اطلاعات",
	callNumberPadCurrentWaitingNumber = { "شماره انتظار فعلی: ${it}" },
	displayNumberTitle = "صفحه نمایش شماره",
	customerTakeNumberTitle = "صفحه شماره مشتری",
	provideNumberTitle = "صفحه شماره را ارائه دهید",
	yourNumberTitle = "صفحه شماره شما",
	callNumberTitle = "صفحه شماره تماس",
	setting = "تنظیم",
	callNumberPadSoundSwitch = "صدا روشن/خاموش",
	shutDown = "خاموش کردن",
	ok = "باشه",
	cancel = "لغو کردن",
	startRunningShop = "شروع به اداره فروشگاه",
	getToWork = "بیا کار کنیم!",
	uploadSuccess = "آپلود موفقیت‌آمیز بود!",
	uploadFailed = "آپلود ناموفق بود!",
	imagePickerDialogTitle = "لوگوی فروشگاه خود را به‌روز کنید",
	imagePickerDialogAction = "یک تصویر انتخاب کنید",
	cropImageDialogTitle = "تأیید برای نمایش لوگوی شما",
	editShopNameDialogTitle = "نام فروشگاه خود را به‌روز کنید"
)