import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ja", default = false)
val JaStrings = Strings(
    projectNameDialogIntro1 = "こんにちは！ここで番号券サービスを作成します",
	projectNameDialogIntro2 = "店舗名を入力してください：",
	projectNameDialogShopName = "店名",
	submit = "送信",
	projectOptionsDialogIntro = { "こんにちは、${it}、どのようなサービスが必要ですか：" },
	projectOptionsDialogShopType0Title = "シンプルな番号呼び出しサービス",
	projectOptionsDialogShopType0Description = "店主はアプリでシンプルにタップするだけで番号を呼び出すことができ、プロセスを迅速かつ効率的にします。",
	projectOptionsDialogShopType1Title = "お客様番号取得サービス",
	projectOptionsDialogShopType1Description = "お客様は番号を取って呼び出しを待つことができ、待ち時間がスムーズかつ迅速であることを保証します。",
	projectOptionsDialogShopType2Title = "番号サービスを提供する",
	projectOptionsDialogShopType2Description = "店主は番号を提供し、カスタマイズ可能な設定でビジネスニーズに合わせた番号呼び出し体験を提供します。",
	vendorConsoleStartRunning = "走り始める",
	numberDisplayScreenTitle = "ここで番号を取ってください！",
	qrCodeDescription = { "${it} ウェブサイトへのQRコード" },
	takeNumberScreenInputPhoneNumber = "電話番号を入力してください",
	takeNumberScreenPhoneNumber = "電話番号",
	provideNumberPadGetNumber = "番号を取得",
	provideNumberPadCurrentNumber = "現在の番号",
	provideNumberPadYourNumber = { "あなたの番号は ${it} です" },
	info = "情報",
	callNumberPadCurrentWaitingNumber = { "現在の待ち番号: ${it}" },
	displayNumberTitle = "番号表示ページ",
	customerTakeNumberTitle = "お客様番号ページ",
	provideNumberTitle = "番号ページを提供する",
	yourNumberTitle = "あなたの番号ページ",
	callNumberTitle = "呼び出し番号ページ",
	setting = "設定",
	callNumberPadSoundSwitch = "サウンドオン/オフ",
	shutDown = "シャットダウン",
	ok = "確認",
	cancel = "キャンセル",
	startRunningShop = "店舗の運営を開始",
	getToWork = "仕事を始めましょう！",
	uploadSuccess = "アップロード成功！",
	uploadFailed = "アップロード失敗！",
	imagePickerDialogTitle = "お店のロゴを更新してください",
	imagePickerDialogAction = "画像を選択",
	cropImageDialogTitle = "ロゴを表示することを確認",
	editShopNameDialogTitle = "店舗名を更新してください"
)