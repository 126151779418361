import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ne", default = false)
val NeStrings = Strings(
    projectNameDialogIntro1 = "नमस्ते! यहाँ हामी एक नम्बर टिकट सेवा सिर्जना गर्दैछौं",
	projectNameDialogIntro2 = "कृपया आफ्नो पसलको नाम प्रविष्ट गर्नुहोस्:",
	projectNameDialogShopName = "पसलको नाम",
	submit = "पेश गर्नुहोस्",
	projectOptionsDialogIntro = { "नमस्ते, ${it}, तपाइँलाई कुन प्रकारको सेवा चाहिन्छ:" },
	projectOptionsDialogShopType0Title = "सरल नम्बर कलिंग सेवा",
	projectOptionsDialogShopType0Description = "दोकानको मालिक/रखवालाले एपमा एक साधारण ट्याप गरेर नम्बरहरू कल गर्न सक्दछन्, जसले प्रक्रियालाई छिटो र कुशल बनाउँछ।",
	projectOptionsDialogShopType1Title = "ग्राहक नम्बर सेवा लिनुहोस्",
	projectOptionsDialogShopType1Description = "ग्राहकहरूले नम्बर लिन सक्छन् र नम्बर कल गर्न पर्खन सक्छन्, पर्खने प्रक्रिया सहज र छिटो बनाउन सुनिश्चित गर्नुहोस्।",
	projectOptionsDialogShopType2Title = "संख्या सेवा प्रदान गर्नुहोस्",
	projectOptionsDialogShopType2Description = "दोकान मालिक/रखवाला नम्बर प्रदान गर्दछ, र अनुकूलन योग्य सेटिंग्सको साथ तपाईंको व्यापार आवश्यकताहरू अनुरूप नम्बर कलिंग अनुभवलाई अनुकूल बनाउँछ।",
	vendorConsoleStartRunning = "दौड सुरु गर्नुहोस्",
	numberDisplayScreenTitle = "यहाँ नम्बर लिनुहोस्!",
	qrCodeDescription = { "${it} वेबसाइटलाई सन्दर्भ गर्ने QR कोड" },
	takeNumberScreenInputPhoneNumber = "तपाईंको फोन नम्बर प्रविष्ट गर्नुहोस्",
	takeNumberScreenPhoneNumber = "फोन नम्बर",
	provideNumberPadGetNumber = "नम्बर प्राप्त गर्नुहोस्",
	provideNumberPadCurrentNumber = "वर्तमान नम्बर",
	provideNumberPadYourNumber = { "तपाईंको नम्बर ${it} हो" },
	info = "जानकारी",
	callNumberPadCurrentWaitingNumber = { "वर्तमान प्रतीक्षा संख्या: ${it}" },
	displayNumberTitle = "संख्या प्रदर्शन पृष्ठ",
	customerTakeNumberTitle = "ग्राहक नम्बर पृष्ठ",
	provideNumberTitle = "संख्या पृष्ठ प्रदान गर्नुहोस्",
	yourNumberTitle = "तपाईंको नम्बर पृष्ठ",
	callNumberTitle = "कल नम्बर पृष्ठ",
	setting = "सेटिङ",
	callNumberPadSoundSwitch = "ध्वनि अन/अफ",
	shutDown = "बन्द गर्नुहोस्",
	ok = "हुन्छ",
	cancel = "रद्द गर्नुहोस्",
	startRunningShop = "पसल चलाउन सुरू गर्नुहोस्",
	getToWork = "अब काम सुरु गरौं!",
	uploadSuccess = "अपलोड सफल भयो!",
	uploadFailed = "अपलोड असफल भयो!",
	imagePickerDialogTitle = "तपाईँको पसलको लोगो अद्यावधिक गर्नुहोस्",
	imagePickerDialogAction = "तस्बिर छान्नुहोस्",
	cropImageDialogTitle = "तपाईँको लोगो देखाउन पुष्टि गर्नुहोस्",
	editShopNameDialogTitle = "तपाईँको पसलको नाम अद्यावधिक गर्नुहोस्"
)