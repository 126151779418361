import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "yo", default = false)
val YoStrings = Strings(
    projectNameDialogIntro1 = "Pẹlẹ o! Nibi a yoo ṣe iṣẹ tikẹti nọmba kan",
	projectNameDialogIntro2 = "Jọwọ tẹ orukọ itaja rẹ sii:",
	projectNameDialogShopName = "Orukọ itaja",
	submit = "Fi ranṣẹ",
	projectOptionsDialogIntro = { "Pẹlẹ o, ${it}, iru iṣẹ wo ni o fẹ:" },
	projectOptionsDialogShopType0Title = "Iṣẹ Ipe Nọmba Rọrun",
	projectOptionsDialogShopType0Description = "Ẹni to ni itaja/tabi alábojútó le pè àwọn nọ́mbà pẹ̀lú fífi ọwọ́ kan tó rọrun lori app náà, tó ń mú kí ètò náà yara àti pé ó fi ìmọ̀ ṣe.",
	projectOptionsDialogShopType1Title = "Iṣẹ Gbigba Nọmba Onibara",
	projectOptionsDialogShopType1Description = "Awọn onibara le gba nọmba ati duro de nọmba pipe, rii daju pe ilana iduro jẹ dan ati yara.",
	projectOptionsDialogShopType2Title = "Pese Iṣẹ Nọmba",
	projectOptionsDialogShopType2Description = "Eniti o ni itaja/tabi eni to n ṣetọju rẹ n pese nọmba, ati ṣe itẹnumọ iriri pipe nọmba lati ba awọn aini iṣowo rẹ mu pẹlu awọn eto ti o le ṣe adani.",
	vendorConsoleStartRunning = "Bẹrẹ ṣiṣe",
	numberDisplayScreenTitle = "Mu nọmba nibi!",
	qrCodeDescription = { "Koodu QR ti o tọka si oju opo wẹẹbu ${it}" },
	takeNumberScreenInputPhoneNumber = "Tẹ nọmba foonu rẹ sii",
	takeNumberScreenPhoneNumber = "Nọmba foonu",
	provideNumberPadGetNumber = "Gba nọmba",
	provideNumberPadCurrentNumber = "Nọmba lọwọlọwọ",
	provideNumberPadYourNumber = { "Nọmba rẹ jẹ ${it}" },
	info = "Alaye",
	callNumberPadCurrentWaitingNumber = { "Nọmba idaduro lọwọlọwọ: ${it}" },
	displayNumberTitle = "Ojú-ìwé àpapọ̀ nomba",
	customerTakeNumberTitle = "Oju-iwe nọmba alabara",
	provideNumberTitle = "Pese oju-iwe nọmba",
	yourNumberTitle = "Ojú-ìwé Nọ́mbà rẹ",
	callNumberTitle = "Ojú-ìwé Nọ́mbà Ìpè",
	setting = "Eto",
	callNumberPadSoundSwitch = "Gbohungbohun Lọ/Awọn",
	shutDown = "Pa kuro",
	ok = "O daa",
	cancel = "Fagile",
	startRunningShop = "Bẹrẹ ṣiṣe ile itaja",
	getToWork = "Ẹ jẹ ká bẹ̀rẹ̀ iṣẹ́!",
	uploadSuccess = "Àwọnlódè parí nípasẹ̀!",
	uploadFailed = "Àwọnlódè kọ̀!",
	imagePickerDialogTitle = "Ṣètò àwòrán ilé ìtajà rẹ",
	imagePickerDialogAction = "Yan àwòrán kan",
	cropImageDialogTitle = "Ṣe ìfàṣẹsí láti fihàn àwòrán àpèjọ rẹ",
	editShopNameDialogTitle = "Ṣètò orúkọ ilé ìtajà rẹ"
)