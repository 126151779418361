import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "zh-TW", default = false)
val ZhTWStrings = Strings(
    projectNameDialogIntro1 = "你好！我們將創建一個號碼票服務",
	projectNameDialogIntro2 = "請輸入您的店鋪名稱：",
	projectNameDialogShopName = "店名",
	submit = "提交",
	projectOptionsDialogIntro = { "嗨，${it}，你想要什麼樣的服務：" },
	projectOptionsDialogShopType0Title = "簡單的號碼呼叫服務",
	projectOptionsDialogShopType0Description = "店主可以在應用上輕點一下呼叫號碼，使過程快速高效。",
	projectOptionsDialogShopType1Title = "顧客取號服務",
	projectOptionsDialogShopType1Description = "顧客可以取號並等待叫號，確保等待過程順暢快捷。",
	projectOptionsDialogShopType2Title = "提供號碼服務",
	projectOptionsDialogShopType2Description = "店主提供號碼，並通過可定制的設置調整呼叫體驗，以滿足您的業務需求。",
	vendorConsoleStartRunning = "開始運行",
	numberDisplayScreenTitle = "在這裡取號！",
	qrCodeDescription = { "指向 ${it} 網站的二維碼" },
	takeNumberScreenInputPhoneNumber = "輸入您的電話號碼",
	takeNumberScreenPhoneNumber = "電話號碼",
	provideNumberPadGetNumber = "取得號碼",
	provideNumberPadCurrentNumber = "當前號碼",
	provideNumberPadYourNumber = { "您的號碼是 ${it}" },
	info = "資訊",
	callNumberPadCurrentWaitingNumber = { "當前等待號碼: ${it}" },
	displayNumberTitle = "號碼顯示頁面",
	customerTakeNumberTitle = "顧客取號頁面",
	provideNumberTitle = "提供號碼頁面",
	yourNumberTitle = "您的號碼頁面",
	callNumberTitle = "呼叫號碼頁面",
	setting = "設定",
	callNumberPadSoundSwitch = "聲音開/關",
	shutDown = "關閉",
	ok = "確定",
	cancel = "取消",
	startRunningShop = "開始營運",
	getToWork = "開始工作吧！",
	uploadSuccess = "上傳成功！",
	uploadFailed = "上傳失敗！",
	imagePickerDialogTitle = "更新你的商標",
	imagePickerDialogAction = "選取圖片",
	cropImageDialogTitle = "確認你的商標",
	editShopNameDialogTitle = "更新商店名稱"
)