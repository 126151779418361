import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "el", default = false)
val ElStrings = Strings(
    projectNameDialogIntro1 = "Γειά σας! Εδώ θα δημιουργήσουμε μια υπηρεσία εισιτηρίων με αριθμούς",
	projectNameDialogIntro2 = "Παρακαλώ εισάγετε το όνομα του καταστήματός σας:",
	projectNameDialogShopName = "Όνομα καταστήματος",
	submit = "Υποβολή",
	projectOptionsDialogIntro = { "Γεια, ${it}, τι είδους υπηρεσία θέλετε:" },
	projectOptionsDialogShopType0Title = "Απλή Υπηρεσία Κλήσεων Αριθμών",
	projectOptionsDialogShopType0Description = "Ο ιδιοκτήτης/διαχειριστής του καταστήματος μπορεί να καλέσει αριθμούς με ένα απλό πάτημα στην εφαρμογή, καθιστώντας τη διαδικασία γρήγορη και αποτελεσματική.",
	projectOptionsDialogShopType1Title = "Υπηρεσία Λήψης Αριθμού Πελάτη",
	projectOptionsDialogShopType1Description = "Οι πελάτες μπορούν να πάρουν έναν αριθμό και να περιμένουν για την κλήση αριθμού, εξασφαλίζοντας ότι η διαδικασία αναμονής είναι ομαλή και γρήγορη.",
	projectOptionsDialogShopType2Title = "Παροχή υπηρεσίας αριθμών",
	projectOptionsDialogShopType2Description = "Ο ιδιοκτήτης/διαχειριστής του καταστήματος παρέχει αριθμό και προσαρμόζει την εμπειρία κλήσης αριθμού ώστε να ταιριάζει στις επιχειρηματικές σας ανάγκες με προσαρμόσιμες ρυθμίσεις.",
	vendorConsoleStartRunning = "Ξεκινήστε να τρέχετε",
	numberDisplayScreenTitle = "Πάρτε αριθμό εδώ!",
	qrCodeDescription = { "Κωδικός QR που παραπέμπει στον ιστότοπο ${it}" },
	takeNumberScreenInputPhoneNumber = "Εισαγάγετε τον αριθμό τηλεφώνου σας",
	takeNumberScreenPhoneNumber = "Αριθμός τηλεφώνου",
	provideNumberPadGetNumber = "Πάρετε αριθμό",
	provideNumberPadCurrentNumber = "Τρέχων αριθμός",
	provideNumberPadYourNumber = { "Ο αριθμός σας είναι ${it}" },
	info = "Πληροφορίες",
	callNumberPadCurrentWaitingNumber = { "Τρέχων αριθμός αναμονής: ${it}" },
	displayNumberTitle = "Σελίδα εμφάνισης αριθμών",
	customerTakeNumberTitle = "Σελίδα αριθμού πελάτη",
	provideNumberTitle = "Παρέχετε αριθμητική σελίδα",
	yourNumberTitle = "Η σελίδα αριθμού σας",
	callNumberTitle = "Σελίδα αριθμού κλήσης",
	setting = "Ρύθμιση",
	callNumberPadSoundSwitch = "Ήχος Ενεργό/Ανενεργό",
	shutDown = "Κλείσιμο",
	ok = "Εντάξει",
	cancel = "Ακύρωση",
	startRunningShop = "Ξεκινήστε τη λειτουργία του καταστήματος",
	getToWork = "Πάμε στη δουλειά!",
	uploadSuccess = "Η μεταφόρτωση ολοκληρώθηκε με επιτυχία!",
	uploadFailed = "Η μεταφόρτωση απέτυχε!",
	imagePickerDialogTitle = "Ενημερώστε το λογότυπο του καταστήματός σας",
	imagePickerDialogAction = "Επιλέξτε μια εικόνα",
	cropImageDialogTitle = "Επιβεβαιώστε για να εμφανίσετε το λογότυπό σας",
	editShopNameDialogTitle = "Ενημερώστε το όνομα του καταστήματός σας"
)