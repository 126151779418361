package tim.huang.genlayout.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import cafe.adriel.lyricist.strings
import org.koin.compose.koinInject
import org.koin.core.parameter.parametersOf
import tim.huang.genlayout.model.Project
import tim.huang.genlayout.ui.theme.lightGray
import tim.huang.genlayout.ui.utils.mobileLayout
import tim.huang.genlayout.ui.widget.TitleLogoRow
import tim.huang.genlayout.viewmodel.TakeNumberUiState
import tim.huang.genlayout.viewmodel.TakeNumberViewModel

@Composable
fun TakeNumberSimulationScreen(
    project: Project,
    takeNumberViewModel: TakeNumberViewModel  = koinInject{ parametersOf(false, "") },
    modifier: Modifier = Modifier
){

    val uiState by takeNumberViewModel.uiState().collectAsState()

    when(uiState){
        is TakeNumberUiState.NavigateToYourNumber -> {
            YourNumberScreen(project.shopName, project.shopLogo, 0, 0)
        }
        else -> TakeNumberScreen(project.shopName, project.shopLogo, takeNumberViewModel::takeNumberByPhone)
    }
}

@Composable
fun TakeNumberScreen(title: String, logo: String?, takeNumberByPhone: (String)-> Unit, modifier: Modifier = Modifier) {

    var textValue by remember { mutableStateOf("") }

    Column(modifier = modifier.mobileLayout().background(lightGray)) {

        TitleLogoRow(title, logo)

        Column(
            modifier = modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {

            Text(
                text = strings.takeNumberScreenInputPhoneNumber,
                style = MaterialTheme.typography.h4,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(16.dp),
                color = Color.Black,
            )

            OutlinedTextField(
                value = textValue,
                onValueChange = { textValue = it },
                label = { Text(strings.takeNumberScreenPhoneNumber) },
                modifier = Modifier.fillMaxWidth(0.6f).padding(16.dp),
                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Phone)
            )


            OutlinedButton(
                modifier = Modifier.padding(16.dp),
                onClick = { takeNumberByPhone(textValue) }){
                Text(
                    strings.submit,
                    style = MaterialTheme.typography.h6,
                    fontWeight = FontWeight.Bold,
                )
            }
        }
    }
}