package tim.huang.genlayout.auth

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.GoogleAuthProvider
import dev.gitlive.firebase.auth.UserInfo
import dev.gitlive.firebase.auth.auth
import firebaseApp
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


val coroutineScope = CoroutineScope(Job())

fun firebaseAuth(clientId: String, credential: String) {

    val auth = Firebase.auth(firebaseApp)

    coroutineScope.launch {
        val authCredential = GoogleAuthProvider.credential(idToken = credential, accessToken = null)
        auth.signInWithCredential(authCredential)
        //firebase cache user login state, reload the page
        delay(300)
        window.location.reload()
    }
}

fun getGoogleId(userInfos: List<UserInfo>): String? {
    return userInfos.find { it.providerId == "google.com" }?.uid
}