import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ms", default = false)
val MsStrings = Strings(
    projectNameDialogIntro1 = "Hello! Di sini kami akan membuat perkhidmatan tiket nombor",
	projectNameDialogIntro2 = "Sila masukkan nama kedai anda:",
	projectNameDialogShopName = "Nama Kedai",
	submit = "Hantar",
	projectOptionsDialogIntro = { "Hai, ${it}, perkhidmatan jenis apa yang anda mahu:" },
	projectOptionsDialogShopType0Title = "Perkhidmatan Panggilan Nombor Mudah",
	projectOptionsDialogShopType0Description = "Pemilik/penjaga kedai boleh memanggil nombor dengan satu ketikan mudah pada aplikasi, menjadikan proses cepat dan cekap.",
	projectOptionsDialogShopType1Title = "Perkhidmatan Ambil Nombor Pelanggan",
	projectOptionsDialogShopType1Description = "Pelanggan boleh mengambil nombor dan menunggu nombor dipanggil, memastikan proses menunggu lancar dan cepat.",
	projectOptionsDialogShopType2Title = "Menyediakan Perkhidmatan Nombor",
	projectOptionsDialogShopType2Description = "Pemilik/penjaga kedai menyediakan nombor, dan menyesuaikan pengalaman panggilan nombor untuk memenuhi keperluan perniagaan anda dengan tetapan yang boleh disesuaikan.",
	vendorConsoleStartRunning = "Mula berlari",
	numberDisplayScreenTitle = "Ambil nombor di sini!",
	qrCodeDescription = { "Kod QR merujuk kepada laman web ${it}" },
	takeNumberScreenInputPhoneNumber = "Masukkan nombor telefon anda",
	takeNumberScreenPhoneNumber = "Nombor telefon",
	provideNumberPadGetNumber = "Dapatkan nombor",
	provideNumberPadCurrentNumber = "Nombor semasa",
	provideNumberPadYourNumber = { "Nombor anda adalah ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Nombor menunggu semasa: ${it}" },
	displayNumberTitle = "Halaman paparan nombor",
	customerTakeNumberTitle = "Halaman nombor pelanggan",
	provideNumberTitle = "Sediakan halaman nombor",
	yourNumberTitle = "Halaman nombor anda",
	callNumberTitle = "Halaman nombor panggilan",
	setting = "Tetapan",
	callNumberPadSoundSwitch = "Bunyi Hidup/Mati",
	shutDown = "Tutup",
	ok = "Ok",
	cancel = "Batal",
	startRunningShop = "Mula Menjalankan Kedai",
	getToWork = "Mari kita mulakan kerja!",
	uploadSuccess = "Muat naik berjaya!",
	uploadFailed = "Muat naik gagal!",
	imagePickerDialogTitle = "Kemas kini logo kedai anda",
	imagePickerDialogAction = "Pilih imej",
	cropImageDialogTitle = "Sahkan untuk memaparkan logo anda",
	editShopNameDialogTitle = "Kemas kini nama kedai anda"
)