import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "zh-CN", default = false)
val ZhCNStrings = Strings(
    projectNameDialogIntro1 = "你好！我们将创建一个号码票服务",
	projectNameDialogIntro2 = "请输入您的店铺名称：",
	projectNameDialogShopName = "店名",
	submit = "提交",
	projectOptionsDialogIntro = { "嗨，${it}，你想要什么样的服务：" },
	projectOptionsDialogShopType0Title = "简单的号码呼叫服务",
	projectOptionsDialogShopType0Description = "店主可以在应用上轻点一下呼叫号码，使过程快速高效。",
	projectOptionsDialogShopType1Title = "客户取号服务",
	projectOptionsDialogShopType1Description = "顾客可以取号并等待叫号，确保等待过程顺畅快捷。",
	projectOptionsDialogShopType2Title = "提供号码服务",
	projectOptionsDialogShopType2Description = "店主提供号码，并通过可定制的设置调整呼叫体验，以满足您的业务需求。",
	vendorConsoleStartRunning = "开始运行",
	numberDisplayScreenTitle = "在这里取号！",
	qrCodeDescription = { "指向 ${it} 网站的二维码" },
	takeNumberScreenInputPhoneNumber = "输入您的电话号码",
	takeNumberScreenPhoneNumber = "电话号码",
	provideNumberPadGetNumber = "获取号码",
	provideNumberPadCurrentNumber = "当前号码",
	provideNumberPadYourNumber = { "您的号码是 ${it}" },
	info = "信息",
	callNumberPadCurrentWaitingNumber = { "当前等待号码: ${it}" },
	displayNumberTitle = "号码显示页面",
	customerTakeNumberTitle = "顾客取号页面",
	provideNumberTitle = "提供号码页面",
	yourNumberTitle = "您的号码页面",
	callNumberTitle = "呼叫号码页面",
	setting = "设置",
	callNumberPadSoundSwitch = "声音开/关",
	shutDown = "关闭",
	ok = "确定",
	cancel = "取消",
	startRunningShop = "开始营业",
	getToWork = "开始工作吧！",
	uploadSuccess = "上传成功！",
	uploadFailed = "上传失败！",
	imagePickerDialogTitle = "更新你的商标",
	imagePickerDialogAction = "选择图片",
	cropImageDialogTitle = "确认你的商标",
	editShopNameDialogTitle = "更新店名"
)