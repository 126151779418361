package tim.huang.genlayout.di

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.MainScope
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf
import org.koin.core.parameter.parametersOf
import org.koin.dsl.bind
import org.koin.dsl.module
import tim.huang.genlayout.data.operation.DatabaseOperation
import tim.huang.genlayout.data.operation.FirebaseOperation
import tim.huang.genlayout.data.operation.SimulationOperation
import tim.huang.genlayout.model.UserManager
import tim.huang.genlayout.viewmodel.NumberDisplayPadViewModel
import tim.huang.genlayout.viewmodel.ProvideNumberViewModel
import tim.huang.genlayout.viewmodel.TakeNumberViewModel


expect val platformModule: Module

val sharedModule = module {
    singleOf(::UserManager)
    single { Firebase.auth(get()) }
    single { (isLive: Boolean) -> if (isLive) FirebaseOperation(get()) else SimulationOperation() }.bind<DatabaseOperation>()
    singleOf(::FirebaseOperation)

    //viewModel
    factory { (isLive: Boolean, takeNumberPadId: String) -> TakeNumberViewModel(get(), get { parametersOf(isLive) }, takeNumberPadId)  }
    factory { (isLive: Boolean, shopId: String) -> NumberDisplayPadViewModel(get(), get(), get { parametersOf(isLive) }, shopId) }
    factory { (isLive: Boolean, shopId: String) -> ProvideNumberViewModel(get(), get { parametersOf(isLive) }, shopId)  }

    //whole app have only one coroutine scope in main.
    //If need certain one, inject with parameter or use named injection
    single { MainScope() }
}