@file:JsExport

import kotlinx.browser.document
import org.w3c.dom.HTMLDivElement


fun showLoadingBanner(){
    (document.getElementById("loader") as? HTMLDivElement)?.let {
        it.style.display = "block"
    }
}

fun hideLoadingBanner(){
    (document.getElementById("loader") as? HTMLDivElement)?.let {
        it.style.display = "none"
    }
}