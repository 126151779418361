import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "lt", default = false)
val LtStrings = Strings(
    projectNameDialogIntro1 = "Sveiki! Čia sukursime numeruotų bilietų paslaugą",
	projectNameDialogIntro2 = "Prašome įvesti savo parduotuvės pavadinimą:",
	projectNameDialogShopName = "Parduotuvės pavadinimas",
	submit = "Pateikti",
	projectOptionsDialogIntro = { "Sveiki, ${it}, kokios paslaugos norėtumėte:" },
	projectOptionsDialogShopType0Title = "Paprasta numerių skambinimo paslauga",
	projectOptionsDialogShopType0Description = "Parduotuvės savininkas/prievaizdas gali skambinti numeriais paprastu bakstelėjimu programėlėje, todėl procesas tampa greitas ir efektyvus.",
	projectOptionsDialogShopType1Title = "Klientų numerių paslauga",
	projectOptionsDialogShopType1Description = "Klientai gali pasiimti numerį ir laukti numerio iškvietimo, užtikrindami, kad laukimo procesas būtų sklandus ir greitas.",
	projectOptionsDialogShopType2Title = "Teikti numerių paslaugą",
	projectOptionsDialogShopType2Description = "Parduotuvės savininkas / tvarkytojas pateikia numerį ir pritaiko numerių skambinimo patirtį, kad atitiktų jūsų verslo poreikius su pritaikomais nustatymais.",
	vendorConsoleStartRunning = "Pradėkite bėgti",
	numberDisplayScreenTitle = "Paimkite numerį čia!",
	qrCodeDescription = { "QR kodas, nurodantis ${it} svetainę" },
	takeNumberScreenInputPhoneNumber = "Įveskite savo telefono numerį",
	takeNumberScreenPhoneNumber = "Telefono numeris",
	provideNumberPadGetNumber = "Gauti numerį",
	provideNumberPadCurrentNumber = "Dabartinis numeris",
	provideNumberPadYourNumber = { "Jūsų numeris yra ${it}" },
	info = "Informacija",
	callNumberPadCurrentWaitingNumber = { "Dabartinis laukimo numeris: ${it}" },
	displayNumberTitle = "Numerių rodymo puslapis",
	customerTakeNumberTitle = "Kliento numerio puslapis",
	provideNumberTitle = "Suteikti numerio puslapį",
	yourNumberTitle = "Jūsų numerio puslapis",
	callNumberTitle = "Skambučio numerio puslapis",
	setting = "Nustatymas",
	callNumberPadSoundSwitch = "Garsas Įjungtas/Išjungtas",
	shutDown = "Išjungti",
	ok = "Gerai",
	cancel = "Atšaukti",
	startRunningShop = "Pradėkite valdyti parduotuvę",
	getToWork = "Pradėkime dirbti!",
	uploadSuccess = "Įkėlimas sėkmingas!",
	uploadFailed = "Įkėlimas nepavyko!",
	imagePickerDialogTitle = "Atnaujinkite savo parduotuvės logotipą",
	imagePickerDialogAction = "Pasirinkite vaizdą",
	cropImageDialogTitle = "Patvirtinkite, kad rodytumėte savo logotipą",
	editShopNameDialogTitle = "Atnaujinkite savo parduotuvės pavadinimą"
)