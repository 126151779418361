import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "vi", default = false)
val ViStrings = Strings(
    projectNameDialogIntro1 = "Xin chào! Ở đây chúng tôi sẽ tạo một dịch vụ vé số",
	projectNameDialogIntro2 = "Vui lòng nhập tên cửa hàng của bạn:",
	projectNameDialogShopName = "Tên cửa hàng",
	submit = "Gửi đi",
	projectOptionsDialogIntro = { "Chào, ${it}, bạn muốn loại dịch vụ nào:" },
	projectOptionsDialogShopType0Title = "Dịch vụ gọi số đơn giản",
	projectOptionsDialogShopType0Description = "Chủ/cửa hàng có thể gọi số bằng một lần chạm đơn giản trên ứng dụng, làm cho quá trình nhanh chóng và hiệu quả.",
	projectOptionsDialogShopType1Title = "Dịch vụ Lấy Số Khách Hàng",
	projectOptionsDialogShopType1Description = "Khách hàng có thể lấy số và chờ gọi số, đảm bảo quá trình chờ đợi diễn ra suôn sẻ và nhanh chóng.",
	projectOptionsDialogShopType2Title = "Cung cấp dịch vụ số",
	projectOptionsDialogShopType2Description = "Chủ/cửa hàng cung cấp số, và điều chỉnh trải nghiệm gọi số để phù hợp với nhu cầu kinh doanh của bạn với các cài đặt có thể tùy chỉnh.",
	vendorConsoleStartRunning = "Bắt đầu chạy",
	numberDisplayScreenTitle = "Lấy số ở đây!",
	qrCodeDescription = { "Mã QR tham chiếu đến trang web ${it}" },
	takeNumberScreenInputPhoneNumber = "Nhập số điện thoại của bạn",
	takeNumberScreenPhoneNumber = "Số điện thoại",
	provideNumberPadGetNumber = "Lấy số",
	provideNumberPadCurrentNumber = "Số hiện tại",
	provideNumberPadYourNumber = { "Số của bạn là ${it}" },
	info = "Thông tin",
	callNumberPadCurrentWaitingNumber = { "Số đang chờ hiện tại: ${it}" },
	displayNumberTitle = "Trang hiển thị số",
	customerTakeNumberTitle = "Trang số khách hàng",
	provideNumberTitle = "Cung cấp trang số",
	yourNumberTitle = "Trang số của bạn",
	callNumberTitle = "Trang số cuộc gọi",
	setting = "Cài đặt",
	callNumberPadSoundSwitch = "Âm thanh Bật/Tắt",
	shutDown = "Tắt máy",
	ok = "Đồng ý",
	cancel = "Hủy bỏ",
	startRunningShop = "Bắt đầu vận hành cửa hàng",
	getToWork = "Hãy bắt tay vào công việc nào!",
	uploadSuccess = "Tải lên thành công!",
	uploadFailed = "Tải lên thất bại!",
	imagePickerDialogTitle = "Cập nhật logo cửa hàng của bạn",
	imagePickerDialogAction = "Chọn một hình ảnh",
	cropImageDialogTitle = "Xác nhận hiển thị logo của bạn",
	editShopNameDialogTitle = "Cập nhật tên cửa hàng của bạn"
)