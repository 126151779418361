import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sl", default = false)
val SlStrings = Strings(
    projectNameDialogIntro1 = "Živjo! Tukaj bomo ustvarili storitev številskih vstopnic",
	projectNameDialogIntro2 = "Prosimo, vnesite ime svoje trgovine:",
	projectNameDialogShopName = "Ime trgovine",
	submit = "Pošlji",
	projectOptionsDialogIntro = { "Živjo, ${it}, kakšno storitev želite:" },
	projectOptionsDialogShopType0Title = "Preprosta storitev klicanja številk",
	projectOptionsDialogShopType0Description = "Lastnik/trgovec lahko kliče številke z enostavnim dotikom na aplikaciji, kar omogoča hiter in učinkovit postopek.",
	projectOptionsDialogShopType1Title = "Storitev prevzema številke stranke",
	projectOptionsDialogShopType1Description = "Stranke lahko vzamejo številko in čakajo na klicanje številke, kar zagotavlja, da je postopek čakanja gladek in hiter.",
	projectOptionsDialogShopType2Title = "Zagotovite storitev številk",
	projectOptionsDialogShopType2Description = "Lastnik/skrbnik trgovine zagotavlja številko in prilagaja izkušnjo klicanja številk tako, da ustreza vašim poslovnim potrebam z nastavitvami po meri.",
	vendorConsoleStartRunning = "Začnite teči",
	numberDisplayScreenTitle = "Vzemite številko tukaj!",
	qrCodeDescription = { "QR koda, ki se nanaša na spletno stran ${it}" },
	takeNumberScreenInputPhoneNumber = "Vnesite svojo telefonsko številko",
	takeNumberScreenPhoneNumber = "Telefonska številka",
	provideNumberPadGetNumber = "Dobite številko",
	provideNumberPadCurrentNumber = "Trenutna številka",
	provideNumberPadYourNumber = { "Vaša številka je ${it}" },
	info = "Informacije",
	callNumberPadCurrentWaitingNumber = { "Trenutna čakalna številka: ${it}" },
	displayNumberTitle = "Stran za prikaz številk",
	customerTakeNumberTitle = "Stran številke stranke",
	provideNumberTitle = "Zagotovite številčno stran",
	yourNumberTitle = "Vaša številčna stran",
	callNumberTitle = "Stran klicne številke",
	setting = "Nastavitev",
	callNumberPadSoundSwitch = "Zvok Vklopljen/Izklopljen",
	shutDown = "Izklopi",
	ok = "V redu",
	cancel = "Prekliči",
	startRunningShop = "Začnite voditi trgovino",
	getToWork = "Začnimo delati!",
	uploadSuccess = "Nalaganje uspešno!",
	uploadFailed = "Nalaganje ni uspelo!",
	imagePickerDialogTitle = "Posodobite logotip vaše trgovine",
	imagePickerDialogAction = "Izberite sliko",
	cropImageDialogTitle = "Potrdite prikaz svojega logotipa",
	editShopNameDialogTitle = "Posodobite ime svoje trgovine"
)