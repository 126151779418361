import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "cs", default = false)
val CsStrings = Strings(
    projectNameDialogIntro1 = "Ahoj! Zde vytvoříme službu číslovaných lístků",
	projectNameDialogIntro2 = "Zadejte prosím název vašeho obchodu:",
	projectNameDialogShopName = "Název obchodu",
	submit = "Odeslat",
	projectOptionsDialogIntro = { "Ahoj, ${it}, jaký druh služby chcete:" },
	projectOptionsDialogShopType0Title = "Jednoduchá služba volání čísel",
	projectOptionsDialogShopType0Description = "Majitel/správce obchodu může volat čísla jednoduchým klepnutím na aplikaci, což činí proces rychlým a efektivním.",
	projectOptionsDialogShopType1Title = "Služba pro Přijetí Čísla Zákazníka",
	projectOptionsDialogShopType1Description = "Zákazníci si mohou vzít číslo a počkat na vyvolání čísla, zajistí plynulý a rychlý průběh čekání.",
	projectOptionsDialogShopType2Title = "Poskytnout číslicovou službu",
	projectOptionsDialogShopType2Description = "Majitel/správce obchodu poskytuje číslo a přizpůsobuje zážitek z volání čísel podle potřeb vašeho podnikání pomocí přizpůsobitelných nastavení.",
	vendorConsoleStartRunning = "Začít běhat",
	numberDisplayScreenTitle = "Vezměte si číslo zde!",
	qrCodeDescription = { "QR kód odkazující na web ${it}" },
	takeNumberScreenInputPhoneNumber = "Zadejte své telefonní číslo",
	takeNumberScreenPhoneNumber = "Telefonní číslo",
	provideNumberPadGetNumber = "Získejte číslo",
	provideNumberPadCurrentNumber = "Aktuální číslo",
	provideNumberPadYourNumber = { "Vaše číslo je ${it}" },
	info = "Informace",
	callNumberPadCurrentWaitingNumber = { "Aktuální čekací číslo: ${it}" },
	displayNumberTitle = "Stránka zobrazení čísel",
	customerTakeNumberTitle = "Stránka čísla zákazníka",
	provideNumberTitle = "Poskytnout číselnou stránku",
	yourNumberTitle = "Vaše číselná stránka",
	callNumberTitle = "Stránka volání čísla",
	setting = "Nastavení",
	callNumberPadSoundSwitch = "Zvuk Zap/Vyp",
	shutDown = "Vypnout",
	ok = "Ok",
	cancel = "Zrušit",
	startRunningShop = "Začněte provozovat obchod",
	getToWork = "Pojďme do práce!",
	uploadSuccess = "Nahrání bylo úspěšné!",
	uploadFailed = "Nahrání se nezdařilo!",
	imagePickerDialogTitle = "Aktualizujte logo svého obchodu",
	imagePickerDialogAction = "Vyberte obrázek",
	cropImageDialogTitle = "Potvrďte zobrazení svého loga",
	editShopNameDialogTitle = "Aktualizujte název svého obchodu"
)