package tim.huang.genlayout.data.network

import genlayout.composeapp.generated.resources.Res
import genlayout.composeapp.generated.resources.worker_api_key
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.accept
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsChannel
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.content.ByteArrayContent
import io.ktor.http.isSuccess
import io.ktor.serialization.kotlinx.json.json
import io.ktor.util.toByteArray
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.getString

expect fun httpClient(config: HttpClientConfig<*>.() -> Unit = {}): HttpClient

internal val defaultHttpClient: HttpClient by lazy {
    httpClient{
        expectSuccess = true
        install(ContentNegotiation) {
            json(Json {
                isLenient = true
                ignoreUnknownKeys = true
                prettyPrint = true
            })
        }
    }
}

class Worker(private val baseUrl: String){
    val client = defaultHttpClient

    suspend fun uploadFile(path: String, byteArray: ByteArray, contentType: String, headers: Map<String, String> = emptyMap()): String {

        val response: HttpResponse = client.put(buildUrl(baseUrl, path)) {
            headers.forEach { (key, value) -> header(key, value) }
            val token = getString(Res.string.worker_api_key)
            header("Authorization", "Bearer $token")
            setBody(
                ByteArrayContent(
                    bytes = byteArray,
                    contentType = contentType.let { ContentType.parse(it) }
                )
            )
        }

        if (response.status.isSuccess()) {
            return response.bodyAsText()
        } else {
            throw Exception("Failed to upload file. Status: ${response.status}")
        }
    }

    suspend fun getImage(blobUrl: String): ByteArray {
        val response: HttpResponse = client.get(blobUrl) {
            accept(ContentType.Image.Any)
        }

        if (response.status.isSuccess()) {
            return response.bodyAsChannel().toByteArray()
        } else {
            throw Exception("Failed to download image. Status: ${response.status}")
        }
    }

    private fun buildUrl(baseUrl: String, path: String): String {
        return baseUrl.removeSuffix("/") + "/" + path.removePrefix("/")
    }
}