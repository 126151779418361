package tim.huang.genlayout.ui

import androidx.compose.animation.animateColor
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.withTransform
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import cafe.adriel.lyricist.strings
import io.github.alexzhirkevich.qrose.rememberQrCodePainter
import tim.huang.genlayout.model.LEDModel
import tim.huang.genlayout.model.QRcodeInfo
import tim.huang.genlayout.ui.theme.lightGray
import tim.huang.genlayout.ui.widget.TitleLogoRow

/**
 * NumberDisplayScreen display two kinds of layout if the [qrCodeInfo] is not null
 * aspect ratio bigger than 1:1 will consider expanded, the QR code will be displayed on right side
 * otherwise display at the bottom
 * NumberDisplayScreen display pure LED number if the [qrCodeInfo] is null
 */
@Composable
fun NumberDisplayScreen(
    title: String,
    logo: String?,
    number: Int, model: LEDModel,
    isConnecting: Boolean = false,
    qrCodeInfo: QRcodeInfo? = null,
    modifier: Modifier = Modifier,
){

    Column(modifier = modifier) {

        TitleLogoRow(title, logo)

        if (qrCodeInfo != null){
            NumberDisplayLEDQrcode(number, model, isConnecting, qrCodeInfo)
        }else{
            NumberDisplayLED(number, model, isConnecting)
        }
    }

}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun NumberDisplayLEDQrcode(
    number: Int,
    model: LEDModel,
    isConnecting: Boolean,
    qrCodeInfo: QRcodeInfo,
) {
    val windowSizeClass = calculateWindowSizeClass()
    val qrCodeTitleSize = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  MaterialTheme.typography.h5
        WindowWidthSizeClass.Medium ->  MaterialTheme.typography.h4
        else ->  MaterialTheme.typography.h3
    }

    Layout(
        modifier = Modifier.background(lightGray),
        content = {

            Surface (
                modifier = Modifier.padding(16.dp)
            ) {
                NumberDisplayLED(number, model, isConnecting)

            }

            Column (
                modifier = Modifier.padding(16.dp),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    strings.numberDisplayScreenTitle,
                    style = qrCodeTitleSize,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(vertical = 32.dp),
                )
                Image(
                    modifier = Modifier.fillMaxSize(0.6f),
                    painter = rememberQrCodePainter(qrCodeInfo.url),
                    contentDescription = strings.qrCodeDescription(qrCodeInfo.url)
                )

            }

        }) { measurables, constraints ->

        val aspectRatio = constraints.maxWidth.toFloat() / constraints.maxHeight

        val placeables =
            if (aspectRatio > 1f) { //expanded
                measurables.mapIndexed { index, measurable ->
                    measurable.measure(
                        constraints.copy(
                            maxWidth = constraints.maxWidth / 2,
                            minWidth = constraints.maxWidth / 2,
                            maxHeight = constraints.maxHeight * 9 / 10,
                            minHeight = constraints.maxHeight * 9 / 10,
                        )
                    )
                }
            } else {
                measurables.mapIndexed { index, measurable ->
                    measurable.measure(
                        constraints.copy(
                            maxHeight = constraints.maxHeight / 2,
                            minHeight = constraints.maxHeight / 2,
                            maxWidth = constraints.maxWidth * 9 / 10,
                            minWidth = constraints.maxWidth * 9 / 10,
                        )
                    )
                }
            }

        layout(constraints.maxWidth, constraints.maxHeight) {
            var xPosition = 0
            var yPosition = 0

            if (aspectRatio > 1f) {
                for (placeable in placeables) {
                    yPosition = (constraints.maxHeight - placeable.height) / 2
                    placeable.place(xPosition, yPosition)
                    xPosition += placeable.width
                }
            } else {
                for (placeable in placeables) {
                    xPosition = (constraints.maxWidth - placeable.width) / 2
                    placeable.place(xPosition, yPosition)
                    yPosition += placeable.height

                }
            }
        }
    }
}


/**
 * each LED number should have 7 sticks, and the height is 2 times of the width - strokeWidth
 */
@Composable
fun NumberDisplayLED(number: Int, model: LEDModel, isConnecting: Boolean = false, modifier: Modifier = Modifier) {

    val (defaultDigits: Int, strokeWidth: Dp) = model.defaultDigits to model.strokeWidth
    Box(
        modifier = modifier
            .background(Color.Black)
            .fillMaxWidth()
    ) {

        Layout(content = {
            val digits = number.toString()
            if (digits.length < defaultDigits) { //prepend empty LEDNumber
                repeat(defaultDigits - digits.length) {
                    LEDNumber(
                        modifier = Modifier
                            .fillMaxSize(), LEDNumber.None, strokeWidth
                    )
                }
            }
            for (singleValue in digits) {
                val ledNumber = singleValue.toString().toInt().toLEDNumber()
                LEDNumber(
                    modifier = Modifier
                        .fillMaxSize(), ledNumber, strokeWidth
                )
            }
        }) { measurables, constraints ->

            val padding = 0.04f * constraints.maxWidth
            val singleWidth =
                ((constraints.maxWidth - padding * (measurables.size + 1)) / measurables.size).let { width ->
                    //check if need to scale so that the height is not exceed the constraints
                    val scale: Float =
                        if (width * 2 - strokeWidth.roundToPx() > (constraints.maxHeight - 2 * padding)) (constraints.maxHeight - 2 * padding) / (width * 2 - strokeWidth.roundToPx()) else 1f
                    width * scale
                }

            val singleHeight = singleWidth * 2 - strokeWidth.roundToPx()

            val itemConstraints = constraints.copy(
                minWidth = singleWidth.toInt().coerceAtLeast(0),
                maxWidth = singleWidth.toInt().coerceAtLeast(0),
                minHeight = singleHeight.toInt().coerceAtLeast(0),
                maxHeight = singleHeight.toInt().coerceAtLeast(0),
            )
            val placeables = measurables.map { measurable ->
                // Measure each children
                measurable.measure(itemConstraints)
            }


            val vacancyWidth =
                constraints.maxWidth - (singleWidth * placeables.size + padding * (placeables.size + 1))

            layout(constraints.maxWidth, constraints.maxHeight) {

                var xPosition = padding + vacancyWidth / 2
                val yPosition = (constraints.maxHeight - singleHeight) / 2
                placeables.forEachIndexed { index, placeable ->
                    placeable.place(x = xPosition.toInt(), y = yPosition.toInt())
                    xPosition += singleWidth + padding
                }
            }
        }

        if (isConnecting) {

            val colorLight = Color.Red
            val colorDark = Color.Black

            val animatedColor by rememberInfiniteTransition().animateColor(
                initialValue = colorDark,
                targetValue = colorLight,
                animationSpec = infiniteRepeatable(
                    animation = tween(1500),
                    repeatMode = RepeatMode.Reverse
                )
            )

            Canvas(modifier = Modifier.fillMaxSize()) {
                drawCircle(
                    color = animatedColor,
                    radius = strokeWidth.toPx() / 2,
                    center = Offset(size.width - strokeWidth.toPx(), strokeWidth.toPx())
                )
            }
        }
    }
}

@Composable
fun LEDNumber(modifier: Modifier = Modifier, ledNumber: LEDNumber, widthUnit: Dp) {

    Canvas(
        modifier = modifier
    ) {

        val strokeWidth = widthUnit.toPx()

        ledNumber.stickColors.forEachIndexed { stickIndex, value ->
            when (stickIndex + 1) {
                1 -> {
                    drawStick(strokeWidth, value)
                }

                2 -> {
                    withTransform({
                        rotate(90f, Offset(strokeWidth / 2, strokeWidth / 2))
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }

                3 -> {
                    withTransform({
                        rotate(90f, Offset(strokeWidth / 2, strokeWidth / 2))
                        translate(0f, -(size.width - strokeWidth))
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }

                4 -> {
                    withTransform({
                        translate(0f, size.width - strokeWidth)
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }

                5 -> {
                    withTransform({
                        rotate(90f, Offset(strokeWidth / 2, strokeWidth / 2))
                        translate(size.width - strokeWidth, 0f)
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }

                6 -> {
                    withTransform({
                        rotate(90f, Offset(strokeWidth / 2, strokeWidth / 2))
                        translate(size.width - strokeWidth, -(size.width - strokeWidth))
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }

                7 -> {
                    withTransform({
                        translate(0f, (size.width - strokeWidth) * 2)
                    }) {
                        drawStick(strokeWidth, value)
                    }
                }
            }
        }
    }
}

/**
 * a stick contain two filled triangle at start and end of the stick
 */
fun DrawScope.drawStick(strokeWidth: Float, value: Int) {
    val filledPath = Path()
    filledPath.moveTo(strokeWidth / 2, strokeWidth / 2)
    filledPath.lineTo(strokeWidth, 0f)
    filledPath.lineTo(size.width - strokeWidth, 0f)
    filledPath.lineTo(size.width - strokeWidth / 2, strokeWidth / 2)
    filledPath.lineTo(size.width - strokeWidth, strokeWidth)
    filledPath.lineTo(strokeWidth, strokeWidth)

    filledPath.close()
    drawPath(
        filledPath,
        color = if (value == 1) Color.Red else Color.Black,
    )
}

/**
 * [stickColors] only contain 1 or 0, which 1 is filled and 0 is empty
 */
enum class LEDNumber(val stickColors: IntArray){
    Zero(intArrayOf(1,1, 1, 0, 1, 1, 1)),
    One(intArrayOf(0, 0, 1, 0, 0, 1, 0)),
    Two(intArrayOf(1, 0, 1, 1, 1, 0, 1)),
    Three(intArrayOf(1, 0, 1, 1, 0, 1, 1)),
    Four(intArrayOf(0, 1, 1, 1, 0, 1, 0)),
    Five(intArrayOf(1, 1, 0, 1, 0, 1, 1)),
    Six(intArrayOf(1, 1, 0, 1, 1, 1, 1)),
    Seven(intArrayOf(1, 0, 1, 0, 0, 1, 0)),
    Eight(intArrayOf(1, 1, 1, 1, 1, 1, 1)),
    Nine(intArrayOf(1, 1, 1, 1, 0, 1, 1)),
    None(intArrayOf(0, 0, 0, 0, 0, 0, 0))
}

/**
 * Convert single digit integer value to LEDNumber
 */
private fun Int.toLEDNumber(): LEDNumber {
    return when(this){
        0 -> LEDNumber.Zero
        1 -> LEDNumber.One
        2 -> LEDNumber.Two
        3 -> LEDNumber.Three
        4 -> LEDNumber.Four
        5 -> LEDNumber.Five
        6 -> LEDNumber.Six
        7 -> LEDNumber.Seven
        8 -> LEDNumber.Eight
        9 -> LEDNumber.Nine
        else -> LEDNumber.None
    }
}