package tim.huang.genlayout.ui

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import kotlinx.coroutines.flow.catch
import tim.huang.genlayout.model.LEDModel
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.viewmodel.NumberDisplayPadViewModel
import tim.huang.genlayout.viewmodel.ShopType

@Composable
fun NumberDisplayPad(viewModel: NumberDisplayPadViewModel, shopId: String) {

    val shop: NumberTicketShop by viewModel.getNumber(shopId).catch {
        //TODO: show error dialog and need redirect to vendor console or main page
//        showErrorDialog(it)
    }.collectAsState(initial = NumberTicketShop("", "", "", ShopType.SIMPLE_CALL_NUMBER, 0, 0))

    LaunchedEffect(shop.number){
        viewModel.callNumber(shop.number)
    }

    NumberDisplayScreen(
        title = shop.name,
        logo = shop.logo,
        number = shop.number,
        LEDModel.NumberDisplay(),
        qrCodeInfo = viewModel.getQrCodeInfo(shopId, shop),
        isConnecting = true
    )


}

@Composable
fun showErrorDialog(it: Throwable) {
    TODO("Not yet implemented")
}
