import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "zu", default = false)
val ZuStrings = Strings(
    projectNameDialogIntro1 = "Sawubona! Lapha sizokwakha insizakalo yamathikithi enombolo",
	projectNameDialogIntro2 = "Sicela ufake igama lesitolo sakho:",
	projectNameDialogShopName = "Igama lesitolo",
	submit = "Thumela",
	projectOptionsDialogIntro = { "Sawubona, ${it}, hlobo luni lwenkonzo oyifunayo:" },
	projectOptionsDialogShopType0Title = "Isevisi Yokubiza Inombolo Elula",
	projectOptionsDialogShopType0Description = "Umnikazi wesitolo / umlondolozi angashaya izinombolo ngokuthepha okulula kuhlelo lokusebenza, okwenza inqubo ibe ngokushesha futhi isebenze kahle.",
	projectOptionsDialogShopType1Title = "Isevisi Yokuthatha Inombolo Yamakhasimende",
	projectOptionsDialogShopType1Description = "Amakhasimende angathatha inombolo alinde inombolo yokushayela, aqinisekise ukuthi inqubo yokulinda iyashelela futhi iyashesha.",
	projectOptionsDialogShopType2Title = "Hlinzeka Ngesevisi Yezinombolo",
	projectOptionsDialogShopType2Description = "Umnikazi/umlondolozi wesitolo uhlinzeka ngenombolo, futhi alungise ulwazi lokushaya inombolo ukuze luhambisane nezidingo zebhizinisi lakho ngezilungiselelo ezenziwe ngokwezifiso.",
	vendorConsoleStartRunning = "Qala ukubaleka",
	numberDisplayScreenTitle = "Thatha inombolo lapha!",
	qrCodeDescription = { "Ikhodi ye-QR ebhekisela kuwebhusayithi ye-${it}" },
	takeNumberScreenInputPhoneNumber = "Faka inombolo yakho yocingo",
	takeNumberScreenPhoneNumber = "Inombolo yocingo",
	provideNumberPadGetNumber = "Thola inombolo",
	provideNumberPadCurrentNumber = "Inombolo yamanje",
	provideNumberPadYourNumber = { "Inombolo yakho ingu ${it}" },
	info = "Ulwazi",
	callNumberPadCurrentWaitingNumber = { "Inombolo yamanje yokulinda: ${it}" },
	displayNumberTitle = "Ikhasi lokubonisa izinombolo",
	customerTakeNumberTitle = "Ikhasi lenombolo yekhasimende",
	provideNumberTitle = "Nikeza ikhasi lenombolo",
	yourNumberTitle = "Ikhasi lakho lenombolo",
	callNumberTitle = "Ikhasi lenombolo yocingo",
	setting = "Ukusetha",
	callNumberPadSoundSwitch = "Umsindo Vuliwe/Valiwe",
	shutDown = "Vala",
	ok = "Kulungile",
	cancel = "Khansela",
	startRunningShop = "Qala ukusebenza kwesitolo",
	getToWork = "Masisebenzeni!",
	uploadSuccess = "Ukulayisha kuphumelele!",
	uploadFailed = "Ukulayisha kwehlulekile!",
	imagePickerDialogTitle = "Buyekeza uphawu lwesitolo sakho",
	imagePickerDialogAction = "Khetha isithombe",
	cropImageDialogTitle = "Qinisekisa ukubonisa ilogo yakho",
	editShopNameDialogTitle = "Buyekeza igama lesitolo sakho"
)