package tim.huang.genlayout.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import cafe.adriel.lyricist.strings
import tim.huang.genlayout.model.ServiceIntro
import tim.huang.genlayout.ui.theme.lightGray
import tim.huang.genlayout.viewmodel.ShopType
import tim.huang.genlayout.viewmodel.VendorConsoleViewModel

@Composable
fun ConsoleCreationScreen(viewModel: VendorConsoleViewModel, shopType: ShopType?, modifier: Modifier = Modifier) {

    var selectedShopType by remember { mutableStateOf(shopType) }
    var shopName by remember { mutableStateOf("") }

    when{
        shopName.isEmpty() -> ProjectNameDialog(
            onComplete = { shopName = it },
            modifier = modifier,
        )
        selectedShopType == null -> ProjectOptionsDialog(
            shopName,
            onComplete = { selectedShopType = it },
            modifier = modifier,
        )
        else -> {
            viewModel.createProject(shopName, selectedShopType!!)
        }
    }

}

//TODO ProjectNameDialog need to detect enter key press for submit
@OptIn(ExperimentalLayoutApi::class)
@Composable
fun ProjectNameDialog(onComplete: (String) -> Unit = {}, modifier: Modifier = Modifier){

    var textValue by remember { mutableStateOf("") }

    Surface (modifier = modifier.fillMaxSize().background(lightGray)) {
        Dialog(
            onDismissRequest = { },
        ){
            Surface(
                shape = RoundedCornerShape(16.dp),
            ) {
                Column (
                    modifier = Modifier.padding(32.dp)
                ){

                    Text(strings.projectNameDialogIntro1)
                    Text(strings.projectNameDialogIntro2)

                    FlowRow(
                        modifier = Modifier.padding(top = 8.dp).fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        OutlinedTextField(
                            value = textValue,
                            onValueChange = { textValue = it },
                            label = { Text(strings.projectNameDialogShopName) },
                            modifier = Modifier.weight(1f).defaultMinSize(minWidth = 200.dp),
                        )

                        TextButton(
                            onClick = { onComplete(textValue) },

                        ){
                            Text(
                                text = strings.submit,
                                modifier = Modifier.padding(horizontal = 32.dp, vertical = 8.dp),
                                style = MaterialTheme.typography.button,
                                fontWeight = FontWeight.Bold
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun ProjectOptionsDialog(shopName: String, onComplete: (ShopType) -> Unit, modifier: Modifier = Modifier){

    Surface (modifier = modifier.fillMaxSize().background(lightGray)) {
        Dialog(
            onDismissRequest = { },
        ){
            Surface(
                shape = RoundedCornerShape(16.dp),
            ) {
                Column (
                    modifier = Modifier.padding(32.dp)
                ){

                    Text(
                        strings.projectOptionsDialogIntro(shopName),
                        modifier = Modifier.padding(bottom = 16.dp),
                    )

                    ServiceIntro.getIntros().forEach { (title, description, shopType) ->
                        Row (modifier = Modifier.padding(8.dp)){
                            OutlinedButton(
                                onClick = { onComplete(shopType) },
                            ){
                                Column {
                                    Text(
                                        title,
                                        fontWeight = FontWeight.Bold,
                                    )
                                    Text(
                                        description,
                                        modifier = Modifier.padding(top = 8.dp),
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}