import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ro", default = false)
val RoStrings = Strings(
    projectNameDialogIntro1 = "Salut! Aici vom crea un serviciu de bilete numerotate",
	projectNameDialogIntro2 = "Vă rugăm să introduceți numele magazinului dvs.:",
	projectNameDialogShopName = "Numele magazinului",
	submit = "Trimite",
	projectOptionsDialogIntro = { "Salut, ${it}, ce fel de serviciu dorești:" },
	projectOptionsDialogShopType0Title = "Serviciu simplu de apelare a numerelor",
	projectOptionsDialogShopType0Description = "Proprietarul/administratorul magazinului poate apela numere cu o simplă atingere pe aplicație, făcând procesul rapid și eficient.",
	projectOptionsDialogShopType1Title = "Serviciu de Preluare Număr pentru Clienți",
	projectOptionsDialogShopType1Description = "Clienții pot lua un număr și aștepta apelarea numărului, asigurându-se că procesul de așteptare este lin și rapid.",
	projectOptionsDialogShopType2Title = "Oferiți servicii de numere",
	projectOptionsDialogShopType2Description = "Proprietarul/administratorul magazinului furnizează numărul și personalizează experiența de apelare a numărului pentru a se potrivi nevoilor dvs. de afaceri cu setări personalizabile.",
	vendorConsoleStartRunning = "Începe să alergi",
	numberDisplayScreenTitle = "Ia un număr aici!",
	qrCodeDescription = { "Cod QR care face referire la site-ul ${it}" },
	takeNumberScreenInputPhoneNumber = "Introduceți numărul de telefon",
	takeNumberScreenPhoneNumber = "Număr de telefon",
	provideNumberPadGetNumber = "Obțineți numărul",
	provideNumberPadCurrentNumber = "Numărul curent",
	provideNumberPadYourNumber = { "Numărul dvs. este ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Numărul de așteptare curent: ${it}" },
	displayNumberTitle = "Pagina de afișare a numerelor",
	customerTakeNumberTitle = "Pagina de număr a clientului",
	provideNumberTitle = "Furnizați pagina de număr",
	yourNumberTitle = "Pagina dvs. de număr",
	callNumberTitle = "Pagina de apelare a numărului",
	setting = "Setare",
	callNumberPadSoundSwitch = "Sunet Pornit/Oprit",
	shutDown = "Oprire",
	ok = "Ok",
	cancel = "Anulează",
	startRunningShop = "Începeți operarea magazinului",
	getToWork = "Să ne apucăm de treabă!",
	uploadSuccess = "Încărcare reușită!",
	uploadFailed = "Încărcarea a eșuat!",
	imagePickerDialogTitle = "Actualizați sigla magazinului dvs.",
	imagePickerDialogAction = "Alegeți o imagine",
	cropImageDialogTitle = "Confirmați afișarea siglei dvs.",
	editShopNameDialogTitle = "Actualizați numele magazinului dvs."
)