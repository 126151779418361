import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "kk", default = false)
val KkStrings = Strings(
    projectNameDialogIntro1 = "Сәлем! Мұнда біз нөмірлік билет қызметін жасаймыз",
	projectNameDialogIntro2 = "Дүкеніңіздің атын енгізіңіз:",
	projectNameDialogShopName = "Дүкен атауы",
	submit = "Жіберу",
	projectOptionsDialogIntro = { "Сәлем, ${it}, қандай қызмет түрін қалайсыз:" },
	projectOptionsDialogShopType0Title = "Қарапайым нөмір шақыру қызметі",
	projectOptionsDialogShopType0Description = "Дүкен иесі/қызметкері қолданбадағы қарапайым түрту арқылы нөмірлерді шақыра алады, бұл процесті жылдам және тиімді етеді.",
	projectOptionsDialogShopType1Title = "Клиентке нөмір беру қызметі",
	projectOptionsDialogShopType1Description = "Клиенттер нөмір алып, нөмірді шақыруды күте алады, күту процесінің біркелкі және жылдам болуын қамтамасыз етеді.",
	projectOptionsDialogShopType2Title = "Нөмір қызметін ұсыну",
	projectOptionsDialogShopType2Description = "Дүкен иесі/сақтаушысы нөмірді қамтамасыз етеді және реттелетін параметрлермен бизнес қажеттіліктеріңізге сәйкес нөмірді шақыру тәжірибесін бейімдейді.",
	vendorConsoleStartRunning = "Жүгіруді бастау",
	numberDisplayScreenTitle = "Мұнда нөмір алыңыз!",
	qrCodeDescription = { "${it} веб-сайтына сілтеме жасайтын QR коды" },
	takeNumberScreenInputPhoneNumber = "Телефон нөміріңізді енгізіңіз",
	takeNumberScreenPhoneNumber = "Телефон нөмірі",
	provideNumberPadGetNumber = "Нөмір алу",
	provideNumberPadCurrentNumber = "Ағымдағы нөмір",
	provideNumberPadYourNumber = { "Сіздің нөміріңіз ${it}" },
	info = "Ақпарат",
	callNumberPadCurrentWaitingNumber = { "Ағымдағы күту нөмірі: ${it}" },
	displayNumberTitle = "Нөмір дисплей беті",
	customerTakeNumberTitle = "Клиент нөмірінің беті",
	provideNumberTitle = "Сандық бет беру",
	yourNumberTitle = "Сіздің нөмір бетіңіз",
	callNumberTitle = "Қоңырау нөмірі беті",
	setting = "Баптау",
	callNumberPadSoundSwitch = "Дыбыс Қосулы/Өшірулі",
	shutDown = "Өшіру",
	ok = "Жақсы",
	cancel = "Болдырмау",
	startRunningShop = "Дүкенді іске қосу",
	getToWork = "Жұмысқа кірісейік!",
	uploadSuccess = "Жүктеу сәтті аяқталды!",
	uploadFailed = "Жүктеу сәтсіз аяқталды!",
	imagePickerDialogTitle = "Дүкеніңіздің логотипін жаңартыңыз",
	imagePickerDialogAction = "Суретті таңдаңыз",
	cropImageDialogTitle = "Логотипіңізді көрсету үшін растаңыз",
	editShopNameDialogTitle = "Дүкеніңіздің атын жаңартыңыз"
)