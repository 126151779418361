package tim.huang.genlayout.ui.widget

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import coil3.SingletonImageLoader
import coil3.compose.AsyncImage
import coil3.compose.LocalPlatformContext

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun TitleLogoRow(title: String, logo: String?) {

    val windowSizeClass = calculateWindowSizeClass()

    val textSize = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  MaterialTheme.typography.h6
        WindowWidthSizeClass.Medium ->  MaterialTheme.typography.h5
        else ->  MaterialTheme.typography.h4
    }

    Column {
        Spacer(modifier = Modifier.height(8.dp))
        Row(Modifier.height(IntrinsicSize.Max)) {
            Text(
                title,
                style = textSize,
                fontWeight = FontWeight.Bold,
                color = Color.Black,
                modifier = Modifier.padding(16.dp),
            )

            if (logo != null) {
                AsyncImage(
                    model = logo,
                    contentDescription = null,
                    imageLoader = SingletonImageLoader.get(LocalPlatformContext.current),
                    modifier = Modifier.fillMaxHeight().padding(8.dp),
                )
            }
        }
    }
}