import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "am", default = false)
val AmStrings = Strings(
    projectNameDialogIntro1 = "ሰላም! እዚህ ቁጥር ትኬት አገልግሎትን እንሰራለን",
	projectNameDialogIntro2 = "እባክዎን የዱባን ስም ያስገቡ:",
	projectNameDialogShopName = "የዱባን ስም",
	submit = "አስገባ",
	projectOptionsDialogIntro = { "ሰላም, ${it}, እንዴት ዓይነት አገልግሎት ትፈልጋለህ:" },
	projectOptionsDialogShopType0Title = "ቀላል የቁጥር ጥሪ አገልግሎት",
	projectOptionsDialogShopType0Description = "የዱባን ባለቤት/አስተዳዳሪ በመቀስቀስ ቁጥር መጠራት እንደሚችል ሲሆን፣ እንደዚህም ያለው ሂደት ፈጣንና ትክክለኛ ያደርገዋል።",
	projectOptionsDialogShopType1Title = "የደንበኛ ቁጥር አገልግሎት",
	projectOptionsDialogShopType1Description = "ደንበኞች ቁጥር መውሰድ እና ቁጥሩን ለመጠራት መጠበቅ ይችላሉ፣ የመጠበቂያ ሂደት ቀላልና ፈጣን መሆኑን ያረጋግጣል።",
	projectOptionsDialogShopType2Title = "የቁጥር አገልግሎት ማቅረብ",
	projectOptionsDialogShopType2Description = "የዱባን ባለቤት/አስተዳዳሪ ቁጥር ያቀርባል፣ እና በተለዋዋጭ ቅንብሮች ጋር የትኛውም የቢዝነስ ፍላጎቶችን ለመስማማት የቁጥር ጥሪ ተሞክሮ እንደሚስማማ ያረጋግጣል።",
	vendorConsoleStartRunning = "ጀምር ሩጫ",
	numberDisplayScreenTitle = "ቁጥር እዚህ ይውሰዱ!",
	qrCodeDescription = { "ወደ ${it} ድህረገፅ የሚጠቁም QR ኮድ" },
	takeNumberScreenInputPhoneNumber = "የስልክ ቁጥርዎን አስገባ",
	takeNumberScreenPhoneNumber = "ስልክ ቁጥር",
	provideNumberPadGetNumber = "ቁጥር አግኝ",
	provideNumberPadCurrentNumber = "ወቅታዊ ቁጥር",
	provideNumberPadYourNumber = { "ቁጥርዎ ነው ${it}" },
	info = "መረጃ",
	callNumberPadCurrentWaitingNumber = { "አሁን የምታጠብቀው ቁጥር: ${it}" },
	displayNumberTitle = "የቁጥር እንዲታይ ገጽ",
	customerTakeNumberTitle = "ደንበኛ ቁጥር ገጽ",
	provideNumberTitle = "ቁጥር ገጽ አቅርብ",
	yourNumberTitle = "የቁጥር ገጽ",
	callNumberTitle = "የጥሪ ቁጥር ገጽ",
	setting = "ቅንብር",
	callNumberPadSoundSwitch = "ድምፅ እያለ/እንደሌለ",
	shutDown = "ዝጋ",
	ok = "እሺ",
	cancel = "መሰረዝ",
	startRunningShop = "እንዲሁ ሱቅ መከፈት ጀምር",
	getToWork = "እንጀምር ሥራ!",
	uploadSuccess = "ማስገቢያ በተሳካ ሁኔታ ተፈጠረ!",
	uploadFailed = "ማስገቢያ አልተሳካም!",
	imagePickerDialogTitle = "የሱቅ አርማዎን አዘምኑ",
	imagePickerDialogAction = "ምስል ይምረጡ",
	cropImageDialogTitle = "ሎጎዎትን ለማሳየት አረጋግጡ",
	editShopNameDialogTitle = "የሱቅ ስም አዘምኑ"
)