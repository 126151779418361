import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "de", default = false)
val DeStrings = Strings(
    projectNameDialogIntro1 = "Hallo! Hier werden wir einen Nummernticketdienst erstellen",
	projectNameDialogIntro2 = "Bitte geben Sie Ihren Geschäftsnamen ein:",
	projectNameDialogShopName = "Geschäftsname",
	submit = "Einreichen",
	projectOptionsDialogIntro = { "Hallo, ${it}, welche Art von Service möchten Sie:" },
	projectOptionsDialogShopType0Title = "Einfacher Nummernaufrufdienst",
	projectOptionsDialogShopType0Description = "Der Ladenbesitzer/-verwalter kann Nummern mit einem einfachen Tipp auf die App aufrufen, wodurch der Prozess schnell und effizient wird.",
	projectOptionsDialogShopType1Title = "Kundennummerndienst",
	projectOptionsDialogShopType1Description = "Kunden können eine Nummer ziehen und auf die Aufrufnummer warten, um sicherzustellen, dass der Warteprozess reibungslos und schnell verläuft.",
	projectOptionsDialogShopType2Title = "Nummerndienst bereitstellen",
	projectOptionsDialogShopType2Description = "Der Ladenbesitzer/-verwalter stellt Nummern bereit und passt das Anruferlebnis mit anpassbaren Einstellungen an Ihre Geschäftsanforderungen an.",
	vendorConsoleStartRunning = "Laufen beginnen",
	numberDisplayScreenTitle = "Nehmen Sie hier eine Nummer!",
	qrCodeDescription = { "QR-Code, der auf die Website ${it} verweist" },
	takeNumberScreenInputPhoneNumber = "Geben Sie Ihre Telefonnummer ein",
	takeNumberScreenPhoneNumber = "Telefonnummer",
	provideNumberPadGetNumber = "Nummer erhalten",
	provideNumberPadCurrentNumber = "Aktuelle Nummer",
	provideNumberPadYourNumber = { "Ihre Nummer ist ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Aktuelle Wartenummer: ${it}" },
	displayNumberTitle = "Nummern Anzeigeseite",
	customerTakeNumberTitle = "Kundennummer Seite",
	provideNumberTitle = "Nummernseite bereitstellen",
	yourNumberTitle = "Ihre Nummernseite",
	callNumberTitle = "Anrufnummer Seite",
	setting = "Einstellung",
	callNumberPadSoundSwitch = "Ton Ein/Aus",
	shutDown = "Herunterfahren",
	ok = "Ok",
	cancel = "Abbrechen",
	startRunningShop = "Shop starten",
	getToWork = "Lass uns an die Arbeit gehen!",
	uploadSuccess = "Hochladen erfolgreich!",
	uploadFailed = "Upload fehlgeschlagen!",
	imagePickerDialogTitle = "Aktualisieren Sie Ihr Geschäftslogo",
	imagePickerDialogAction = "Wählen Sie ein Bild",
	cropImageDialogTitle = "Bestätigen Sie die Anzeige Ihres Logos",
	editShopNameDialogTitle = "Aktualisieren Sie den Namen Ihres Geschäfts"
)