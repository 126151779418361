import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "si", default = false)
val SiStrings = Strings(
    projectNameDialogIntro1 = "ආයුබෝවන්! මෙහි අපි අංක ප්‍රවේශ පත්‍ර සේවාවක් නිර්මාණය කිරීමට යන්නේ",
	projectNameDialogIntro2 = "කරුණාකර ඔබේ සාප්පු නාමය ඇතුළත් කරන්න:",
	projectNameDialogShopName = "සාප්පු නාමය",
	submit = "ඉදිරිපත් කරන්න",
	projectOptionsDialogIntro = { "ආයුබෝවන්, ${it}, ඔබට කොහොමගේ සේවාවක් අවශ්යද:" },
	projectOptionsDialogShopType0Title = "සරල අංක ඇමතුම් සේවාව",
	projectOptionsDialogShopType0Description = "සාප්පු හිමිකරු/රකින අය ඇප් එකේ සිම්පල් ටැප් එකකින් අංක අමතන්න පුළුවන්, එයින් ක්‍රියාවලිය ඉක්මන් සහ කාර්යක්ෂම වේ.",
	projectOptionsDialogShopType1Title = "පාරිභෝගික අංක සේවාව ගන්න",
	projectOptionsDialogShopType1Description = "පාරිභෝගිකයින්ට අංකයක් ලබා ගෙන අංකය පැහැදිලි කිරීමේදී බලා සිටීමට හැකි අතර, බලා සිටීමේ ක්‍රියාවලිය මෘදු සහ වේගවත් බව තහවුරු කරයි.",
	projectOptionsDialogShopType2Title = "අංක සේවාව සපයන්න",
	projectOptionsDialogShopType2Description = "සාප්පු හිමිකරු/රකින අය අංකයක් ලබා දෙයි, සහ පරිශීලන කළ හැකි සැකසීම් සමඟ ඔබේ ව්‍යාපාරික අවශ්‍යතා හට ගැලපෙන පරිදි අංක ඇමතුම් අත්දැකීම් හට අනුකූල වේ.",
	vendorConsoleStartRunning = "දුවන්න ආරම්භ කරන්න",
	numberDisplayScreenTitle = "මෙතනින් අංක ගන්න!",
	qrCodeDescription = { "${it} වෙබ්අඩවිය වෙත යොමු කරන QR කේතය" },
	takeNumberScreenInputPhoneNumber = "ඔබේ දුරකථන අංකය ඇතුළත් කරන්න",
	takeNumberScreenPhoneNumber = "දුරකථන අංකය",
	provideNumberPadGetNumber = "අංකයක් ලබා ගන්න",
	provideNumberPadCurrentNumber = "වත්මන් අංකය",
	provideNumberPadYourNumber = { "ඔබේ අංකය ${it}" },
	info = "තොරතුරු",
	callNumberPadCurrentWaitingNumber = { "වත්මන් අංකය: ${it}" },
	displayNumberTitle = "අංකය පෙන්වන්න පිටුව",
	customerTakeNumberTitle = "ගනුදෙනුකරුවා අංක පිටුව",
	provideNumberTitle = "අංක පිටුව ලබා දෙන්න",
	yourNumberTitle = "ඔබේ අංක පිටුව",
	callNumberTitle = "අංකය ඇමතුම් පිටුව",
	setting = "සැකසුම",
	callNumberPadSoundSwitch = "ශබ්දය ඕන්/ඔෆ්",
	shutDown = "අවසන් කරන්න",
	ok = "හරි",
	cancel = "අවලංගු කරන්න",
	startRunningShop = "වෙළඳසැල ක්‍රියාත්මක කිරීමට අරඹන්න",
	getToWork = "කටයුතු කිරීමට අපි යමු!",
	uploadSuccess = "උඩුගත කිරීම සාර්ථකයි!",
	uploadFailed = "උඩුගත කිරීම අසාර්ථකයි!",
	imagePickerDialogTitle = "ඔබේ සාප්පු ලාංඡනය යාවත්කාලීන කරන්න",
	imagePickerDialogAction = "රූපයක් තෝරන්න",
	cropImageDialogTitle = "ඔබේ ලාංඡනය පෙන්වීමට තහවුරු කරන්න",
	editShopNameDialogTitle = "ඔබේ සාප්පු නම යාවත්කාලීන කරන්න"
)