@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package genlayout.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object JsMainFont0 {
  public val unifont: FontResource by 
      lazy { init_unifont() }
}

public val Res.font.unifont: FontResource
  get() = JsMainFont0.unifont

private fun init_unifont(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:unifont",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/font/unifont.ttf", -1, -1),
    )
)
