import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "he", default = false)
val HeStrings = Strings(
    projectNameDialogIntro1 = "שלום! כאן אנו עומדים ליצור שירות כרטיסים ממוספרים",
	projectNameDialogIntro2 = "אנא הזן את שם החנות שלך:",
	projectNameDialogShopName = "שם החנות",
	submit = "שלח",
	projectOptionsDialogIntro = { "שלום, ${it}, איזה סוג שירות אתה רוצה:" },
	projectOptionsDialogShopType0Title = "שירות קריאת מספר פשוט",
	projectOptionsDialogShopType0Description = "בעל החנות/המנהל יכול להתקשר למספרים בלחיצה פשוטה על האפליקציה, מה שהופך את התהליך למהיר ויעיל.",
	projectOptionsDialogShopType1Title = "שירות לקיחת מספר ללקוח",
	projectOptionsDialogShopType1Description = "לקוחות יכולים לקחת מספר ולהמתין לקריאה, ולוודא שתהליך ההמתנה יהיה חלק ומהיר.",
	projectOptionsDialogShopType2Title = "לספק שירות מספר",
	projectOptionsDialogShopType2Description = "בעל החנות/המנהל מספק מספר ומעצב את חוויית הקריאה כדי להתאים לצרכי העסק שלך עם הגדרות הניתנות להתאמה אישית.",
	vendorConsoleStartRunning = "התחל לרוץ",
	numberDisplayScreenTitle = "קח מספר כאן!",
	qrCodeDescription = { "קוד QR המפנה לאתר ${it}" },
	takeNumberScreenInputPhoneNumber = "הזן את מספר הטלפון שלך",
	takeNumberScreenPhoneNumber = "מספר טלפון",
	provideNumberPadGetNumber = "קבל מספר",
	provideNumberPadCurrentNumber = "מספר נוכחי",
	provideNumberPadYourNumber = { "המספר שלך הוא ${it}" },
	info = "מידע",
	callNumberPadCurrentWaitingNumber = { "מספר המתנה נוכחי: ${it}" },
	displayNumberTitle = "עמוד תצוגת מספרים",
	customerTakeNumberTitle = "עמוד מספר לקוח",
	provideNumberTitle = "ספק דף מספרים",
	yourNumberTitle = "עמוד המספר שלך",
	callNumberTitle = "עמוד מספר שיחה",
	setting = "הגדרה",
	callNumberPadSoundSwitch = "צליל פועל/כבוי",
	shutDown = "כבה",
	ok = "אישור",
	cancel = "ביטול",
	startRunningShop = "התחל להפעיל את החנות",
	getToWork = "בואו נתחיל לעבוד!",
	uploadSuccess = "ההעלאה הצליחה!",
	uploadFailed = "ההעלאה נכשלה!",
	imagePickerDialogTitle = "עדכן את לוגו החנות שלך",
	imagePickerDialogAction = "בחר תמונה",
	cropImageDialogTitle = "אשר את הצגת הלוגו שלך",
	editShopNameDialogTitle = "עדכן את שם החנות שלך"
)