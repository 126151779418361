@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package genlayout.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val base_url: StringResource by 
      lazy { init_base_url() }

  public val worker_api_key: StringResource by 
      lazy { init_worker_api_key() }
}

public val Res.string.base_url: StringResource
  get() = CommonMainString0.base_url

private fun init_base_url(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:base_url", "base_url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    56),
    )
)

public val Res.string.worker_api_key: StringResource
  get() = CommonMainString0.worker_api_key

private fun init_worker_api_key(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:worker_api_key", "worker_api_key",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/genlayout.composeapp.generated.resources/values/strings.commonMain.cvr", 67,
    46),
    )
)
