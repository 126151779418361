package tim.huang.genlayout.data.operation

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.viewmodel.ShopType

class SimulationOperation: DatabaseOperation {
    override suspend fun atomicFetchWaitingNumber(shopId: String): Int {
        return 1
    }

    override fun receiveShopInfo(shopId: String): Flow<NumberTicketShop> {
        return flow {
            emit(NumberTicketShop("", "", "", ShopType.SIMPLE_CALL_NUMBER, 0, 0))
        }
    }
}