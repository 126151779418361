package tim.huang.genlayout.viewmodel

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import cafe.adriel.lyricist.strings
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import tim.huang.genlayout.data.operation.DatabaseOperation
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.utils.Base64Hash

class ProvideNumberViewModel(
    private val coroutineScope: CoroutineScope,
    private val operation: DatabaseOperation,
    private val shopId: String,
) {

    private val event = MutableStateFlow<ProvideNumberEvent?>(null)

    val uiState = coroutineScope.launchMolecule(mode = RecompositionMode.Immediate){
        ProvideNumberUiStatePresenter()
    }

    @Composable
    fun ProvideNumberUiStatePresenter(): ProvideNumberUiState {

        val getNumberButtonText = strings.provideNumberPadGetNumber
        var buttonText by remember { mutableStateOf(getNumberButtonText) }
        var providedNumber: Int? by remember { mutableStateOf(null) }
        val shopInfo: NumberTicketShop? by operation.receiveShopInfo(shopId).collectAsState(null)

        val provideNumberEvent by event.collectAsState()

        fun resetUiState(){
            buttonText = getNumberButtonText
            providedNumber = null
            event.value = null
        }

        LaunchedEffect(provideNumberEvent){
            if (provideNumberEvent is ProvideNumberEvent.ProvideNumber){
                providedNumber = operation.atomicFetchWaitingNumber(shopId)
                var waitSecs = 8
                while (waitSecs > 0){
                    buttonText = "$getNumberButtonText ($waitSecs)"
                    delay(1000)
                    waitSecs--
                }
                resetUiState()
            }
        }
        return if (shopInfo == null){
            ProvideNumberUiState.Loading
        }else { //we are sure shopInfo won't be null again
            ProvideNumberUiState.Data(
                title = shopInfo!!.name,
                logo = shopInfo!!.logo,
                buttonText = buttonText,
                currentNumber = shopInfo!!.number,
                providedNumber = providedNumber,
                providedNumberInHash = providedNumber?.let { Base64Hash.hashNumber(it, shopId) }
            )
        }
    }

    fun provideNumber() {
        event.value = ProvideNumberEvent.ProvideNumber
    }
}

sealed class ProvideNumberUiState {
    data object Loading : ProvideNumberUiState()
    data class Data(
        val title: String,
        val logo: String?,
        val buttonText: String,
        val currentNumber: Int,
        val providedNumber: Int?,
        val providedNumberInHash: String?
    ) : ProvideNumberUiState()
}

sealed interface ProvideNumberEvent {
    data object ProvideNumber : ProvideNumberEvent
}

