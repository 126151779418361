import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ru", default = false)
val RuStrings = Strings(
    projectNameDialogIntro1 = "Привет! Здесь мы собираемся создать услугу номерных билетов",
	projectNameDialogIntro2 = "Пожалуйста, введите название вашего магазина:",
	projectNameDialogShopName = "Название магазина",
	submit = "Отправить",
	projectOptionsDialogIntro = { "Привет, ${it}, какой тип услуги вам нужен:" },
	projectOptionsDialogShopType0Title = "Простая служба вызова номеров",
	projectOptionsDialogShopType0Description = "Владелец/смотритель магазина может вызвать номера простым нажатием на приложение, делая процесс быстрым и эффективным.",
	projectOptionsDialogShopType1Title = "Сервис получения номеров для клиентов",
	projectOptionsDialogShopType1Description = "Клиенты могут взять номер и ждать вызова номера, обеспечивая, чтобы процесс ожидания был гладким и быстрым.",
	projectOptionsDialogShopType2Title = "Предоставить номерной сервис",
	projectOptionsDialogShopType2Description = "Владелец/смотритель магазина предоставляет номер и настраивает опыт вызова номеров в соответствии с потребностями вашего бизнеса с помощью настраиваемых параметров.",
	vendorConsoleStartRunning = "Начать бег",
	numberDisplayScreenTitle = "Возьмите номер здесь!",
	qrCodeDescription = { "QR-код, ссылающийся на сайт ${it}" },
	takeNumberScreenInputPhoneNumber = "Введите ваш номер телефона",
	takeNumberScreenPhoneNumber = "Номер телефона",
	provideNumberPadGetNumber = "Получить номер",
	provideNumberPadCurrentNumber = "Текущий номер",
	provideNumberPadYourNumber = { "Ваш номер ${it}" },
	info = "Информация",
	callNumberPadCurrentWaitingNumber = { "Текущий номер ожидания: ${it}" },
	displayNumberTitle = "Страница отображения номеров",
	customerTakeNumberTitle = "Страница получения номера клиента",
	provideNumberTitle = "Предоставить страницу номера",
	yourNumberTitle = "Ваша страница номеров",
	callNumberTitle = "Страница вызова номера",
	setting = "Настройка",
	callNumberPadSoundSwitch = "Звук Вкл/Выкл",
	shutDown = "Выключить",
	ok = "Ок",
	cancel = "Отмена",
	startRunningShop = "Начать работу магазина",
	getToWork = "Давайте начнем работать!",
	uploadSuccess = "Загрузка успешна!",
	uploadFailed = "Загрузка не удалась!",
	imagePickerDialogTitle = "Обновите логотип вашего магазина",
	imagePickerDialogAction = "Выберите изображение",
	cropImageDialogTitle = "Подтвердите отображение вашего логотипа",
	editShopNameDialogTitle = "Обновите название вашего магазина"
)