package tim.huang.genlayout.resources        

data class Strings(
    val projectNameDialogIntro1: String,
	val projectNameDialogIntro2: String,
	val projectNameDialogShopName: String,
	val submit: String,
	val projectOptionsDialogIntro: (String) -> String,
	val projectOptionsDialogShopType0Title: String,
	val projectOptionsDialogShopType0Description: String,
	val projectOptionsDialogShopType1Title: String,
	val projectOptionsDialogShopType1Description: String,
	val projectOptionsDialogShopType2Title: String,
	val projectOptionsDialogShopType2Description: String,
	val vendorConsoleStartRunning: String,
	val numberDisplayScreenTitle: String,
	val qrCodeDescription: (String) -> String,
	val takeNumberScreenInputPhoneNumber: String,
	val takeNumberScreenPhoneNumber: String,
	val provideNumberPadGetNumber: String,
	val provideNumberPadCurrentNumber: String,
	val provideNumberPadYourNumber: (String) -> String,
	val info: String,
	val callNumberPadCurrentWaitingNumber: (String) -> String,
	val displayNumberTitle: String,
	val customerTakeNumberTitle: String,
	val provideNumberTitle: String,
	val yourNumberTitle: String,
	val callNumberTitle: String,
	val setting: String,
	val callNumberPadSoundSwitch: String,
	val shutDown: String,
	val ok: String,
	val cancel: String,
	val startRunningShop: String,
	val getToWork: String,
	val uploadSuccess: String,
	val uploadFailed: String,
	val imagePickerDialogTitle: String,
	val imagePickerDialogAction: String,
	val cropImageDialogTitle: String,
	val editShopNameDialogTitle: String
)