package tim.huang.genlayout.ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import cafe.adriel.lyricist.strings
import io.github.alexzhirkevich.qrose.rememberQrCodePainter
import org.koin.compose.koinInject
import org.koin.core.parameter.parametersOf
import tim.huang.genlayout.ui.theme.lightGray
import tim.huang.genlayout.ui.widget.TitleLogoRow
import tim.huang.genlayout.viewmodel.ProvideNumberUiState
import tim.huang.genlayout.viewmodel.ProvideNumberViewModel

@Composable
fun ProvideNumberSimulationScreen(title: String, logo: String?, provideNumberViewModel: ProvideNumberViewModel = koinInject{ parametersOf(false, "") }, modifier: Modifier) {
    val uiState by provideNumberViewModel.uiState.collectAsState()

    when(val state = uiState){
        is ProvideNumberUiState.Data -> {
            ProvideNumberPad(title, logo, state, { provideNumberViewModel.provideNumber() }, modifier)
        }
        else -> {}
    }
}

@Composable
fun ProvideNumberPad(title: String, logo: String?, state: ProvideNumberUiState.Data, provideNumber: () -> Unit, modifier: Modifier = Modifier,) {

    Column(modifier.background(lightGray)) {

        TitleLogoRow(title, logo)

        Column(
            modifier = modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {

            Text(
                strings.provideNumberPadCurrentNumber,
                style = MaterialTheme.typography.h4,
                fontWeight = FontWeight.Bold,
                color = Color.Black,
                modifier = Modifier.padding(16.dp),
            )
            Text(
                state.currentNumber.toString(),
                style = MaterialTheme.typography.h3,
                fontWeight = FontWeight.Bold,
                color = MaterialTheme.colors.primary,
                modifier = Modifier.padding(16.dp),
            )

            OutlinedButton(onClick = provideNumber){
                Text(
                    state.buttonText,
                    style = MaterialTheme.typography.h6,
                    fontWeight = FontWeight.Bold,)
            }

            if (state.providedNumberInHash != null){
                val yourNumberLink = "https://keeppro.io?dst=${state.providedNumberInHash}"
                val uriHandler = LocalUriHandler.current

                Text(
                    strings.provideNumberPadYourNumber(state.providedNumber.toString()),
                    style = MaterialTheme.typography.h6,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(vertical = 16.dp),)

                Image(
                    modifier = Modifier.fillMaxSize(0.6f),
                    painter = rememberQrCodePainter(yourNumberLink),
                    contentDescription = strings.qrCodeDescription(yourNumberLink)
                )

                Text(
                    text = yourNumberLink,
                    color = Color.Blue,
                    modifier = Modifier
                        .padding(vertical = 16.dp)
                        .clickable {
                            uriHandler.openUri(yourNumberLink)
                        },
                    fontWeight = FontWeight.Bold,
                    style = TextStyle(textDecoration = TextDecoration.Underline) + MaterialTheme.typography.body1
                )
            }
        }

    }
}