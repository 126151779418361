package tim.huang.genlayout.ui

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Switch
import androidx.compose.material.Tab
import androidx.compose.material.TabRow
import androidx.compose.material.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import cafe.adriel.lyricist.strings
import genlayout.composeapp.generated.resources.Res
import genlayout.composeapp.generated.resources.power_settings
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import tim.huang.genlayout.BuildKonfig
import tim.huang.genlayout.ui.widget.TitleLogoRow
import tim.huang.genlayout.utils.baseUrl
import tim.huang.genlayout.viewmodel.ShopType
import tim.huang.genlayout.viewmodel.VendorConsoleViewModel
import tim.huang.genlayout.viewmodel.VendorState


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallNumberPad(viewModel: VendorConsoleViewModel, shopInfo: VendorState.RunningShop, modifier: Modifier = Modifier) {

    val windowSizeClass = calculateWindowSizeClass()

    val soundOn by viewModel.getNumberCallingSoundOn().collectAsState()
    val title = shopInfo.shop.name
    val logo = shopInfo.shop.logo

    Column(modifier = modifier.padding(16.dp)) {
        TitleLogoRow(title, logo)

        if(windowSizeClass.widthSizeClass == WindowWidthSizeClass.Compact){
            PortraitCallNumberPad(viewModel, shopInfo, soundOn, modifier)
        }else{
            LandScapeCallNumberPad(viewModel, shopInfo, soundOn, modifier)
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PortraitCallNumberPad(
    viewModel: VendorConsoleViewModel,
    shop: VendorState.RunningShop,
    soundOn: Boolean,
    modifier: Modifier
) {
    var selectedIndex by remember { mutableStateOf(0) }
    val pagerState = rememberPagerState { 2 }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(pagerState){
        snapshotFlow { pagerState.currentPage }.collect { page ->
            selectedIndex = page
        }
    }

    Column(
        modifier = modifier
    ) {
        CallNumberScreen(viewModel.getNumberPad(), modifier = Modifier.fillMaxWidth().aspectRatio(1f))

        Spacer(modifier = Modifier.height(16.dp))

        TabRow(
            selectedTabIndex = selectedIndex
        ){
            Tab(
                selected = selectedIndex == 0,
                onClick = {
                    coroutineScope.launch {
                        pagerState.animateScrollToPage(0)
                    }
                }
            ){
                Text(
                    text = strings.info,
                )
            }

            Tab(
                selected = selectedIndex == 1,
                onClick = {
                    coroutineScope.launch {
                        pagerState.animateScrollToPage(1)
                    }
                }
            ){
                Text(
                    text = strings.setting,
                )
            }
        }

        HorizontalPager(
            state = pagerState
        ){ page ->
            when(page){
                0 -> InfoSession(null, viewModel, shop, modifier = Modifier.fillMaxSize().padding(8.dp))
                1 -> SettingSession(null, viewModel, shop, soundOn, modifier = Modifier.fillMaxSize().padding(8.dp))
            }

        }

    }
}

@Composable
fun LandScapeCallNumberPad(
    viewModel: VendorConsoleViewModel,
    shop: VendorState.RunningShop,
    soundOn: Boolean,
    modifier: Modifier
) {
    Row (
        horizontalArrangement = Arrangement.SpaceAround,
        modifier = modifier
    ) {
        CallNumberScreen(viewModel.getNumberPad(), modifier = Modifier.weight(1f))
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.Start,
            modifier = Modifier.fillMaxHeight().weight(1f).padding(start = 8.dp),
        ) {

            InfoSession(strings.info, viewModel, shop)

            Spacer(modifier = Modifier.height(32.dp))
            Divider(modifier = Modifier.height(1.dp).padding(horizontal = 8.dp))
            Spacer(modifier = Modifier.height(32.dp))

            SettingSession(strings.setting, viewModel, shop, soundOn)
        }

    }
}

@Composable
fun SettingSession(title: String?, viewModel: VendorConsoleViewModel, shop: VendorState.RunningShop, soundOn: Boolean, modifier: Modifier = Modifier) {

    val scrollState = rememberScrollState()

    Column(modifier.verticalScroll(scrollState)) {
        title?.let {
            Text(
                text = it,
                fontWeight = FontWeight.Bold,
                style = TextStyle(textDecoration = TextDecoration.Underline) + MaterialTheme.typography.h4,
            )
        }


        Row (
            modifier = Modifier.padding(vertical = 8.dp),
            verticalAlignment = Alignment.CenterVertically,
        ){
            Text(
                style = MaterialTheme.typography.body1,
                text = strings.callNumberPadSoundSwitch,
            )

            Switch(
                modifier = Modifier.padding(horizontal = 8.dp),
                checked = soundOn,
                onCheckedChange = {
                    viewModel.updateNumberCalling(it)
                },
            )
        }

        OutlinedButton(
            shape = RoundedCornerShape(50),
            border = BorderStroke(1.dp, Color.Red),
            onClick = { viewModel.shutdownShop(shop.shopId) },
            modifier = Modifier.padding(4.dp)
        ){
            Image(
                modifier = Modifier.size(32.dp),
                painter = painterResource(Res.drawable.power_settings),
                contentDescription = strings.shutDown,
                colorFilter = ColorFilter.tint(Color.Red),
            )
            Text(
                text = strings.shutDown,
                color = Color.Red,
                fontWeight = FontWeight.Bold,
                fontStyle = FontStyle.Italic,
                modifier = Modifier.padding(8.dp),
                style = TextStyle(textDecoration = TextDecoration.Underline) + MaterialTheme.typography.body1,
            )
        }
    }
}

@Composable
fun InfoSession(title: String?, viewModel: VendorConsoleViewModel, shopInfo: VendorState.RunningShop, modifier: Modifier = Modifier) {

    val scrollState = rememberScrollState()
    val baseUrl = BuildKonfig.baseUrl

    Column(modifier = modifier.verticalScroll(scrollState)) {
        val displayNumberLink = "$baseUrl?shop=${shopInfo.shopId}"
        val takeNumberLink = "$baseUrl?tnp=${shopInfo.shopId}"
        val provideNumberLink = "$baseUrl?isVendor=true&pnp=${shopInfo.shopId}"
        val shopType = ShopType.fromValue(shopInfo.shop.shopType)

        title?.let {
            Text(
                text = it,
                fontWeight = FontWeight.Bold,
                style = TextStyle(textDecoration = TextDecoration.Underline) + MaterialTheme.typography.h4,
                modifier = Modifier.padding(bottom = 16.dp)
            )
        }


        if (shopType != ShopType.SIMPLE_CALL_NUMBER){
            val waitingNumber by viewModel.getWaitingNumber(shopInfo.shopId).collectAsState(0)
            //display current waiting number

            Text(
                text = strings.callNumberPadCurrentWaitingNumber(waitingNumber.toString()),
                fontWeight = FontWeight.Bold,
                style = MaterialTheme.typography.h6,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }
        Spacer(modifier = Modifier.height(16.dp))
        LinkInfo(hint = strings.displayNumberTitle, url = displayNumberLink)
        Spacer(modifier = Modifier.height(16.dp))

        if (shopType == ShopType.USER_TAKE_NUMBER){
            LinkInfo(hint = strings.customerTakeNumberTitle, url = takeNumberLink)
        }
        Spacer(modifier = Modifier.height(16.dp))
        if (shopType == ShopType.VENDOR_PROVIDE_NUMBER){
            LinkInfo(hint = strings.provideNumberTitle, url = provideNumberLink)
        }
    }
}

@Composable
fun LinkInfo(hint: String, url: String){
    val uriHandler = LocalUriHandler.current

    Column {
        Text(
            text = hint,
            fontWeight = FontWeight.Bold,
            style = MaterialTheme.typography.h6,
        )
        Text(
            text = url,
            color = Color.Blue,
            modifier = Modifier
                .padding(vertical = 4.dp)
                .clickable {
                    uriHandler.openUri(url)
                },
            fontWeight = FontWeight.Bold,
            style = TextStyle(textDecoration = TextDecoration.Underline) + MaterialTheme.typography.body1
        )
    }
}