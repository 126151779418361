import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.Typography
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.intl.Locale
import androidx.compose.ui.window.ComposeViewport
import cafe.adriel.lyricist.ProvideStrings
import cafe.adriel.lyricist.rememberStrings
import com.mohamedrejeb.calf.ui.progress.AdaptiveCircularProgressIndicator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import genlayout.composeapp.generated.resources.Res
import genlayout.composeapp.generated.resources.unifont
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.withTimeoutOrNull
import org.jetbrains.compose.resources.Font
import org.koin.compose.koinInject
import org.koin.core.context.startKoin
import org.koin.core.parameter.parametersOf
import org.w3c.dom.url.URLSearchParams
import tim.huang.genlayout.di.platformModule
import tim.huang.genlayout.di.sharedModule
import tim.huang.genlayout.model.Shop
import tim.huang.genlayout.model.UserManager
import tim.huang.genlayout.ui.CallNumberPad
import tim.huang.genlayout.ui.ConsoleCreationScreen
import tim.huang.genlayout.ui.NumberDisplayPad
import tim.huang.genlayout.ui.ProvideNumberPad
import tim.huang.genlayout.ui.TakeNumberScreen
import tim.huang.genlayout.ui.VendorConsole
import tim.huang.genlayout.ui.YourNumberScreen
import tim.huang.genlayout.ui.theme.KeepProTheme
import tim.huang.genlayout.utils.Base64Hash
import tim.huang.genlayout.utils.logg
import tim.huang.genlayout.viewmodel.NumberDisplayPadViewModel
import tim.huang.genlayout.viewmodel.NumberDisplayUiState
import tim.huang.genlayout.viewmodel.ProvideNumberUiState
import tim.huang.genlayout.viewmodel.ProvideNumberViewModel
import tim.huang.genlayout.viewmodel.ShopType
import tim.huang.genlayout.viewmodel.TakeNumberUiState
import tim.huang.genlayout.viewmodel.TakeNumberViewModel
import tim.huang.genlayout.viewmodel.VendorConsoleViewModel
import tim.huang.genlayout.viewmodel.VendorState


suspend fun main() = coroutineScope {
    try {
        startKoin {
            modules(sharedModule + platformModule)
        }
        Routes.configNavigationRule()
        val params = URLSearchParams(window.location.search)
        val isVendor = params.get("isVendor").let { it == "true" }
        val shopId = params.get("shop")
        val controlPadId = params.get("controlPad")
        val shopType = runCatching { params.get("shopType")?.let { ShopType.fromValue(it.toInt()) } }.getOrNull()
        val provideNumberPadId = params.get("pnp")
        val hash = params.get("dst")
        val takeNumberPadId = params.get("tnp")
        when{
            isVendor && !controlPadId.isNullOrEmpty() -> {
                //go to call number screen
                checkBasicAuth {
                    goToCallNumberPad(koinInject { parametersOf(Shop(controlPadId)) })
                }
            }
            isVendor && !provideNumberPadId.isNullOrEmpty() -> {
                checkBasicAuth {
                    goToProvideNumberPad( koinInject<ProvideNumberViewModel>{ parametersOf(true, provideNumberPadId) } )
                }
            }
            isVendor -> {
                checkBasicAuth {
                    goToVendorConsole(
                        koinInject { parametersOf(null) },
                        shopType
                    )
                }
            }
            !takeNumberPadId.isNullOrEmpty() -> {
                checkBasicAuth {
                    goToTakeNumberPad( koinInject{ parametersOf(true, takeNumberPadId) })
                }
            }
            !hash.isNullOrEmpty() -> {
                val (number, decodeShopId) = Base64Hash.decodeHashNumber(hash)
                checkBasicAuth {
                    goToYourNumberPad(koinInject{ parametersOf(true, decodeShopId)}, number)
                }
            }
            !shopId.isNullOrEmpty() -> {
                //go to number display screen

                delay(1000) //TODO shitty workaround to let skiko wasm ready
                goToNumberDisplayPad(shopId)
            }
            else -> {} //go to main page it's done in html
        }
    }catch (e: Exception){
        logg("something wrong: $e")
    }


}

@Composable
fun goToProvideNumberPad(viewModel: ProvideNumberViewModel) {
    val uiState by viewModel.uiState.collectAsState()

    when(val state = uiState){
        is ProvideNumberUiState.Data -> {
            ProvideNumberPad(state.title, state.logo, state, { viewModel.provideNumber() })
        }
        ProvideNumberUiState.Loading -> {
            Box(Modifier.fillMaxSize()) {
                AdaptiveCircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
            }
        }
    }
}

@Composable
fun goToTakeNumberPad(viewModel: TakeNumberViewModel) {

    val uiState by viewModel.uiState().collectAsState()

    when(val state = uiState){
        is TakeNumberUiState.NavigateToYourNumber -> {
            Routes.redirectToYourNumberPad(state.numberHash)
        }
        is TakeNumberUiState.Data -> {
            with(state.shopInfo){
                TakeNumberScreen(name, logo, viewModel::takeNumberByPhone)
            }
        }
        TakeNumberUiState.Loading -> {
            Box(Modifier.fillMaxSize()) {
                AdaptiveCircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
            }
        }
    }
}

@Composable
fun goToYourNumberPad(viewModel: NumberDisplayPadViewModel, yourNumber: Int) {
    val uiState by viewModel.uiState.collectAsState()

    when(val state = uiState){
        is NumberDisplayUiState.Display -> {
            with(state.shopInfo){
                YourNumberScreen(title = name, logo = logo, curNumber = number, yourNumber)
            }
        }
        NumberDisplayUiState.Loading -> {
            Box(Modifier.fillMaxSize()) {
                AdaptiveCircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
            }
        }
    }
}

fun goToNumberDisplayPad(shopId: String) {
    navigateToCompose {
        NumberDisplayPad(koinInject{ parametersOf(true, shopId)}, shopId)
    }
}

@Composable
fun goToCallNumberPad(viewModel: VendorConsoleViewModel) {
    val vendorState by viewModel.vendorStateFlow().collectAsState()

    when(val state = vendorState){
        is VendorState.RunningShop -> {
            CallNumberPad(viewModel, state)
        }
        is VendorState.VendorConsole -> {
            Routes.redirectToVendorConsole()
        }
        VendorState.InitConsole -> {
            Routes.redirectToVendorConsole()
        }
        else -> {}
    }
}


@Composable
fun goToVendorConsole(
    viewModel: VendorConsoleViewModel,
    shopType: ShopType?) {
    //check if user have running shop, if yes, go to call number screen
    //if no, go to vendor console
    val vendorState by viewModel.vendorStateFlow().collectAsState()
    val uploadStatus by viewModel.uploadStatus.collectAsState()

    when(val state = vendorState){
        is VendorState.RunningShop -> {
            Routes.redirectToCallNumberPad(state.shopId)
        }
        is VendorState.VendorConsole -> {
            VendorConsole(
                numberPad = viewModel.getNumberPad(),
                project = state.project,
                uploadStatus = uploadStatus,
                startRunningShop = { viewModel.startRunningShop(state.project) },
                updateProjectName = { viewModel.updateProjectName(it) },
                updateProjectLogo = { viewModel.updateProjectLogo(it) },
            )
        }
        VendorState.InitConsole -> {
            ConsoleCreationScreen(viewModel, shopType)
        }
        else -> {}
    }
}

/**
 * Beware cache firebase user might not be updated in time
 * Take 1 seconds here to wait for the cache to be updated
 * Be sure the loading screen is shown
 */
suspend fun checkLoginPopup(activateUI: @Composable () -> Unit){

//    val user = withTimeoutOrNull(1000) { UserConfig.getUserFlow(noAnonymous = true).filterNotNull().first() }
//    if (user == null){
//        (document.getElementById("googleButton") as? HTMLDivElement)?.let {
//            it.style.display = "flex"
//        }
//    }else{
//        navigateToCompose(activateUI)
//    }
}

suspend fun checkBasicAuth(activateUI: @Composable () -> Unit){
    val manager = UserManager(Firebase.auth(firebaseApp))
    val user = withTimeoutOrNull(1000) { manager.getUserFlow().filterNotNull().first() }
    if (user == null){
        manager.loginAnonymously()
    }
    navigateToCompose(activateUI)
}

/**
 * entry point of every web app, doing configs for:
 * 1. html
 * 2. locale
 */
@OptIn(ExperimentalComposeUiApi::class)
fun navigateToCompose(activateUI: @Composable () -> Unit) {
    logg("navigateToCompose called")
    document.getElementById("main")?.remove()
    //this is important to make sure compose container is full screen
    document.body?.style?.apply {
        height = "100%"
        width = "100%"
    }
    ComposeViewport("composeContainer") {
        val lyricist = rememberStrings(
            currentLanguageTag = getCurrentLanguageTag()
        )

        ProvideStrings(lyricist) {
            KeepProTheme(typography = loadCjkFont()) {
                activateUI()
            }
        }
    }
}

//TODO feature: let user select language from settings
@Composable
internal fun getCurrentLanguageTag(): String {
    val value = Locale.current.language
    return value.split("-").first()
}

/**
 * Load cjk font for web, this is a workaround for now, after compose support cjk font(1.7), this should be removed
 */
@Composable
private fun loadCjkFont(): Typography {
    val font = FontFamily(
        Font(Res.font.unifont),
    )
    return Typography(defaultFontFamily = font)
}


