package tim.huang.genlayout.viewmodel

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import tim.huang.genlayout.data.operation.FirebaseOperation
import tim.huang.genlayout.model.NumberTicketUiModel
import tim.huang.genlayout.model.NumberTicketVendorConsoleConfigs
import tim.huang.genlayout.model.Shop
import tim.huang.genlayout.model.StrokeWidthControlData
import tim.huang.genlayout.model.UserManager
import tim.huang.genlayout.model.Voice
import tim.huang.genlayout.model.VoiceControlData
import tim.huang.genlayout.sound.NumberCallingController
import tim.huang.genlayout.utils.getSortedVoices
import web.events.EventHandler
import web.speech.speechSynthesis

open class VendorConsoleViewModelJs(coroutineScope: CoroutineScope,
                                    shop: Shop?,
                                    audioController: NumberCallingController,
                                    operation: FirebaseOperation,
                                    manager: UserManager,
    ) : VendorConsoleViewModel(coroutineScope, shop, audioController, operation, manager) {


    override val _uiModel: MutableStateFlow<NumberTicketUiModel> = MutableStateFlow(
        with(NumberTicketVendorConsoleConfigs()) {
            NumberTicketUiModel(
                this,
                VoiceControlData(
                    voice,
                    speechSynthesis.getSortedVoices(voice).map { Voice(it.name, it.lang) },
                    update = ::updateVoice
                ),
                StrokeWidthControlData(
                    strokeWidth,
                    strokeUpdateValue,
                    ::incStrokeWidth,
                    ::decStrokeWidth,
                )
            )
        }
    )


    init {

        speechSynthesis.onvoiceschanged = EventHandler {
            _uiModel.value = _uiModel.value.run {
                copy(
                    voiceControlData = voiceControlData.copy(
                        options = speechSynthesis.getSortedVoices(voiceControlData.selectedVoice).map {
                            Voice(it.name, it.lang)
                        }
                    )
                )
            }
        }
    }

    private fun updateVoice(voice: Voice){
        _uiModel.value = _uiModel.value.run {
            copy(
                config = config.copy(voice = voice),
                voiceControlData = voiceControlData.copy(selectedVoice = voice)
            )
        }
    }

    private fun incStrokeWidth(){
        _uiModel.value = _uiModel.value.run {
            val value = config.strokeWidth + config.strokeUpdateValue
            copy(
                config = config.copy(strokeWidth = value),
                strokeWidthControlData = strokeWidthControlData.copy(strokeWidth = value)
            )
        }
    }

    private fun decStrokeWidth(){
        _uiModel.value = _uiModel.value.run {
            val value = config.strokeWidth - config.strokeUpdateValue
            copy(
                config = config.copy(strokeWidth = value),
                strokeWidthControlData = strokeWidthControlData.copy(strokeWidth = value)
            )
        }
    }

}