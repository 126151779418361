import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "hy", default = false)
val HyStrings = Strings(
    projectNameDialogIntro1 = "Բարեւ! Այստեղ մենք ստեղծելու ենք համարի տոմսերի ծառայություն",
	projectNameDialogIntro2 = "Խնդրում ենք մուտքագրել ձեր խանութի անունը:",
	projectNameDialogShopName = "Խանութի անվանումը",
	submit = "Ներկայացնել",
	projectOptionsDialogIntro = { "Բարեւ, ${it}, ինչպիսի ծառայություն եք ցանկանում:" },
	projectOptionsDialogShopType0Title = "Պարզ համարի զանգի ծառայություն",
	projectOptionsDialogShopType0Description = "Խանութի սեփականատերը/պահապանը կարող է զանգահարել համարներով՝ հավելվածում մի պարզ հպումով, ինչը գործընթացը դարձնում է արագ և արդյունավետ:",
	projectOptionsDialogShopType1Title = "Հաճախորդների համարների ստացման ծառայություն",
	projectOptionsDialogShopType1Description = "Հաճախորդները կարող են վերցնել համարը և սպասել համարի կանչին, ապահովելով, որ սպասման գործընթացը հարթ և արագ լինի:",
	projectOptionsDialogShopType2Title = "Համարների ծառայություն տրամադրեք",
	projectOptionsDialogShopType2Description = "Խանութի սեփականատերը/պահապանը տրամադրում է համարը և հարմարեցնում համարի կանչման փորձը ձեր բիզնեսի կարիքներին՝ հարմարեցվող կարգավորումներով:",
	vendorConsoleStartRunning = "Սկսեք վազել",
	numberDisplayScreenTitle = "Վերցրեք համարը այստեղ!",
	qrCodeDescription = { "${it} կայքէջին անդրադառնալու QR կոդ" },
	takeNumberScreenInputPhoneNumber = "Մուտքագրեք ձեր հեռախոսահամարը",
	takeNumberScreenPhoneNumber = "Հեռախոսահամար",
	provideNumberPadGetNumber = "Ստացեք համարը",
	provideNumberPadCurrentNumber = "Ընթացիկ համար",
	provideNumberPadYourNumber = { "Ձեր համարը ${it} է" },
	info = "Տեղեկություն",
	callNumberPadCurrentWaitingNumber = { "Ընթացիկ հերթում սպասման համար: ${it}" },
	displayNumberTitle = "Համարների ցուցադրման էջ",
	customerTakeNumberTitle = "Հաճախորդի համարի էջ",
	provideNumberTitle = "Թվային էջ տրամադրել",
	yourNumberTitle = "Ձեր համարի էջ",
	callNumberTitle = "Զանգահարեք համարի էջ",
	setting = "Կարգավորում",
	callNumberPadSoundSwitch = "Ձայնը Միացրած/Անջատած",
	shutDown = "Անջատել",
	ok = "Լավ",
	cancel = "Չեղարկել",
	startRunningShop = "Սկսել խանութի աշխատանքը",
	getToWork = "Եկեք սկսենք աշխատել!",
	uploadSuccess = "Վերբեռնումը հաջողվեց!",
	uploadFailed = "Վերբեռնումը ձախողվեց!",
	imagePickerDialogTitle = "Թարմացրեք ձեր խանութի լոգոն",
	imagePickerDialogAction = "Ընտրեք պատկեր",
	cropImageDialogTitle = "Հաստատեք ձեր լոգոյի ցուցադրումը",
	editShopNameDialogTitle = "Թարմացրեք ձեր խանութի անունը"
)