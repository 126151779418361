package tim.huang.genlayout.utils

import tim.huang.genlayout.BuildKonfig

enum class Platform {
    ANDROID,
    IOS,
    WEB,
    DESKTOP
}

internal fun BuildKonfig.getPlatForm(): Platform{
    return when(PLATFORM){
        "android" -> return Platform.ANDROID
        "ios" -> return Platform.IOS
        "js", "wasmJs" -> return Platform.WEB
        "desktop" -> return Platform.DESKTOP
        else -> throw IllegalArgumentException("Can't platform info from BuildKonfig.")
    }
}

internal fun BuildKonfig.isMobilePlatform(): Boolean{
    return when(PLATFORM){
        "android", "ios" -> true
        else -> false
    }
}

//add BuildKonfig properties baseUrl
internal val BuildKonfig.baseUrl: String
        get() = if (DEBUG) "http://localhost:8080" else "https://keeppro.io"
