import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "hu", default = false)
val HuStrings = Strings(
    projectNameDialogIntro1 = "Helló! Itt létrehozunk egy számjegyű szolgáltatást",
	projectNameDialogIntro2 = "Kérjük, adja meg üzletének nevét:",
	projectNameDialogShopName = "Bolt neve",
	submit = "Beküldés",
	projectOptionsDialogIntro = { "Szia, ${it}, milyen szolgáltatást szeretnél:" },
	projectOptionsDialogShopType0Title = "Egyszerű számahívási szolgáltatás",
	projectOptionsDialogShopType0Description = "Az üzlet tulajdonosa/vezetője egyszerűen koppint az alkalmazásra a számok hívásához, így a folyamat gyors és hatékony lesz.",
	projectOptionsDialogShopType1Title = "Ügyfélszám Felvételi Szolgáltatás",
	projectOptionsDialogShopType1Description = "Az ügyfelek számot vehetnek, és várhatják a számuk hívását, biztosítva, hogy a várakozási folyamat zökkenőmentes és gyors legyen.",
	projectOptionsDialogShopType2Title = "Szám szolgáltatás biztosítása",
	projectOptionsDialogShopType2Description = "Az üzlet tulajdonosa/kezelője számot biztosít, és testre szabható beállításokkal igazítja a számos hívási élményt az üzleti igényekhez.",
	vendorConsoleStartRunning = "Kezdj el futni",
	numberDisplayScreenTitle = "Vegyél itt számot!",
	qrCodeDescription = { "QR-kód, amely a ${it} webhelyre utal" },
	takeNumberScreenInputPhoneNumber = "Adja meg a telefonszámát",
	takeNumberScreenPhoneNumber = "Telefonszám",
	provideNumberPadGetNumber = "Szám megszerzése",
	provideNumberPadCurrentNumber = "Aktuális szám",
	provideNumberPadYourNumber = { "A számod ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Jelenlegi várakozási szám: ${it}" },
	displayNumberTitle = "Számkijelző oldal",
	customerTakeNumberTitle = "Ügyfélszám oldal",
	provideNumberTitle = "Biztosítson számoldalt",
	yourNumberTitle = "A te számod oldal",
	callNumberTitle = "Hívószám oldal",
	setting = "Beállítás",
	callNumberPadSoundSwitch = "Hang Be/Ki",
	shutDown = "Leállítás",
	ok = "Ok",
	cancel = "Mégse",
	startRunningShop = "Indítsa el az üzletet",
	getToWork = "Lássunk munkához!",
	uploadSuccess = "Sikeres feltöltés!",
	uploadFailed = "A feltöltés nem sikerült!",
	imagePickerDialogTitle = "Frissítse az üzlete logóját",
	imagePickerDialogAction = "Válasszon képet",
	cropImageDialogTitle = "Erősítse meg a logó megjelenítését",
	editShopNameDialogTitle = "Frissítse az üzlete nevét"
)