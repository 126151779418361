package tim.huang.genlayout.model

import dev.gitlive.firebase.auth.FirebaseAuth
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.withTimeoutOrNull

class UserManager(
    private val authModule: FirebaseAuth
) {

    /**
     * cache firebase user might not be updated in time
     * don't use this when app is starting
     */
    val isUserLoggedIn: Boolean
        get(){
            return authModule.currentUser != null
        }

    val userFlow = authModule.authStateChanged

    fun getUserId(): String? {
        return authModule.currentUser?.uid
    }

    suspend fun loginAnonymously() {
        authModule.signInAnonymously()
    }

    fun getUserFlow(noAnonymous: Boolean = false) = authModule.authStateChanged.map {
        if (noAnonymous && it?.isAnonymous == true){
            authModule.signOut()
            null
        }
        else it
    }

    /**
     * If mobile app just launched, we need at least anonymous user to start
     */
    suspend fun didLaunchApp() {
        withTimeoutOrNull(1000L){
            getUserFlow().filterNotNull().first()
        }.let { firebaseUser ->
            if (firebaseUser == null) {
                loginAnonymously()
            }
        }
    }

}