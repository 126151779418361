package tim.huang.genlayout.model

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

sealed class LEDModel{
    abstract val defaultDigits: Int
    abstract val strokeWidth: Dp

    class NumberDisplay(override val defaultDigits: Int = 3, override val strokeWidth: Dp = 30.dp): LEDModel(){
    }

    object CallNumber: LEDModel(){
        override val defaultDigits: Int = 4
        override val strokeWidth: Dp = 10.dp
    }

    class Custom(override val defaultDigits: Int, override val strokeWidth: Dp): LEDModel()
}



