import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "bn", default = false)
val BnStrings = Strings(
    projectNameDialogIntro1 = "হ্যালো! এখানে আমরা একটি নম্বর টিকিট পরিষেবা তৈরি করতে যাচ্ছি",
	projectNameDialogIntro2 = "অনুগ্রহ করে আপনার দোকানের নাম লিখুন:",
	projectNameDialogShopName = "দোকানের নাম",
	submit = "জমা দিন",
	projectOptionsDialogIntro = { "হাই, ${it}, আপনি কোন ধরণের পরিষেবা চান:" },
	projectOptionsDialogShopType0Title = "সহজ নম্বর কলিং পরিষেবা",
	projectOptionsDialogShopType0Description = "দোকানের মালিক/রক্ষক অ্যাপে একটি সহজ ট্যাপ দিয়ে নম্বরগুলি কল করতে পারেন, যা প্রক্রিয়াটিকে দ্রুত এবং দক্ষ করে তোলে।",
	projectOptionsDialogShopType1Title = "গ্রাহক নম্বর নেওয়ার সেবা",
	projectOptionsDialogShopType1Description = "গ্রাহকরা নম্বর নিতে পারেন এবং নম্বর কল করার জন্য অপেক্ষা করতে পারেন, অপেক্ষার প্রক্রিয়া মসৃণ এবং দ্রুত নিশ্চিত করুন।",
	projectOptionsDialogShopType2Title = "সংখ্যা পরিষেবা প্রদান করুন",
	projectOptionsDialogShopType2Description = "দোকানের মালিক/রক্ষক নম্বর প্রদান করে, এবং কাস্টমাইজযোগ্য সেটিংস সহ আপনার ব্যবসার প্রয়োজন অনুযায়ী নম্বর কলিং অভিজ্ঞতাকে মানিয়ে নেয়।",
	vendorConsoleStartRunning = "দৌড় শুরু করুন",
	numberDisplayScreenTitle = "এখানে নম্বর নিন!",
	qrCodeDescription = { "${it} ওয়েবসাইট উল্লেখ করে কিউআর কোড" },
	takeNumberScreenInputPhoneNumber = "আপনার ফোন নম্বর লিখুন",
	takeNumberScreenPhoneNumber = "ফোন নম্বর",
	provideNumberPadGetNumber = "নম্বর পান",
	provideNumberPadCurrentNumber = "বর্তমান নম্বর",
	provideNumberPadYourNumber = { "আপনার নম্বর ${it}" },
	info = "তথ্য",
	callNumberPadCurrentWaitingNumber = { "বর্তমান অপেক্ষমাণ সংখ্যা: ${it}" },
	displayNumberTitle = "নম্বর প্রদর্শন পৃষ্ঠা",
	customerTakeNumberTitle = "গ্রাহক নম্বর পৃষ্ঠা",
	provideNumberTitle = "নম্বর পৃষ্ঠা প্রদান করুন",
	yourNumberTitle = "আপনার নম্বর পৃষ্ঠা",
	callNumberTitle = "কল নম্বর পৃষ্ঠা",
	setting = "সেটিং",
	callNumberPadSoundSwitch = "শব্দ চালু/বন্ধ",
	shutDown = "বন্ধ করুন",
	ok = "ঠিক আছে",
	cancel = "বাতিল",
	startRunningShop = "দোকান চালানো শুরু করুন",
	getToWork = "চল কাজ শুরু করি!",
	uploadSuccess = "আপলোড সফল হয়েছে!",
	uploadFailed = "আপলোড ব্যর্থ হয়েছে!",
	imagePickerDialogTitle = "আপনার দোকানের লোগো আপডেট করুন",
	imagePickerDialogAction = "একটি ছবি নির্বাচন করুন",
	cropImageDialogTitle = "আপনার লোগো প্রদর্শন করতে নিশ্চিত করুন",
	editShopNameDialogTitle = "আপনার দোকানের নাম আপডেট করুন"
)