import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "th", default = false)
val ThStrings = Strings(
    projectNameDialogIntro1 = "สวัสดี! ที่นี่เราจะสร้างบริการตั๋วหมายเลข",
	projectNameDialogIntro2 = "กรุณาใส่ชื่อร้านของคุณ:",
	projectNameDialogShopName = "ชื่อร้าน",
	submit = "ส่ง",
	projectOptionsDialogIntro = { "สวัสดี, ${it}, คุณต้องการบริการประเภทใด:" },
	projectOptionsDialogShopType0Title = "บริการเรียกหมายเลขง่ายๆ",
	projectOptionsDialogShopType0Description = "เจ้าของ/ผู้ดูแลร้านสามารถเรียกหมายเลขได้ด้วยการแตะที่แอป ทำให้กระบวนการรวดเร็วและมีประสิทธิภาพ",
	projectOptionsDialogShopType1Title = "บริการรับหมายเลขลูกค้า",
	projectOptionsDialogShopType1Description = "ลูกค้าสามารถรับหมายเลขและรอเรียกหมายเลข เพื่อให้แน่ใจว่ากระบวนการราบรื่นและรวดเร็ว",
	projectOptionsDialogShopType2Title = "ให้บริการหมายเลข",
	projectOptionsDialogShopType2Description = "เจ้าของ/ผู้ดูแลร้านจัดหาหมายเลข และปรับประสบการณ์การโทรตามหมายเลขให้เหมาะกับความต้องการทางธุรกิจของคุณด้วยการตั้งค่าที่ปรับแต่งได้",
	vendorConsoleStartRunning = "เริ่มวิ่ง",
	numberDisplayScreenTitle = "รับหมายเลขที่นี่!",
	qrCodeDescription = { "รหัส QR อ้างอิงเว็บไซต์ ${it}" },
	takeNumberScreenInputPhoneNumber = "ป้อนหมายเลขโทรศัพท์ของคุณ",
	takeNumberScreenPhoneNumber = "หมายเลขโทรศัพท์",
	provideNumberPadGetNumber = "รับหมายเลข",
	provideNumberPadCurrentNumber = "หมายเลขปัจจุบัน",
	provideNumberPadYourNumber = { "หมายเลขของคุณคือ ${it}" },
	info = "ข้อมูล",
	callNumberPadCurrentWaitingNumber = { "หมายเลขที่กำลังรออยู่: ${it}" },
	displayNumberTitle = "หน้าจอแสดงหมายเลข",
	customerTakeNumberTitle = "หน้าหมายเลขลูกค้า",
	provideNumberTitle = "ให้หน้าหมายเลข",
	yourNumberTitle = "หน้าหมายเลขของคุณ",
	callNumberTitle = "หน้าหมายเลขโทร",
	setting = "การตั้งค่า",
	callNumberPadSoundSwitch = "เสียง เปิด/ปิด",
	shutDown = "ปิด",
	ok = "ตกลง",
	cancel = "ยกเลิก",
	startRunningShop = "เริ่มต้นการดำเนินการร้านค้า",
	getToWork = "มาเริ่มทำงานกันเถอะ!",
	uploadSuccess = "อัปโหลดสำเร็จ!",
	uploadFailed = "การอัปโหลดล้มเหลว!",
	imagePickerDialogTitle = "อัปเดตโลโก้ร้านค้าของคุณ",
	imagePickerDialogAction = "เลือกภาพ",
	cropImageDialogTitle = "ยืนยันที่จะแสดงโลโก้ของคุณ",
	editShopNameDialogTitle = "อัปเดตชื่อร้านค้าของคุณ"
)