import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "id", default = false)
val IdStrings = Strings(
    projectNameDialogIntro1 = "Halo! Di sini kami akan membuat layanan tiket nomor",
	projectNameDialogIntro2 = "Silakan masukkan nama toko Anda:",
	projectNameDialogShopName = "Nama Toko",
	submit = "Kirim",
	projectOptionsDialogIntro = { "Hai, ${it}, layanan seperti apa yang Anda inginkan:" },
	projectOptionsDialogShopType0Title = "Layanan Panggilan Nomor Sederhana",
	projectOptionsDialogShopType0Description = "Pemilik/tukang jaga toko dapat memanggil nomor dengan satu sentuhan sederhana pada aplikasi, membuat prosesnya cepat dan efisien.",
	projectOptionsDialogShopType1Title = "Layanan Ambil Nomor Pelanggan",
	projectOptionsDialogShopType1Description = "Pelanggan dapat mengambil nomor dan menunggu panggilan nomor, memastikan proses menunggu berjalan lancar dan cepat.",
	projectOptionsDialogShopType2Title = "Menyediakan Layanan Nomor",
	projectOptionsDialogShopType2Description = "Pemilik/tukang jaga toko menyediakan nomor, dan menyesuaikan pengalaman panggilan nomor untuk memenuhi kebutuhan bisnis Anda dengan pengaturan yang dapat disesuaikan.",
	vendorConsoleStartRunning = "Mulai berlari",
	numberDisplayScreenTitle = "Ambil nomor di sini!",
	qrCodeDescription = { "Kode QR yang mengarah ke situs web ${it}" },
	takeNumberScreenInputPhoneNumber = "Masukkan nomor telepon Anda",
	takeNumberScreenPhoneNumber = "Nomor telepon",
	provideNumberPadGetNumber = "Dapatkan nomor",
	provideNumberPadCurrentNumber = "Nomor saat ini",
	provideNumberPadYourNumber = { "Nomor Anda adalah ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Nomor tunggu saat ini: ${it}" },
	displayNumberTitle = "Halaman tampilan nomor",
	customerTakeNumberTitle = "Halaman nomor pelanggan",
	provideNumberTitle = "Sediakan halaman nomor",
	yourNumberTitle = "Halaman nomor Anda",
	callNumberTitle = "Halaman nomor panggilan",
	setting = "Pengaturan",
	callNumberPadSoundSwitch = "Suara Nyala/Mati",
	shutDown = "Matikan",
	ok = "Oke",
	cancel = "Batal",
	startRunningShop = "Mulai Menjalankan Toko",
	getToWork = "Ayo mulai bekerja!",
	uploadSuccess = "Unggah berhasil!",
	uploadFailed = "Unggah gagal!",
	imagePickerDialogTitle = "Perbarui logo toko Anda",
	imagePickerDialogAction = "Pilih gambar",
	cropImageDialogTitle = "Konfirmasi untuk menampilkan logo Anda",
	editShopNameDialogTitle = "Perbarui nama toko Anda"
)