package tim.huang.genlayout.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.consumeWindowInsets
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredSize
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Scaffold
import androidx.compose.material.ScrollableTabRow
import androidx.compose.material.Tab
import androidx.compose.material.TabRowDefaults
import androidx.compose.material.TabRowDefaults.tabIndicatorOffset
import androidx.compose.material.Text
import androidx.compose.material.contentColorFor
import androidx.compose.material.rememberScaffoldState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import cafe.adriel.lyricist.strings
import coil3.ImageLoader
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.setSingletonImageLoaderFactory
import coil3.request.crossfade
import com.mohamedrejeb.calf.permissions.ExperimentalPermissionsApi
import com.mohamedrejeb.calf.ui.dialog.AdaptiveAlertDialog
import com.mohamedrejeb.calf.ui.progress.AdaptiveCircularProgressIndicator
import tim.huang.genlayout.model.LEDModel
import tim.huang.genlayout.model.NumberPad
import tim.huang.genlayout.model.Page
import tim.huang.genlayout.model.Project
import tim.huang.genlayout.ui.widget.ConsoleHeader
import tim.huang.genlayout.ui.widget.EditLogoDialog
import tim.huang.genlayout.ui.widget.EditShopNameDialog
import tim.huang.genlayout.viewmodel.UploadStatus


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalPermissionsApi::class,
    ExperimentalCoilApi::class
)
@Composable
fun VendorConsole(
    numberPad: NumberPad,
    project: Project,
    uploadStatus: UploadStatus,
    startRunningShop: () -> Unit = {},
    updateProjectName: (String) -> Unit = {},
    updateProjectLogo: (ByteArray) -> Unit = {},
    modifier: Modifier = Modifier,
) {

    setSingletonImageLoaderFactory { context ->
        ImageLoader
            .Builder(context)
            .crossfade(true)
            .build()
    }

    var selectedPage by remember { mutableStateOf(project.pages.first()) }
    var selectedIndex by remember { mutableStateOf(0) }
    val windowSizeClass = calculateWindowSizeClass()

    val progressBarSize = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  100.dp
        WindowWidthSizeClass.Medium ->  150.dp
        else ->  200.dp
    }
    val tabTextSize = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  MaterialTheme.typography.body1
        WindowWidthSizeClass.Medium ->  MaterialTheme.typography.h6
        else ->  MaterialTheme.typography.h5
    }

    var editNamePopup by remember { mutableStateOf(false) }
    var editLogoPopup by remember { mutableStateOf(false) }
    var confirmStartRunningDialog by remember { mutableStateOf(false) }
    val scaffoldState = rememberScaffoldState()

    Scaffold(
        modifier.defaultMinSize(minWidth = 200.dp, minHeight = 100.dp),
        scaffoldState = scaffoldState,
        topBar = {
//            ConsoleHeader(project, { confirmStartRunningDialog = true }, { editNamePopup = true }, { editLogoPopup = true })
        },
    ){ innerPadding ->
        Box(modifier.fillMaxSize()){
            Column (
                modifier = modifier.background(MaterialTheme.colors.background).fillMaxSize().consumeWindowInsets(innerPadding),
            ) {

                ScrollableTabRow(
                    selectedTabIndex = selectedIndex,
                    backgroundColor = MaterialTheme.colors.background,
                    indicator = { tabPositions ->
                        TabRowDefaults.Indicator(
                            Modifier.tabIndicatorOffset(tabPositions[selectedIndex]),
                            color = MaterialTheme.colors.secondary
                        )
                    },
                ) {
                    project.pages.forEachIndexed { index, page ->

                        Tab(
                            selected = page == selectedPage,
                            onClick = {
                                selectedIndex = index
                                selectedPage = page
                            },
                            modifier = Modifier.padding(8.dp),
                            selectedContentColor = MaterialTheme.colors.secondary,
                            unselectedContentColor = contentColorFor(MaterialTheme.colors.background),
                        ) {
                            Text(
                                page.title,
                                style = tabTextSize,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis,
                            )
                        }
                    }
                    // Add more tabs as needed
                }

                Row {
                    // future feature: user control panel
//            Column(modifier = Modifier.weight(0.15f)) {
//                when (selectedTab) {
//                    0 -> {
//                        StrokeWidthControl(uiModel.strokeWidthControlData)
//                    }
//                    1 -> {
//                        VoiceControl(uiModel.voiceControlData)
//                    }
//                }
//            }


                    Box(
                        modifier = Modifier
                            .fillMaxSize()
                    ) {

                        when (selectedPage) {
                            Page.NUMBER_DISPLAY -> NumberDisplayScreen(
                                title = project.shopName,
                                logo = project.shopLogo,
                                numberPad.curNumber.value,
                                LEDModel.NumberDisplay(strokeWidth = 30.dp), //TODO uiModel.config.strokeWidth
                                qrCodeInfo = project.getQrCodeInfo(),
                                modifier = Modifier.padding(8.dp)
                            )
                            // Add more cases as needed
                            Page.CALL_NUMBER -> CallNumberScreen(numberPad, modifier = Modifier.padding(8.dp))
                            Page.TAKE_NUMBER ->  TakeNumberSimulationScreen(project = project, modifier = Modifier.padding(8.dp))
                            Page.PROVIDE_NUMBER -> ProvideNumberSimulationScreen(project.shopName, project.shopLogo,  modifier = Modifier.padding(8.dp))
                            else -> {
                                // Do nothing
                            }
                        }
                    }
                }
            }


            if (editNamePopup){
                EditShopNameDialog(
                    shopName = project.shopName,
                    onDismiss = { editNamePopup = false },
                    onConfirm = { newShopName ->
                        updateProjectName(newShopName)
                        editNamePopup = false
                    }
                )
            }

            if (editLogoPopup){
                EditLogoDialog(
                    onDismiss = { editLogoPopup = false },
                    onConfirm = { newLogo ->
                        updateProjectLogo(newLogo)
                        editLogoPopup = false
                    }
                )
            }

            if (uploadStatus is UploadStatus.Uploading){
                AdaptiveCircularProgressIndicator(
                    modifier.requiredSize(progressBarSize).align(Alignment.Center),
                )
            }

            if (confirmStartRunningDialog) {
                AdaptiveAlertDialog(
                    onConfirm = {
                        startRunningShop()
                    },
                    onDismiss = {
                        confirmStartRunningDialog = false
                    },
                    confirmText = strings.ok,
                    dismissText = strings.cancel,
                    title = strings.startRunningShop,
                    text = strings.getToWork,
                )
            }
        }
    }

    val successText = strings.uploadSuccess
    val failedText = strings.uploadFailed

    LaunchedEffect(uploadStatus){
        when (uploadStatus){
            UploadStatus.Success -> {
                scaffoldState.snackbarHostState.showSnackbar(successText)
            }
            is UploadStatus.Failed -> {
                scaffoldState.snackbarHostState.showSnackbar(failedText)
            }
            else -> {
                // Do nothing
            }
        }
    }

}

