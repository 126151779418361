package tim.huang.genlayout.ui.widget

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import cafe.adriel.lyricist.strings
import com.mohamedrejeb.calf.core.LocalPlatformContext
import com.mohamedrejeb.calf.io.readByteArray
import com.mohamedrejeb.calf.picker.FilePickerFileType
import com.mohamedrejeb.calf.picker.FilePickerSelectionMode
import com.mohamedrejeb.calf.picker.rememberFilePickerLauncher
import kotlinx.coroutines.launch

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ImagePickerDialog(onDismiss: () -> Unit, onSelected: (ByteArray) -> Unit) {
    val scope = rememberCoroutineScope()
    val context = LocalPlatformContext.current

    val pickerLauncher = rememberFilePickerLauncher(
        type = FilePickerFileType.Image,
        selectionMode = FilePickerSelectionMode.Single,
        onResult = { files ->
            scope.launch {
                files.firstOrNull()?.let { file ->
                    //after choosing file transfer to crop image dialog
                    onSelected(file.readByteArray(context))
                }
            }
        }
    )

    val windowSizeClass = calculateWindowSizeClass()
    val buttonPadding = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  16.dp
        else ->  32.dp
    }

    Dialog(onDismissRequest = onDismiss) {
        Surface(
            shape = RoundedCornerShape(16.dp),
        ) {
            //hint choose a file
            Column(
                modifier = Modifier.padding(buttonPadding),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    strings.imagePickerDialogTitle,
                    style = MaterialTheme.typography.h5,
                )

                Button(
                    onClick = {
                        pickerLauncher.launch()
                    },
                    modifier = Modifier.padding(16.dp)
                ) {
                    Text(strings.imagePickerDialogAction)
                }
            }
        }
    }
}