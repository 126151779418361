package cafe.adriel.lyricist

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.text.intl.Locale
import cafe.adriel.lyricist.Lyricist
import cafe.adriel.lyricist.LanguageTag
import cafe.adriel.lyricist.rememberStrings
import cafe.adriel.lyricist.ProvideStrings
import AmStrings
import ArStrings
import AzStrings
import BnStrings
import BsStrings
import CsStrings
import DaStrings
import DeStrings
import ElStrings
import EnStrings
import EsStrings
import EtStrings
import FaStrings
import FiStrings
import FrCAStrings
import FrStrings
import GuStrings
import HaStrings
import HeStrings
import HiStrings
import HrStrings
import HuStrings
import HyStrings
import IdStrings
import ItStrings
import JaStrings
import KaStrings
import KkStrings
import KmStrings
import KnStrings
import KoStrings
import LoStrings
import LtStrings
import LvStrings
import MkStrings
import MlStrings
import MnStrings
import MrStrings
import MsStrings
import MyStrings
import NeStrings
import NlBEStrings
import NlStrings
import NoStrings
import PaStrings
import PlStrings
import PsStrings
import PtBRStrings
import PtStrings
import RoStrings
import RuStrings
import SiStrings
import SkStrings
import SlStrings
import SoStrings
import SqStrings
import SrStrings
import SvStrings
import SwStrings
import TaStrings
import TeStrings
import ThStrings
import TlStrings
import TrStrings
import UkStrings
import UrStrings
import UzStrings
import ViStrings
import YoStrings
import ZhCNStrings
import ZhStrings
import ZhTWStrings
import ZuStrings
import tim.huang.genlayout.resources.Strings

internal val Strings: Map<LanguageTag, Strings> = mapOf(
    "am" to AmStrings,
    "ar" to ArStrings,
    "az" to AzStrings,
    "bn" to BnStrings,
    "bs" to BsStrings,
    "cs" to CsStrings,
    "da" to DaStrings,
    "de" to DeStrings,
    "el" to ElStrings,
    "en" to EnStrings,
    "es" to EsStrings,
    "et" to EtStrings,
    "fa" to FaStrings,
    "fi" to FiStrings,
    "fr-CA" to FrCAStrings,
    "fr" to FrStrings,
    "gu" to GuStrings,
    "ha" to HaStrings,
    "he" to HeStrings,
    "hi" to HiStrings,
    "hr" to HrStrings,
    "hu" to HuStrings,
    "hy" to HyStrings,
    "id" to IdStrings,
    "it" to ItStrings,
    "ja" to JaStrings,
    "ka" to KaStrings,
    "kk" to KkStrings,
    "km" to KmStrings,
    "kn" to KnStrings,
    "ko" to KoStrings,
    "lo" to LoStrings,
    "lt" to LtStrings,
    "lv" to LvStrings,
    "mk" to MkStrings,
    "ml" to MlStrings,
    "mn" to MnStrings,
    "mr" to MrStrings,
    "ms" to MsStrings,
    "my" to MyStrings,
    "ne" to NeStrings,
    "nl-BE" to NlBEStrings,
    "nl" to NlStrings,
    "no" to NoStrings,
    "pa" to PaStrings,
    "pl" to PlStrings,
    "ps" to PsStrings,
    "pt-BR" to PtBRStrings,
    "pt" to PtStrings,
    "ro" to RoStrings,
    "ru" to RuStrings,
    "si" to SiStrings,
    "sk" to SkStrings,
    "sl" to SlStrings,
    "so" to SoStrings,
    "sq" to SqStrings,
    "sr" to SrStrings,
    "sv" to SvStrings,
    "sw" to SwStrings,
    "ta" to TaStrings,
    "te" to TeStrings,
    "th" to ThStrings,
    "tl" to TlStrings,
    "tr" to TrStrings,
    "uk" to UkStrings,
    "ur" to UrStrings,
    "uz" to UzStrings,
    "vi" to ViStrings,
    "yo" to YoStrings,
    "zh-CN" to ZhCNStrings,
    "zh" to ZhStrings,
    "zh-TW" to ZhTWStrings,
    "zu" to ZuStrings
)

internal val LocalStrings: ProvidableCompositionLocal<Strings> = 
    staticCompositionLocalOf { EnStrings }

internal val strings: Strings
    @Composable
    get() = LocalStrings.current

@Composable
internal fun rememberStrings(
    defaultLanguageTag: LanguageTag = "en",
    currentLanguageTag: LanguageTag = Locale.current.toLanguageTag(),
): Lyricist<Strings> =
    rememberStrings(Strings, defaultLanguageTag, currentLanguageTag)

@Composable
internal fun ProvideStrings(
    lyricist: Lyricist<Strings> = rememberStrings(),
    content: @Composable () -> Unit
) {
    ProvideStrings(lyricist, LocalStrings, content)
}