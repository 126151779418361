import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.asList


object Routes {
    fun configNavigationRule(){
        document.querySelectorAll("[data-thq='thq-navbar-vendor-console']").asList().forEach {
            it.addEventListener("click", {
                window.location.href = "/?isVendor=true"
            })
        }

        document.querySelector("[data-thq='thq-navbar-vendor-console-shop-type-0']")?.addEventListener("click", {
            window.location.href = "/?isVendor=true&shopType=0"
        })

        document.querySelector("[data-thq='thq-navbar-vendor-console-shop-type-1']")?.addEventListener("click", {
            window.location.href = "/?isVendor=true&shopType=1"
        })

        document.querySelector("[data-thq='thq-navbar-vendor-console-shop-type-2']")?.addEventListener("click", {
            window.location.href = "/?isVendor=true&shopType=2"
        })
    }

    fun redirectToCallNumberPad(controlPadId: String){
        window.location.href = "/?isVendor=true&controlPad=$controlPadId"
    }

    fun redirectToVendorConsole(){
        window.location.href = "/?isVendor=true"
    }

    fun redirectToYourNumberPad(numberHash: String) {
        window.location.href = "/?dst=$numberHash"
    }
}