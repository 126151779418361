package tim.huang.genlayout.utils

import tim.huang.genlayout.model.Voice
import web.speech.SpeechSynthesis
import web.speech.SpeechSynthesisVoice
import web.speech.speechSynthesis

internal fun SpeechSynthesis.getSelectedVoice(voice: Voice): SpeechSynthesisVoice? {
    return speechSynthesis.getVoices().find { it.lang == voice.lang && it.name == voice.name }
        ?: speechSynthesis.getVoices().find { it.default } ?: speechSynthesis.getVoices().firstOrNull()
}

/**
 * Sort voices by the following order:
 * 1. The voice that matches the current voice
 * 2. The voice that matches the current language
 * 3. The default voice
 * 4. The voice that matches the default language
 * 5. Rest of the voices
 */
internal fun SpeechSynthesis.getSortedVoices(voice: Voice): List<SpeechSynthesisVoice> {
    val defaultVoice = speechSynthesis.getVoices().find { it.default }
    return speechSynthesis.getVoices().sortedWith(
        compareBy({ !(it.lang == voice.lang && it.name == voice.name) }, { voice.lang != it.lang }, { !it.default }, { defaultVoice?.lang != it.lang }, { it.lang }, { it.name })
    )
}