package tim.huang.genlayout.model

import tim.huang.genlayout.viewmodel.ShopType

data class NumberTicketShop(
    val name: String,
    val logo: String?,
    val userId: String,
    val shopType: ShopType,
    val number: Int,
    val waitingNumber: Int
)