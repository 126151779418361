package tim.huang.genlayout.model

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

data class NumberTicketVendorConsoleConfigs(
    val strokeWidth: Dp = 30.dp,
    //TODO make these default values more localized
    val callNumberPrefix: String = "Number",
    val callNumberSuffix: String = "please go to counter 1",
    val voice: Voice = Voice("Aaron", "en-US"),
    val strokeUpdateValue :Dp = 2.dp
){
    fun getCallNumberText(number: Int): String {
        return "$callNumberPrefix $number $callNumberSuffix"
    }
}

class Voice(val name: String, val lang: String)

data class VoiceControlData(
    val selectedVoice: Voice,
    val options: List<Voice>,
    val update: (Voice) -> Unit
)

data class StrokeWidthControlData(
    val strokeWidth: Dp,
    val strokeUpdateValue: Dp,
    val inc: () -> Unit,
    val dec: () -> Unit,
)
