import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sr", default = false)
val SrStrings = Strings(
    projectNameDialogIntro1 = "Здраво! Овде ћемо креирати услугу бројева",
	projectNameDialogIntro2 = "Молимо унесите име ваше продавнице:",
	projectNameDialogShopName = "Назив продавнице",
	submit = "Пошаљи",
	projectOptionsDialogIntro = { "Здраво, ${it}, какву врсту услуге желите:" },
	projectOptionsDialogShopType0Title = "Једноставна услуга позивања бројева",
	projectOptionsDialogShopType0Description = "Vlasnik/rukovalac prodavnice može pozivati brojeve jednostavnim dodirom na aplikaciju, čineći proces brzim i efikasnim.",
	projectOptionsDialogShopType1Title = "Услуга за преузимање броја купаца",
	projectOptionsDialogShopType1Description = "Kupci mogu uzeti broj i čekati pozivanje broja, osiguravajući da je proces čekanja gladak i brz.",
	projectOptionsDialogShopType2Title = "Обезбедити бројевну услугу",
	projectOptionsDialogShopType2Description = "Vlasnik/čuvar prodavnice obezbeđuje broj i prilagođava iskustvo pozivanja brojeva kako bi odgovaralo potrebama vašeg poslovanja sa podesivim postavkama.",
	vendorConsoleStartRunning = "Почни да трчиш",
	numberDisplayScreenTitle = "Узмите број овде!",
	qrCodeDescription = { "QR код који се односи на веб сајт ${it}" },
	takeNumberScreenInputPhoneNumber = "Унесите свој број телефона",
	takeNumberScreenPhoneNumber = "Број телефона",
	provideNumberPadGetNumber = "Узми број",
	provideNumberPadCurrentNumber = "Тренутни број",
	provideNumberPadYourNumber = { "Ваш број је ${it}" },
	info = "Информације",
	callNumberPadCurrentWaitingNumber = { "Тренутни број на чекању: ${it}" },
	displayNumberTitle = "Страница за приказ бројева",
	customerTakeNumberTitle = "Страница броја клијента",
	provideNumberTitle = "Обезбедите нумеричку страницу",
	yourNumberTitle = "Ваша страница бројева",
	callNumberTitle = "Страница броја позива",
	setting = "Подешавање",
	callNumberPadSoundSwitch = "Звук Укључен/Искључен",
	shutDown = "Искључи",
	ok = "У реду",
	cancel = "Откажи",
	startRunningShop = "Почни са радом продавнице",
	getToWork = "Хајде да почнемо да радимо!",
	uploadSuccess = "Отпрема је успешна!",
	uploadFailed = "Отпрема није успела!",
	imagePickerDialogTitle = "Ažurirajte logotip vaše prodavnice",
	imagePickerDialogAction = "Изаберите слику",
	cropImageDialogTitle = "Потврдите да прикажете ваш лого",
	editShopNameDialogTitle = "Ažurirajte ime svoje prodavnice"
)