import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "pt-BR", default = false)
val PtBRStrings = Strings(
    projectNameDialogIntro1 = "Olá! Vamos criar um serviço de bilhetes numerados",
	projectNameDialogIntro2 = "Por favor, insira o nome da sua loja:",
	projectNameDialogShopName = "Nome da loja",
	submit = "Enviar",
	projectOptionsDialogIntro = { "Oi, ${it}, que tipo de serviço você quer:" },
	projectOptionsDialogShopType0Title = "Serviço Simples de Chamada de Número",
	projectOptionsDialogShopType0Description = "O proprietário/gerente da loja pode chamar os números com um simples toque no aplicativo, tornando o processo rápido e eficiente.",
	projectOptionsDialogShopType1Title = "Serviço de Retirada de Senha para Clientes",
	projectOptionsDialogShopType1Description = "Os clientes podem pegar um número e esperar pela chamada, garantindo que o processo de espera seja suave e rápido.",
	projectOptionsDialogShopType2Title = "Fornecer Serviço de Número",
	projectOptionsDialogShopType2Description = "O proprietário/gerente da loja fornece números e personaliza a experiência de chamada de números para atender às necessidades do seu negócio com configurações personalizáveis.",
	vendorConsoleStartRunning = "Começar a correr",
	numberDisplayScreenTitle = "Pegue o número aqui!",
	qrCodeDescription = { "Código QR referente ao site ${it}" },
	takeNumberScreenInputPhoneNumber = "Digite seu número de telefone",
	takeNumberScreenPhoneNumber = "Número de telefone",
	provideNumberPadGetNumber = "Obter número",
	provideNumberPadCurrentNumber = "Número atual",
	provideNumberPadYourNumber = { "Seu número é ${it}" },
	info = "Informação",
	callNumberPadCurrentWaitingNumber = { "Número de espera atual: ${it}" },
	displayNumberTitle = "Página de exibição de números",
	customerTakeNumberTitle = "Página de número do cliente",
	provideNumberTitle = "Fornecer página de número",
	yourNumberTitle = "Sua página de número",
	callNumberTitle = "Página de chamada de número",
	setting = "Configuração",
	callNumberPadSoundSwitch = "Som Ligado/Desligado",
	shutDown = "Desligar",
	ok = "Ok",
	cancel = "Cancelar",
	startRunningShop = "Começar a operar a loja",
	getToWork = "Vamos ao trabalho!",
	uploadSuccess = "Upload bem-sucedido!",
	uploadFailed = "Falha no upload!",
	imagePickerDialogTitle = "Atualize o logotipo da sua loja",
	imagePickerDialogAction = "Escolha uma imagem",
	cropImageDialogTitle = "Confirme para exibir seu logotipo",
	editShopNameDialogTitle = "Atualize o nome da sua loja"
)