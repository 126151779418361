package tim.huang.genlayout.model

import androidx.compose.runtime.Composable
import cafe.adriel.lyricist.strings
import tim.huang.genlayout.viewmodel.ShopType

data class Project(val shopName: String, val shopLogo: String?, val shopType: ShopType, val pages: List<Page>) {
    fun getQrCodeInfo(): QRcodeInfo? {
        return if (shopType == ShopType.USER_TAKE_NUMBER) QRcodeInfo("") else null
    }
}

enum class Page {
    NUMBER_DISPLAY,
    CALL_NUMBER,
    TAKE_NUMBER,
    PROVIDE_NUMBER,
    YOUR_NUMBER;

    val title: String
    @Composable
    get() {
            return when(this){
                NUMBER_DISPLAY -> strings.displayNumberTitle
                CALL_NUMBER -> strings.callNumberTitle
               TAKE_NUMBER -> strings.customerTakeNumberTitle
                PROVIDE_NUMBER -> strings.provideNumberTitle
                YOUR_NUMBER -> strings.yourNumberTitle
            }
        }
}




