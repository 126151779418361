import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ur", default = false)
val UrStrings = Strings(
    projectNameDialogIntro1 = "ہیلو! یہاں ہم ایک نمبر ٹکٹ سروس بنانے جا رہے ہیں",
	projectNameDialogIntro2 = "براہ کرم اپنی دکان کا نام درج کریں:",
	projectNameDialogShopName = "دکان کا نام",
	submit = "جمع کروائیں",
	projectOptionsDialogIntro = { "ہیلو، ${it}، آپ کس قسم کی سروس چاہتے ہیں:" },
	projectOptionsDialogShopType0Title = "سادہ نمبر کالنگ سروس",
	projectOptionsDialogShopType0Description = "دکان کا مالک/رکھوالا ایپ پر ایک سادہ ٹچ کے ساتھ نمبر کال کر سکتا ہے، جس سے عمل تیز اور مؤثر ہو جاتا ہے۔",
	projectOptionsDialogShopType1Title = "کسٹمر نمبر لینے کی خدمت",
	projectOptionsDialogShopType1Description = "گاہک نمبر لے سکتے ہیں اور کال کے نمبر کا انتظار کر سکتے ہیں، اس بات کو یقینی بنائیں کہ انتظار کا عمل ہموار اور تیز ہو۔",
	projectOptionsDialogShopType2Title = "نمبر سروس فراہم کریں",
	projectOptionsDialogShopType2Description = "دکان کا مالک/رکھوالا نمبر فراہم کرتا ہے، اور آپ کی کاروباری ضروریات کو پورا کرنے کے لیے حسب ضرورت ترتیبات کے ساتھ نمبر کالنگ کا تجربہ تیار کرتا ہے۔",
	vendorConsoleStartRunning = "دوڑنا شروع کریں",
	numberDisplayScreenTitle = "یہاں نمبر لیں!",
	qrCodeDescription = { "${it} ویب سائٹ کا حوالہ دینے والا کیو آر کوڈ" },
	takeNumberScreenInputPhoneNumber = "اپنا فون نمبر درج کریں",
	takeNumberScreenPhoneNumber = "فون نمبر",
	provideNumberPadGetNumber = "نمبر حاصل کریں",
	provideNumberPadCurrentNumber = "موجودہ نمبر",
	provideNumberPadYourNumber = { "آپ کا نمبر ${it} ہے" },
	info = "معلومات",
	callNumberPadCurrentWaitingNumber = { "موجودہ انتظار کا نمبر: ${it}" },
	displayNumberTitle = "نمبر ڈسپلے پیج",
	customerTakeNumberTitle = "کسٹمر نمبر پیج",
	provideNumberTitle = "نمبر پیج فراہم کریں",
	yourNumberTitle = "آپ کا نمبر صفحہ",
	callNumberTitle = "کال نمبر صفحہ",
	setting = "ترتیب",
	callNumberPadSoundSwitch = "آواز چلائیں/بند کریں",
	shutDown = "بند کریں",
	ok = "ٹھیک",
	cancel = "منسوخ کریں",
	startRunningShop = "دکان چلانا شروع کریں",
	getToWork = "چلو کام پر لگیں!",
	uploadSuccess = "اپ لوڈ کامیاب!",
	uploadFailed = "اپ لوڈ ناکام ہو گیا!",
	imagePickerDialogTitle = "اپنے دکان کا لوگو اپ ڈیٹ کریں",
	imagePickerDialogAction = "ایک تصویر منتخب کریں",
	cropImageDialogTitle = "اپنے لوگو کو ظاہر کرنے کی تصدیق کریں",
	editShopNameDialogTitle = "اپنے دکان کا نام اپ ڈیٹ کریں"
)