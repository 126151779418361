import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "no", default = false)
val NoStrings = Strings(
    projectNameDialogIntro1 = "Hei! Her skal vi opprette en nummerservice",
	projectNameDialogIntro2 = "Vennligst skriv inn butikkens navn:",
	projectNameDialogShopName = "Butikknavn",
	submit = "Send inn",
	projectOptionsDialogIntro = { "Hei, ${it}, hva slags tjeneste ønsker du:" },
	projectOptionsDialogShopType0Title = "Enkel nummeroppringingstjeneste",
	projectOptionsDialogShopType0Description = "Butikkeier/ansvarlig kan ringe numre med et enkelt trykk på appen, noe som gjør prosessen rask og effektiv.",
	projectOptionsDialogShopType1Title = "Kundenummer Tjeneste",
	projectOptionsDialogShopType1Description = "Kunder kan ta nummer og vente på opprop, og sikre at ventetiden er jevn og rask.",
	projectOptionsDialogShopType2Title = "Gi nummerservice",
	projectOptionsDialogShopType2Description = "Butikkeier/ansvarlig gir nummer og skreddersyr nummeropplevelsen for å passe dine forretningsbehov med tilpassbare innstillinger.",
	vendorConsoleStartRunning = "Begynn å løpe",
	numberDisplayScreenTitle = "Ta nummer her!",
	qrCodeDescription = { "QR-kode som refererer til ${it} nettsted" },
	takeNumberScreenInputPhoneNumber = "Skriv inn telefonnummeret ditt",
	takeNumberScreenPhoneNumber = "Telefonnummer",
	provideNumberPadGetNumber = "Få nummer",
	provideNumberPadCurrentNumber = "Nåværende nummer",
	provideNumberPadYourNumber = { "Ditt nummer er ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Nåværende ventenummer: ${it}" },
	displayNumberTitle = "Nummervisningsside",
	customerTakeNumberTitle = "Kundenummerside",
	provideNumberTitle = "Gi nummerside",
	yourNumberTitle = "Din nummerside",
	callNumberTitle = "Ringesidenummer",
	setting = "Innstilling",
	callNumberPadSoundSwitch = "Lyd På/Av",
	shutDown = "Slå av",
	ok = "Ok",
	cancel = "Avbryt",
	startRunningShop = "Start butikkdriften",
	getToWork = "La oss komme i gang med arbeidet!",
	uploadSuccess = "Opplastingen var vellykket!",
	uploadFailed = "Opplasting mislyktes!",
	imagePickerDialogTitle = "Oppdater butikklogoen din",
	imagePickerDialogAction = "Velg et bilde",
	cropImageDialogTitle = "Bekreft for å vise logoen din",
	editShopNameDialogTitle = "Oppdater butikkens navn"
)