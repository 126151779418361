import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "km", default = false)
val KmStrings = Strings(
    projectNameDialogIntro1 = "សួស្ដី! នៅទីនេះយើងនឹងបង្កើតសេវាកម្មសំបុត្រលេខ",
	projectNameDialogIntro2 = "សូមបញ្ចូលឈ្មោះហាងរបស់អ្នក:",
	projectNameDialogShopName = "ឈ្មោះហាង",
	submit = "ដាក់ស្នើ",
	projectOptionsDialogIntro = { "សួស្ដី, ${it}, តើអ្នកចង់បានសេវាដូចម្តេច:" },
	projectOptionsDialogShopType0Title = "សេវាកម្មហៅលេខាអាសន្ន",
	projectOptionsDialogShopType0Description = "ម្ចាស់ហាង/អ្នកថែទាំអាចហៅលេខដោយគ្រាន់តែប៉ះលើកម្មវិធីធ្វើឱ្យដំណើរការលឿននិងមានប្រសិទ្ធភាព។",
	projectOptionsDialogShopType1Title = "សេវាកម្មយកលេខអតិថិជន",
	projectOptionsDialogShopType1Description = "អតិថិជនអាចយកលេខនិងរង់ចាំការហៅលេខ ដើម្បីធានាថាដំណើរការរង់ចាំគឺមានភាពរលូននិងរហ័ស។",
	projectOptionsDialogShopType2Title = "ផ្ដល់សេវាកម្មលេខ",
	projectOptionsDialogShopType2Description = "ម្ចាស់ហាង/អ្នកថែទាំផ្ដល់លេខ ហើយកែប្រែបទពិសោធន៍ហៅលេខាឲ្យសមស្របនឹងតម្រូវការអាជីវកម្មរបស់អ្នកជាមួយការកំណត់អាចប្តូរបាន។",
	vendorConsoleStartRunning = "ចាប់ផ្តើមរត់",
	numberDisplayScreenTitle = "យកលេខនៅទីនេះ!",
	qrCodeDescription = { "កូដ QR ដែលយោងទៅគេហទំព័រ ${it}" },
	takeNumberScreenInputPhoneNumber = "បញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
	takeNumberScreenPhoneNumber = "លេខទូរស័ព្ទ",
	provideNumberPadGetNumber = "យកលេខ",
	provideNumberPadCurrentNumber = "លេខបច្ចុប្បន្ន",
	provideNumberPadYourNumber = { "លេខរបស់អ្នកគឺ ${it}" },
	info = "ព័ត៌មាន",
	callNumberPadCurrentWaitingNumber = { "លេខរង់ចាំបច្ចុប្បន្ន: ${it}" },
	displayNumberTitle = "ទំព័របង្ហាញលេខ",
	customerTakeNumberTitle = "ទំព័រលេខអតិថិជន",
	provideNumberTitle = "ផ្តល់ទំព័រលេខ",
	yourNumberTitle = "ទំព័រលេខរបស់អ្នក",
	callNumberTitle = "ទំព័រលេខហៅ",
	setting = "ការកំណត់",
	callNumberPadSoundSwitch = "សម្លេង បើក/បិទ",
	shutDown = "បិទ",
	ok = "យល់ព្រម",
	cancel = "បោះបង់",
	startRunningShop = "ចាប់ផ្ដើមដំណើរការទំនាក់ទំនង",
	getToWork = "ចាប់ផ្ដើមធ្វើការ!",
	uploadSuccess = "ផ្ទុកឡើងបានជោគជ័យ!",
	uploadFailed = "ផ្ទុកឡើងបានបរាជ័យ!",
	imagePickerDialogTitle = "អាប់ដេតរូបសញ្ញាហាងរបស់អ្នក",
	imagePickerDialogAction = "ជ្រើសរូបភាព",
	cropImageDialogTitle = "បញ្ជាក់ដើម្បីបង្ហាញឡូហ្គូរបស់អ្នក",
	editShopNameDialogTitle = "អាប់ដេតឈ្មោះហាងរបស់អ្នក"
)