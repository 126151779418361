import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "pl", default = false)
val PlStrings = Strings(
    projectNameDialogIntro1 = "Cześć! Tutaj zamierzamy stworzyć usługę numerowanych biletów",
	projectNameDialogIntro2 = "Proszę wprowadzić nazwę sklepu:",
	projectNameDialogShopName = "Nazwa sklepu",
	submit = "Zatwierdź",
	projectOptionsDialogIntro = { "Cześć, ${it}, jakiego rodzaju usługę chcesz:" },
	projectOptionsDialogShopType0Title = "Prosta usługa wywoływania numerów",
	projectOptionsDialogShopType0Description = "Właściciel sklepu/zarządca może wywoływać numery jednym prostym stuknięciem w aplikacji, co sprawia, że proces jest szybki i wydajny.",
	projectOptionsDialogShopType1Title = "Usługa Pobierania Numeru Klienta",
	projectOptionsDialogShopType1Description = "Klienci mogą wziąć numer i czekać na wywołanie, zapewniając płynność i szybkość procesu oczekiwania.",
	projectOptionsDialogShopType2Title = "Świadczyć usługi numeracyjne",
	projectOptionsDialogShopType2Description = "Właściciel/sklepowy zapewnia numer i dostosowuje doświadczenie wywoływania numerów do potrzeb biznesowych dzięki konfigurowalnym ustawieniom.",
	vendorConsoleStartRunning = "Zacznij biegać",
	numberDisplayScreenTitle = "Weź numer tutaj!",
	qrCodeDescription = { "Kod QR odnoszący się do strony internetowej ${it}" },
	takeNumberScreenInputPhoneNumber = "Wprowadź swój numer telefonu",
	takeNumberScreenPhoneNumber = "Numer telefonu",
	provideNumberPadGetNumber = "Uzyskaj numer",
	provideNumberPadCurrentNumber = "Aktualny numer",
	provideNumberPadYourNumber = { "Twój numer to ${it}" },
	info = "Informacja",
	callNumberPadCurrentWaitingNumber = { "Aktualny numer oczekiwania: ${it}" },
	displayNumberTitle = "Strona wyświetlania numerów",
	customerTakeNumberTitle = "Strona numeru klienta",
	provideNumberTitle = "Zapewnij stronę numerów",
	yourNumberTitle = "Twoja strona numeru",
	callNumberTitle = "Strona numeru telefonu",
	setting = "Ustawienie",
	callNumberPadSoundSwitch = "Dźwięk Włącz/Wyłącz",
	shutDown = "Wyłącz",
	ok = "Ok",
	cancel = "Anuluj",
	startRunningShop = "Rozpocznij prowadzenie sklepu",
	getToWork = "Zabierzmy się do pracy!",
	uploadSuccess = "Przesyłanie zakończone sukcesem!",
	uploadFailed = "Przesyłanie nieudane!",
	imagePickerDialogTitle = "Zaktualizuj logo swojego sklepu",
	imagePickerDialogAction = "Wybierz obraz",
	cropImageDialogTitle = "Potwierdź, aby wyświetlić swoje logo",
	editShopNameDialogTitle = "Zaktualizuj nazwę swojego sklepu"
)