import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "az", default = false)
val AzStrings = Strings(
    projectNameDialogIntro1 = "Salam! Burada nömrəli bilet xidməti yaradacağıq",
	projectNameDialogIntro2 = "Zəhmət olmasa mağazanızın adını daxil edin:",
	projectNameDialogShopName = "Mağaza Adı",
	submit = "Təqdim et",
	projectOptionsDialogIntro = { "Salam, ${it}, hansı növ xidməti istəyirsiniz:" },
	projectOptionsDialogShopType0Title = "Sadə Nömrə Zəng Xidməti",
	projectOptionsDialogShopType0Description = "Mağaza sahibi/baxıcısı proqramda sadə bir toxunuşla nömrələri çağıraraq prosesi sürətli və səmərəli edir.",
	projectOptionsDialogShopType1Title = "Müştəri Nömrəsi Xidməti",
	projectOptionsDialogShopType1Description = "Müştərilər nömrə götürə və nömrə çağırmağı gözləyə bilər, gözləmə prosesinin hamar və sürətli olmasını təmin edin.",
	projectOptionsDialogShopType2Title = "Nömrə Xidməti Təmin Edin",
	projectOptionsDialogShopType2Description = "Mağaza sahibi/baxıcısı nömrə təqdim edir və tənzimlənən parametrlərlə nömrə zəng təcrübəsini iş ehtiyaclarınıza uyğunlaşdırır.",
	vendorConsoleStartRunning = "Qaçmağa başla",
	numberDisplayScreenTitle = "Buradan nömrə götürün!",
	qrCodeDescription = { "${it} veb saytına istinad edən QR kodu" },
	takeNumberScreenInputPhoneNumber = "Telefon nömrənizi daxil edin",
	takeNumberScreenPhoneNumber = "Telefon nömrəsi",
	provideNumberPadGetNumber = "Nömrə alın",
	provideNumberPadCurrentNumber = "Cari nömrə",
	provideNumberPadYourNumber = { "Sizin nömrəniz ${it}" },
	info = "Məlumat",
	callNumberPadCurrentWaitingNumber = { "Cari gözləmə nömrəsi: ${it}" },
	displayNumberTitle = "Nömrə ekranı səhifəsi",
	customerTakeNumberTitle = "Müştəri nömrəsi səhifəsi",
	provideNumberTitle = "Nömrə səhifəsi təmin edin",
	yourNumberTitle = "Sizin nömrə səhifəniz",
	callNumberTitle = "Zəng nömrəsi səhifəsi",
	setting = "Quraşdırma",
	callNumberPadSoundSwitch = "Səs Açıq/Sönük",
	shutDown = "Söndür",
	ok = "Ok",
	cancel = "Ləğv et",
	startRunningShop = "Mağazanı işlətməyə başla",
	getToWork = "Gəlin işə başlayaq!",
	uploadSuccess = "Yükləmə uğurla tamamlandı!",
	uploadFailed = "Yükləmə uğursuz oldu!",
	imagePickerDialogTitle = "Mağazanızın loqosunu yeniləyin",
	imagePickerDialogAction = "Şəkil seçin",
	cropImageDialogTitle = "Loqonuzu göstərməyi təsdiqləyin",
	editShopNameDialogTitle = "Mağazanızın adını yeniləyin"
)