import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "mr", default = false)
val MrStrings = Strings(
    projectNameDialogIntro1 = "नमस्कार! येथे आम्ही क्रमांक तिकीट सेवा तयार करणार आहोत",
	projectNameDialogIntro2 = "कृपया आपल्या दुकानाचे नाव प्रविष्ट करा:",
	projectNameDialogShopName = "दुकानाचे नाव",
	submit = "सबमिट करा",
	projectOptionsDialogIntro = { "हाय, ${it}, तुम्हाला कोणत्या प्रकारची सेवा हवी आहे:" },
	projectOptionsDialogShopType0Title = "सोपी क्रमांक कॉलिंग सेवा",
	projectOptionsDialogShopType0Description = "दुकान मालक/रक्षक अॅपवर एक साधा टॅप करून क्रमांक कॉल करू शकतो, ज्यामुळे प्रक्रिया जलद आणि कार्यक्षम होते.",
	projectOptionsDialogShopType1Title = "ग्राहक क्रमांक सेवा घ्या",
	projectOptionsDialogShopType1Description = "ग्राहक नंबर घेऊ शकतात आणि नंबर कॉल होण्याची वाट पाहू शकतात, प्रतीक्षा प्रक्रिया सुचारू आणि जलद आहे याची खात्री करा.",
	projectOptionsDialogShopType2Title = "क्रमांक सेवा प्रदान करा",
	projectOptionsDialogShopType2Description = "दुकान मालक/रक्षक नंबर पुरवतो, आणि सानुकूलनायोग्य सेटिंग्जसह आपल्या व्यवसायाच्या गरजेनुसार नंबर कॉलिंग अनुभवाला अनुरूप बनवतो.",
	vendorConsoleStartRunning = "धावणे सुरू करा",
	numberDisplayScreenTitle = "इथे क्रमांक घ्या!",
	qrCodeDescription = { "${it} वेबसाइटकडे संदर्भित करणारा QR कोड" },
	takeNumberScreenInputPhoneNumber = "आपला फोन नंबर प्रविष्ट करा",
	takeNumberScreenPhoneNumber = "फोन नंबर",
	provideNumberPadGetNumber = "क्रमांक मिळवा",
	provideNumberPadCurrentNumber = "सध्याचा क्रमांक",
	provideNumberPadYourNumber = { "तुमचा नंबर ${it} आहे" },
	info = "माहिती",
	callNumberPadCurrentWaitingNumber = { "चालू प्रतीक्षेत असलेला क्रमांक: ${it}" },
	displayNumberTitle = "संख्या प्रदर्शन पृष्ठ",
	customerTakeNumberTitle = "ग्राहक क्रमांक पृष्ठ",
	provideNumberTitle = "क्रमांक पृष्ठ प्रदान करा",
	yourNumberTitle = "आपले क्रमांक पृष्ठ",
	callNumberTitle = "कॉल नंबर पृष्ठ",
	setting = "सेटिंग",
	callNumberPadSoundSwitch = "ध्वनी चालू/बंद",
	shutDown = "बंद करा",
	ok = "ठीक आहे",
	cancel = "रद्द करा",
	startRunningShop = "दुकान चालवणे सुरू करा",
	getToWork = "कामाला लागूया!",
	uploadSuccess = "अपलोड यशस्वी!",
	uploadFailed = "अपलोड अयशस्वी!",
	imagePickerDialogTitle = "आपले दुकानाचे चिन्ह अद्यतनित करा",
	imagePickerDialogAction = "प्रतिमा निवडा",
	cropImageDialogTitle = "तुमचा लोगो प्रदर्शित करण्यासाठी पुष्टी करा",
	editShopNameDialogTitle = "तुमचे दुकानाचे नाव अद्यतनित करा"
)