package tim.huang.genlayout.sound

import web.audio.AudioContext
import web.events.EventHandler
import web.http.fetch
import web.speech.speechSynthesis

class NumberCallingControllerJs: NumberCallingController {

    override suspend fun numberCalling(number: Int) {
        if (speechSynthesis.speaking){
            return
        }
        audioPlay("audio/bell_ring.mp3", thenDo = {
            //feature will be added when text and voice could be manage in vendor console
//                shoutOutNumber(number)
        })
    }

    private suspend fun audioPlay(url: String, thenDo: () -> Unit = {}) {
        val context = AudioContext()
        val source = context.createBufferSource()

        source.onended = EventHandler {
            thenDo()
        }

        fetch(url).arrayBuffer().then { buffer ->
            context.decodeAudioData(buffer).then { decodedData ->
                source.buffer = decodedData
                source.connect(context.destination)
                source.start()
            }
        }
    }

    private fun shoutOutNumber(number: Int){
//        val config = _uiModel.value.config
//        val voice: SpeechSynthesisVoice = speechSynthesis.getSelectedVoice(config.voice)
//            ?: return
//        //TODO hint browser not support speech synthesis or internet connection issue
//
//        val msg = config.getCallNumberText(number)
//
//        val speech = SpeechSynthesisUtterance(msg)
//
//        speech.voice = voice
//
//        speechSynthesis.speak(speech)
    }

}
