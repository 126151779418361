import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "uz", default = false)
val UzStrings = Strings(
    projectNameDialogIntro1 = "Salom! Bu yerda biz raqamli bilet xizmatini yaratamiz",
	projectNameDialogIntro2 = "Iltimos, do'kon nomini kiriting:",
	projectNameDialogShopName = "Do‘kon nomi",
	submit = "Yuborish",
	projectOptionsDialogIntro = { "Salom, ${it}, qanday xizmat turini xohlaysiz:" },
	projectOptionsDialogShopType0Title = "Oddiy raqam chaqirish xizmati",
	projectOptionsDialogShopType0Description = "Do'kon egasi/boshqaruvchisi ilovada oddiy teginish bilan raqamlarni chaqirishi mumkin, bu jarayonni tez va samarali qiladi.",
	projectOptionsDialogShopType1Title = "Mijoz raqam olish xizmati",
	projectOptionsDialogShopType1Description = "Mijozlar raqamni olib, raqamni chaqirishni kutishlari mumkin, kutish jarayonining silliq va tezkor ekanligiga ishonch hosil qilishadi.",
	projectOptionsDialogShopType2Title = "Raqam xizmatini taqdim eting",
	projectOptionsDialogShopType2Description = "Do'kon egasi/xodimi raqam beradi va sozlanishi mumkin bo'lgan sozlamalar bilan biznes ehtiyojlaringizga mos ravishda raqam chaqirish tajribasini moslashtiradi.",
	vendorConsoleStartRunning = "Yugurishni boshlang",
	numberDisplayScreenTitle = "Bu yerdan raqam oling!",
	qrCodeDescription = { "${it} veb-saytiga ishora qiluvchi QR kodi" },
	takeNumberScreenInputPhoneNumber = "Telefon raqamingizni kiriting",
	takeNumberScreenPhoneNumber = "Telefon raqami",
	provideNumberPadGetNumber = "Raqam olish",
	provideNumberPadCurrentNumber = "Hozirgi raqam",
	provideNumberPadYourNumber = { "Sizning raqamingiz ${it}" },
	info = "Ma'lumot",
	callNumberPadCurrentWaitingNumber = { "Hozirgi kutish raqami: ${it}" },
	displayNumberTitle = "Raqam ko'rsatish sahifasi",
	customerTakeNumberTitle = "Mijozlar soni sahifasi",
	provideNumberTitle = "Raqamli sahifani taqdim eting",
	yourNumberTitle = "Sizning raqam sahifangiz",
	callNumberTitle = "Qo'ng'iroq raqami sahifasi",
	setting = "Sozlash",
	callNumberPadSoundSwitch = "Ovoz Yoqilgan/O'chirilgan",
	shutDown = "O‘chirish",
	ok = "Ok",
	cancel = "Bekor qilish",
	startRunningShop = "Do‘konni boshqarishni boshlash",
	getToWork = "Ishga kirishaylik!",
	uploadSuccess = "Yuklash muvaffaqiyatli!",
	uploadFailed = "Yuklash muvaffaqiyatsiz bo'ldi!",
	imagePickerDialogTitle = "Do'koningizning logotipini yangilang",
	imagePickerDialogAction = "Rasmni tanlang",
	cropImageDialogTitle = "Logotipingizni ko'rsatishni tasdiqlang",
	editShopNameDialogTitle = "Do'kon nomini yangilang"
)