import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "fr-CA", default = false)
val FrCAStrings = Strings(
    projectNameDialogIntro1 = "Bonjour! Ici, nous allons créer un service de billetterie numérotée",
	projectNameDialogIntro2 = "Veuillez entrer le nom de votre magasin:",
	projectNameDialogShopName = "Nom du magasin",
	submit = "Soumettre",
	projectOptionsDialogIntro = { "Salut, ${it}, quel type de service voulez-vous :" },
	projectOptionsDialogShopType0Title = "Service d'appel de numéros simple",
	projectOptionsDialogShopType0Description = "Le propriétaire/gérant de la boutique peut appeler des numéros d'un simple tapotement sur l'application, rendant le processus rapide et efficace.",
	projectOptionsDialogShopType1Title = "Service de prise de numéro pour les clients",
	projectOptionsDialogShopType1Description = "Les clients peuvent prendre un numéro et attendre l'appel, garantissant que le processus d'attente est fluide et rapide.",
	projectOptionsDialogShopType2Title = "Fournir un service de numéros",
	projectOptionsDialogShopType2Description = "Le propriétaire/gérant de la boutique fournit des numéros et adapte l'expérience d'appel de numéros pour répondre aux besoins de votre entreprise avec des paramètres personnalisables.",
	vendorConsoleStartRunning = "Commencer à courir",
	numberDisplayScreenTitle = "Prenez un numéro ici!",
	qrCodeDescription = { "Code QR renvoyant au site Web de ${it}" },
	takeNumberScreenInputPhoneNumber = "Entrez votre numéro de téléphone",
	takeNumberScreenPhoneNumber = "Numéro de téléphone",
	provideNumberPadGetNumber = "Obtenir un numéro",
	provideNumberPadCurrentNumber = "Numéro actuel",
	provideNumberPadYourNumber = { "Votre numéro est ${it}" },
	info = "Info",
	callNumberPadCurrentWaitingNumber = { "Numéro d'attente actuel: ${it}" },
	displayNumberTitle = "Page d'affichage des numéros",
	customerTakeNumberTitle = "Page de numéro de client",
	provideNumberTitle = "Fournir une page de numéro",
	yourNumberTitle = "Votre page de numéro",
	callNumberTitle = "Page de numéro d'appel",
	setting = "Paramètre",
	callNumberPadSoundSwitch = "Son Activé/Désactivé",
	shutDown = "Éteindre",
	ok = "D’accord",
	cancel = "Annuler",
	startRunningShop = "Démarrer la boutique",
	getToWork = "Allons-y au travail !",
	uploadSuccess = "Téléversement réussi!",
	uploadFailed = "Échec du téléversement!",
	imagePickerDialogTitle = "Mettez à jour le logo de votre boutique",
	imagePickerDialogAction = "Choisissez une image",
	cropImageDialogTitle = "Confirmez l'affichage de votre logo",
	editShopNameDialogTitle = "Mettez à jour le nom de votre boutique"
)