package tim.huang.genlayout.ui.widget

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import cafe.adriel.lyricist.strings

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun EditShopNameDialog(shopName: String, onDismiss: () -> Unit, onConfirm: (String) -> Unit) {

    var textValue by remember { mutableStateOf(shopName) }

    val windowSizeClass = calculateWindowSizeClass()
    val buttonPadding = when(windowSizeClass.widthSizeClass){
        WindowWidthSizeClass.Compact ->  16.dp
        else ->  32.dp
    }

    Dialog(
        onDismissRequest = onDismiss,
    ) {
        Surface(
            shape = RoundedCornerShape(16.dp),
        ) {
            Column(
                modifier = Modifier.padding(buttonPadding)
            ) {

                Text(
                    strings.editShopNameDialogTitle,
                    style = MaterialTheme.typography.h5,
                )

                Column(
                    modifier = Modifier.padding(top = 8.dp).width(IntrinsicSize.Min)
                ) {
                    OutlinedTextField(
                        value = textValue,
                        onValueChange = { textValue = it },
                        label = { Text(strings.projectNameDialogShopName) },
                        modifier = Modifier.defaultMinSize(minWidth = 200.dp).fillMaxWidth(),
                    )

                    Row(
                        horizontalArrangement = Arrangement.SpaceEvenly,
                        modifier = Modifier.fillMaxWidth()
                    ) {

                        TextButton(onClick = onDismiss, modifier = Modifier.padding(8.dp)) {
                            Text(
                                text = strings.cancel,
                                modifier = Modifier.padding(horizontal = buttonPadding),
                                style = MaterialTheme.typography.button,
                                fontWeight = FontWeight.Bold
                            )
                        }

                        Button(
                            onClick = { onConfirm(textValue) },
                            modifier = Modifier.padding(8.dp)
                        ) {
                            Text(
                                text = strings.submit,
                                modifier = Modifier.padding(horizontal = buttonPadding),
                                style = MaterialTheme.typography.button,
                                maxLines = 1,
                                fontWeight = FontWeight.Bold
                            )
                        }
                    }
                }
            }
        }
    }
}