package tim.huang.genlayout.di

import firebaseApp
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import tim.huang.genlayout.model.Shop
import tim.huang.genlayout.sound.NumberCallingController
import tim.huang.genlayout.sound.NumberCallingControllerJs
import tim.huang.genlayout.viewmodel.VendorConsoleViewModel
import tim.huang.genlayout.viewmodel.VendorConsoleViewModelJs

actual val platformModule = module {
    single { (shop : Shop?) -> VendorConsoleViewModelJs(get(), shop, get(), get(), get()) }.bind<VendorConsoleViewModel>()
    single { firebaseApp }
    singleOf(::NumberCallingControllerJs).bind<NumberCallingController>()
}