import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "sw", default = false)
val SwStrings = Strings(
    projectNameDialogIntro1 = "Habari! Hapa tutaunda huduma ya tiketi za namba",
	projectNameDialogIntro2 = "Tafadhali ingiza jina la duka lako:",
	projectNameDialogShopName = "Jina la Duka",
	submit = "Tuma",
	projectOptionsDialogIntro = { "Hujambo, ${it}, unataka huduma ya aina gani:" },
	projectOptionsDialogShopType0Title = "Huduma Rahisi ya Kupiga Namba",
	projectOptionsDialogShopType0Description = "Mmiliki/mtunzaji wa duka anaweza kupiga namba kwa kugusa rahisi kwenye programu, na kufanya mchakato kuwa wa haraka na mzuri.",
	projectOptionsDialogShopType1Title = "Huduma ya Kuchukua Namba ya Wateja",
	projectOptionsDialogShopType1Description = "Wateja wanaweza kuchukua namba na kusubiri kupigiwa namba, kuhakikisha mchakato wa kusubiri ni laini na wa haraka.",
	projectOptionsDialogShopType2Title = "Kutoa Huduma ya Nambari",
	projectOptionsDialogShopType2Description = "Mmiliki/mtunzaji wa duka hutoa nambari, na kubinafsisha uzoefu wa kupiga simu ili kukidhi mahitaji yako ya biashara na mipangilio inayoweza kubadilishwa.",
	vendorConsoleStartRunning = "Anza kukimbia",
	numberDisplayScreenTitle = "Chukua namba hapa!",
	qrCodeDescription = { "Msimbo wa QR unaorejelea tovuti ya ${it}" },
	takeNumberScreenInputPhoneNumber = "Ingiza namba yako ya simu",
	takeNumberScreenPhoneNumber = "Nambari ya simu",
	provideNumberPadGetNumber = "Pata nambari",
	provideNumberPadCurrentNumber = "Nambari ya sasa",
	provideNumberPadYourNumber = { "Nambari yako ni ${it}" },
	info = "Habari",
	callNumberPadCurrentWaitingNumber = { "Nambari ya sasa inayosubiri: ${it}" },
	displayNumberTitle = "Ukurasa wa onyesho la nambari",
	customerTakeNumberTitle = "Ukurasa wa nambari ya mteja",
	provideNumberTitle = "Toa ukurasa wa nambari",
	yourNumberTitle = "Ukurasa wako wa nambari",
	callNumberTitle = "Ukurasa wa nambari ya simu",
	setting = "Kuweka",
	callNumberPadSoundSwitch = "Sauti Kuwashwa/Kuzima",
	shutDown = "Zima",
	ok = "Sawa",
	cancel = "Ghairi",
	startRunningShop = "Anza kuendesha duka",
	getToWork = "Hebu tuanze kazi!",
	uploadSuccess = "Kupakia mafanikio!",
	uploadFailed = "Kupakia imeshindikana!",
	imagePickerDialogTitle = "Sasisha nembo ya duka lako",
	imagePickerDialogAction = "Chagua picha",
	cropImageDialogTitle = "Thibitisha kuonyesha nembo yako",
	editShopNameDialogTitle = "Sasisha jina la duka lako"
)