package tim.huang.genlayout.ui.widget

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue

@Composable
fun EditLogoDialog(onDismiss: () -> Unit, onConfirm: (ByteArray)-> Unit) {

    //use state to switch between file selection dialog and crop image dialog
    var selectedFile: ByteArray? by remember { mutableStateOf(null) }

    if (selectedFile != null){
        //crop image dialog
        CropImageDialog(selectedFile!!, onDismiss, onSubmit = { onConfirm(it) })
    }else{
        ImagePickerDialog(onDismiss) { selectedFile = it }
    }
}