package tim.huang.genlayout

import kotlin.Boolean
import kotlin.String

internal actual object BuildKonfig {
  public actual val PLATFORM: String = "js"

  public actual val DEBUG: Boolean = false
}
