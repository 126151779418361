import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "kn", default = false)
val KnStrings = Strings(
    projectNameDialogIntro1 = "ಹಲೋ! ಇಲ್ಲಿ ನಾವು ಸಂಖ್ಯೆ ಟಿಕೆಟ್ ಸೇವೆಯನ್ನು ರಚಿಸುತ್ತೇವೆ",
	projectNameDialogIntro2 = "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಂಗಡಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ:",
	projectNameDialogShopName = "ಅಂಗಡಿಯ ಹೆಸರು",
	submit = "ಸಲ್ಲಿಸು",
	projectOptionsDialogIntro = { "ಹಾಯ್, ${it}, ನಿಮಗೆ ಯಾವ ರೀತಿಯ ಸೇವೆ ಬೇಕು:" },
	projectOptionsDialogShopType0Title = "ಸರಳ ಸಂಖ್ಯೆಯ ಕಾಲಿಂಗ್ ಸೇವೆ",
	projectOptionsDialogShopType0Description = "ದುಕಾನದ ಮಾಲೀಕ/ರಕ್ಷಕವು ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಸರಳ ಟ್ಯಾಪ್‌ನೊಂದಿಗೆ ಸಂಖ್ಯೆಗಳನ್ನು ಕರೆ ಮಾಡಬಹುದು, ಇದರಿಂದ ಪ್ರಕ್ರಿಯೆಯನ್ನು ವೇಗವಾಗಿ ಮತ್ತು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಮಾಡಬಹುದು.",
	projectOptionsDialogShopType1Title = "ಗ್ರಾಹಕರ ಸಂಖ್ಯೆ ಸೇವೆ",
	projectOptionsDialogShopType1Description = "ಗ್ರಾಹಕರು ಸಂಖ್ಯೆ ತೆಗೆದುಕೊಂಡು ಸಂಖ್ಯೆಯನ್ನು ಕರೆ ಮಾಡಲು ನಿರೀಕ್ಷಿಸಬಹುದು, ನಿರೀಕ್ಷೆಯ ಪ್ರಕ್ರಿಯೆ ಸುಲಭ ಮತ್ತು ವೇಗವಾಗಿ ನಡೆಯುತ್ತದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.",
	projectOptionsDialogShopType2Title = "ಸಂಖ್ಯೆ ಸೇವೆ ಒದಗಿಸಿ",
	projectOptionsDialogShopType2Description = "ಅಂಗಡಿಯ ಮಾಲಿಕ/ರಕ್ಷಕ ಸಂಖ್ಯೆಯನ್ನು ಒದಗಿಸುತ್ತಾರೆ, ಮತ್ತು ಕಸ್ಟಮೈಸ್ ಮಾಡಬಹುದಾದ ಸೆಟ್ಟಿಂಗ್‌ಗಳೊಂದಿಗೆ ನಿಮ್ಮ ವ್ಯವಹಾರದ ಅಗತ್ಯಗಳಿಗೆ ತಕ್ಕಂತೆ ಸಂಖ್ಯಾ ಕರೆ ಅನುಭವವನ್ನು ಹೊಂದಿಸುತ್ತಾರೆ.",
	vendorConsoleStartRunning = "ಓಟ ಪ್ರಾರಂಭಿಸಿ",
	numberDisplayScreenTitle = "ಇಲ್ಲಿ ಸಂಖ್ಯೆ ತೆಗೆದುಕೊಳ್ಳಿ!",
	qrCodeDescription = { "${it} ವೆಬ್‌ಸೈಟ್‌ಗೆ ಸೂಚಿಸುವ QR ಕೋಡ್" },
	takeNumberScreenInputPhoneNumber = "ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	takeNumberScreenPhoneNumber = "ಫೋನ್ ಸಂಖ್ಯೆ",
	provideNumberPadGetNumber = "ಸಂಖ್ಯೆ ಪಡೆಯಿರಿ",
	provideNumberPadCurrentNumber = "ಪ್ರಸ್ತುತ ಸಂಖ್ಯೆ",
	provideNumberPadYourNumber = { "ನಿಮ್ಮ ಸಂಖ್ಯೆ ${it}" },
	info = "ಮಾಹಿತಿ",
	callNumberPadCurrentWaitingNumber = { "ಪ್ರಸ್ತುತ ನಿರೀಕ್ಷಣೆಯ ಸಂಖ್ಯೆಯು: ${it}" },
	displayNumberTitle = "ಸಂಖ್ಯೆ ಪ್ರದರ್ಶನ ಪುಟ",
	customerTakeNumberTitle = "ಗ್ರಾಹಕರ ಸಂಖ್ಯೆ ಪುಟ",
	provideNumberTitle = "ಸಂಖ್ಯಾ ಪುಟವನ್ನು ಒದಗಿಸಿ",
	yourNumberTitle = "ನಿಮ್ಮ ಸಂಖ್ಯೆಯ ಪುಟ",
	callNumberTitle = "ಕಾಲ್ ನಂಬರಿನ ಪುಟ",
	setting = "ಸೆಟ್ಟಿಂಗ್",
	callNumberPadSoundSwitch = "ಧ್ವನಿ ಆನ್/ಆಫ್",
	shutDown = "ಆಫ್ ಮಾಡಿ",
	ok = "ಸರಿ",
	cancel = "ರದ್ದು ಮಾಡು",
	startRunningShop = "ದುಕಾನವನ್ನು ಆರಂಭಿಸಿ",
	getToWork = "ಏನು ಹೊಡತಿನಾದರೂ ಮಾಡೋಣ!",
	uploadSuccess = "ಅಪ್ಲೋಡ್ ಯಶಸ್ವಿಯಾಗಿದೆ!",
	uploadFailed = "ಅಪ್ಲೋಡ್ ವಿಫಲವಾಗಿದೆ!",
	imagePickerDialogTitle = "ನಿಮ್ಮ ಅಂಗಡಿಯ ಲೋಗೋ ಅನ್ನು ನವೀಕರಿಸಿ",
	imagePickerDialogAction = "ಚಿತ್ರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	cropImageDialogTitle = "ನಿಮ್ಮ ಲೋಗೋವನ್ನು ತೋರಿಸಲು ದೃಢೀಕರಿಸಿ",
	editShopNameDialogTitle = "ನಿಮ್ಮ ಅಂಗಡಿಯ ಹೆಸರನ್ನು ನವೀಕರಿಸಿ"
)