package tim.huang.genlayout.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import cafe.adriel.lyricist.strings
import tim.huang.genlayout.ui.theme.lightGray
import tim.huang.genlayout.ui.utils.mobileLayout
import tim.huang.genlayout.ui.widget.TitleLogoRow

@Composable
fun YourNumberScreen(title: String, logo: String?, curNumber: Int, yourNumber: Int, modifier: Modifier = Modifier) {

    Column(modifier = modifier.mobileLayout().background(lightGray)) {
        TitleLogoRow(title, logo)

        Column(
            modifier = Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceEvenly
        ) {
            Text(
                strings.provideNumberPadYourNumber(""),
                style = MaterialTheme.typography.h4,
                fontWeight = FontWeight.Bold,
            )
            Text(
                "$yourNumber",
                style = MaterialTheme.typography.h3,
                fontWeight = FontWeight.Bold,
                color = MaterialTheme.colors.primary
            )
            Text(
                strings.provideNumberPadCurrentNumber,
                style = MaterialTheme.typography.h5,
                fontWeight = FontWeight.Bold,
            )
            Text(
                "$curNumber",
                style = MaterialTheme.typography.h3,
                fontWeight = FontWeight.Bold,
                color = MaterialTheme.colors.secondary
            )

        }
    }
}