import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ta", default = false)
val TaStrings = Strings(
    projectNameDialogIntro1 = "வணக்கம்! இங்கே எண் டிக்கெட் சேவையை உருவாக்கவிருக்கிறோம்",
	projectNameDialogIntro2 = "உங்கள் கடையின் பெயரை உள்ளிடவும்:",
	projectNameDialogShopName = "கடை பெயர்",
	submit = "சமர்ப்பிக்கவும்",
	projectOptionsDialogIntro = { "வணக்கம், ${it}, உங்களுக்கு எந்த வகையான சேவை வேண்டும்:" },
	projectOptionsDialogShopType0Title = "எளிய எண் அழைப்பு சேவை",
	projectOptionsDialogShopType0Description = "கடை உரிமையாளர் / பராமரிப்பாளர் செயலியில் எளிய தட்டியால் எண்ணங்களை அழைக்கலாம், செயல்முறையை விரைவாகவும் செயல்திறனாகவும் மாற்றுகிறது.",
	projectOptionsDialogShopType1Title = "வாடிக்கையாளர் எண் எடுக்கும் சேவை",
	projectOptionsDialogShopType1Description = "வாடிக்கையாளர்கள் ஒரு எண்ணை எடுத்து அழைக்க நிற்கலாம், காத்திருக்கும் செயல்முறை மென்மையான மற்றும் விரைவானது என உறுதி செய்யவும்.",
	projectOptionsDialogShopType2Title = "எண் சேவையை வழங்கவும்",
	projectOptionsDialogShopType2Description = "கடை உரிமையாளர் / பராமரிப்பாளர் எண்ணங்களை வழங்கி, தனிப்பயன் அமைப்புகளுடன் உங்கள் வணிக தேவைகளுக்கு ஏற்ப எண்ணிக்கை அழைப்புக் குறைபாட்டைத் தயார் செய்கிறார்.",
	vendorConsoleStartRunning = "ஓட தொடங்கு",
	numberDisplayScreenTitle = "இங்கே எண்ணை எடுக்கவும்!",
	qrCodeDescription = { "${it} இணையதளத்தை குறிக்கும் QR குறியீடு" },
	takeNumberScreenInputPhoneNumber = "உங்கள் தொலைபேசி எண் உள்ளிடவும்",
	takeNumberScreenPhoneNumber = "தொலைபேசி எண்",
	provideNumberPadGetNumber = "எண் பெற",
	provideNumberPadCurrentNumber = "தற்போதைய எண்",
	provideNumberPadYourNumber = { "உங்கள் எண் ${it}" },
	info = "தகவல்",
	callNumberPadCurrentWaitingNumber = { "தற்போதைய காத்திருக்கும் எண்: ${it}" },
	displayNumberTitle = "எண் காட்சி பக்கம்",
	customerTakeNumberTitle = "வாடிக்கையாளர் எண் பக்கம்",
	provideNumberTitle = "எண் பக்கத்தை வழங்குங்கள்",
	yourNumberTitle = "உங்கள் எண் பக்கம்",
	callNumberTitle = "அழைப்பு எண் பக்கம்",
	setting = "அமைப்பு",
	callNumberPadSoundSwitch = "ஒலி ஆன்/ஆஃப்",
	shutDown = "ஆஃப் செய்யவும்",
	ok = "சரி",
	cancel = "ரத்து",
	startRunningShop = "கடை ஓட்டத் தொடங்கு",
	getToWork = "வாங்க வேலை செய்யலாமா!",
	uploadSuccess = "பதிவேற்றம் வெற்றிகரமாக முடிந்தது!",
	uploadFailed = "பதிவேற்றம் தோல்வியுற்றது!",
	imagePickerDialogTitle = "உங்கள் கடையின் லோகோவைப் புதுப்பிக்கவும்",
	imagePickerDialogAction = "ஒரு படத்தைத் தேர்ந்தெடுக்கவும்",
	cropImageDialogTitle = "உங்கள் லோகோவை காட்ட உறுதிப்படுத்தவும்",
	editShopNameDialogTitle = "உங்கள் கடை பெயரைப் புதுப்பிக்கவும்"
)