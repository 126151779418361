package tim.huang.genlayout.data.operation

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.firestore.FieldValue
import dev.gitlive.firebase.firestore.FirebaseFirestore
import dev.gitlive.firebase.firestore.Timestamp
import dev.gitlive.firebase.firestore.firestore
import dev.gitlive.firebase.firestore.where
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import tim.huang.genlayout.data.ShopStatus
import tim.huang.genlayout.data.VendorProject
import tim.huang.genlayout.data.VendorShop
import tim.huang.genlayout.model.NumberTicketShop
import tim.huang.genlayout.model.Project
import tim.huang.genlayout.viewmodel.ShopType

class FirebaseOperation(firebaseApp: FirebaseApp): DatabaseOperation {

    private val fireStore: FirebaseFirestore = Firebase.firestore(firebaseApp)
    private val firebaseAuth = Firebase.auth(firebaseApp)

    suspend fun createShop(project: Project): Pair<String, VendorShop> {
        //firestore create shop at shops collection
        //then store this shop id to users/shops collection
        val userId = firebaseAuth.currentUser?.uid ?: throw FirebaseNoUserException()
        val shop = VendorShop(
            project.shopName,
            userId,
            project.shopLogo,
            shopType = project.shopType.typeValue,
            createdTime = Timestamp.now(),
        )
        val shopId = run { fireStore.collection("shops").add(shop) }.id
        fireStore.collection("users").document(userId).collection("shops").document(shopId).set(
            ShopStatus(true, project.shopName, project.shopLogo, shopType = project.shopType.typeValue)
        )
        return shopId to shop
    }

    override fun receiveShopInfo(shopId: String): Flow<NumberTicketShop> {
        return fireStore.collection("shops").document(shopId).snapshots.map {
            it.data(VendorShop.serializer()).run {
                NumberTicketShop(
                    name = name,
                    logo = logo,
                    userId = userId,
                    shopType = ShopType.fromValue(shopType),
                    number = number,
                    waitingNumber = waitingNumber,
                )
            }
        }
    }

    suspend fun getShopInfo(shopId: String): VendorShop {
        return fireStore.collection("shops").document(shopId).get().data(VendorShop.serializer())
    }

    suspend fun checkRunningShop(): Pair<String, VendorShop>? {
        //find live shop's shopId under user
         val document = firebaseAuth.currentUser?.uid?.let { userId ->
            fireStore.collection("users").document(userId).collection("shops").where {
                "isOnline" equalTo true
            }.get().documents.firstOrNull()
        }
        //use shopId to get shop info
        return document?.id?.let { it to getShopInfo(it) }
    }

    suspend fun checkCreatedProject(): Pair<String, Project>? {
        return firebaseAuth.currentUser?.uid?.let { userId ->
            val document = fireStore.collection("users").document(userId).collection("projects").get().documents.firstOrNull()
            document?.data(VendorProject.serializer())
                ?.let {
                    val shopType = ShopType.fromValue(it.shopType)
                    document.id to Project(it.shopName, it.shopLogo, shopType, shopType.pages())
                }
        }
    }

    suspend fun shutDownShop(shopId: String) {
        firebaseAuth.currentUser?.uid?.let { userId ->
            fireStore.collection("users").document(userId).collection("shops").document(shopId).delete()
        }
        fireStore.collection("shops").document(shopId).delete()
    }

    /**
     * This only update the number without overwriting any other field
     */
    suspend fun updateNumber(shopId: String, number: Int) {
        fireStore.collection("shops").document(shopId).update(
            "number" to number,
            "updatedTime" to Timestamp.now()
        )
    }

    suspend fun createProject(shopName: String, shopType: ShopType): Pair<String, Project> {
        val userId = firebaseAuth.currentUser?.uid ?: throw FirebaseNoUserException()
        val projectId = run {
            fireStore.collection("users").document(userId).collection("projects").add(
                VendorProject(shopName, shopType.typeValue, createdTime = Timestamp.now()))
        }.id
        return projectId to Project(shopName, null, shopType, shopType.pages())
    }

    /**
     * firestore may try multiple times to make sure the transaction is successful
     */
    override suspend fun atomicFetchWaitingNumber(shopId: String): Int {
        val docRef = fireStore.collection("shops").document(shopId)
        return fireStore.runTransaction {
            val newNumber = get(docRef).data(VendorShop.serializer()).waitingNumber + 1
            update(docRef, "waitingNumber" to newNumber, "updatedTime" to FieldValue.serverTimestamp)
            newNumber
        }
    }

    fun getWaitingNumber(shopId: String): Flow<Int> {
        return fireStore.collection("shops").document(shopId).snapshots.map {
            it.data(VendorShop.serializer()).waitingNumber
        }
    }

    suspend fun updateProjectName(projectId: String, shopName: String){
        val userId = firebaseAuth.currentUser?.uid ?: throw FirebaseNoUserException()
        fireStore.collection("users").document(userId).collection("projects").document(projectId).update(
            "shopName" to shopName,
            "updatedTime" to Timestamp.now()
        )
    }

    suspend fun updateProjectLogo(projectId: String, newLogoUrl: String) {
        val userId = firebaseAuth.currentUser?.uid ?: throw FirebaseNoUserException()
        fireStore.collection("users").document(userId).collection("projects").document(projectId).update(
            "shopLogo" to newLogoUrl,
            "updatedTime" to Timestamp.now()
        )
    }
}

class FirebaseNotInitializedException : Exception("Firebase is not initialized")
class FirebaseNoUserException : Exception("User is not logged in")
