import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "mk", default = false)
val MkStrings = Strings(
    projectNameDialogIntro1 = "Здраво! Овде ќе создадеме услуга за броеви на билети",
	projectNameDialogIntro2 = "Ве молиме внесете го името на вашата продавница:",
	projectNameDialogShopName = "Име на продавница",
	submit = "Поднеси",
	projectOptionsDialogIntro = { "Здраво, ${it}, каква услуга сакате:" },
	projectOptionsDialogShopType0Title = "Едноставна услуга за повикување броеви",
	projectOptionsDialogShopType0Description = "Сопственикот/чуварот на продавницата може да повикува броеви со едноставно притискање на апликацијата, што го прави процесот брз и ефикасен.",
	projectOptionsDialogShopType1Title = "Услуга за земање број на клиент",
	projectOptionsDialogShopType1Description = "Клиентите можат да земат број и да чекаат повик на бројот, осигурувајќи дека процесот на чекање е мазен и брз.",
	projectOptionsDialogShopType2Title = "Обезбедете услуга за броеви",
	projectOptionsDialogShopType2Description = "Сопственикот/чуварот на продавницата обезбедува број и го прилагодува искуството со повикување на бројот за да одговара на потребите на вашата деловна активност со прилагодливи поставки.",
	vendorConsoleStartRunning = "Започни да трчаш",
	numberDisplayScreenTitle = "Земи број овде!",
	qrCodeDescription = { "QR код што се однесува на веб-страницата ${it}" },
	takeNumberScreenInputPhoneNumber = "Внесете го вашиот телефонски број",
	takeNumberScreenPhoneNumber = "Телефонски број",
	provideNumberPadGetNumber = "Земете број",
	provideNumberPadCurrentNumber = "Тековен број",
	provideNumberPadYourNumber = { "Вашиот број е ${it}" },
	info = "Информации",
	callNumberPadCurrentWaitingNumber = { "Тековен број на чекање: ${it}" },
	displayNumberTitle = "Страница за прикажување броеви",
	customerTakeNumberTitle = "Страница за број на клиент",
	provideNumberTitle = "Обезбедете нумеричка страница",
	yourNumberTitle = "Вашата страница за бројот",
	callNumberTitle = "Страница за повикување на бројот",
	setting = "Поставување",
	callNumberPadSoundSwitch = "Звук Вклучен/Исклучен",
	shutDown = "Исклучи",
	ok = "Ок",
	cancel = "Откажи",
	startRunningShop = "Започни со работа на продавница",
	getToWork = "Ајде да работиме!",
	uploadSuccess = "Подигнувањето е успешно!",
	uploadFailed = "Подигнувањето не успеа!",
	imagePickerDialogTitle = "Ажурирајте го логото на вашата продавница",
	imagePickerDialogAction = "Изберете слика",
	cropImageDialogTitle = "Потврдете да го прикажете вашиот лого",
	editShopNameDialogTitle = "Ажурирајте го името на вашата продавница"
)