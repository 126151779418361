import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ps", default = false)
val PsStrings = Strings(
    projectNameDialogIntro1 = "سلام! دلته موږ د نمبر ټیکټ خدمت جوړوو",
	projectNameDialogIntro2 = "مهرباني وکړئ د خپلې دوکان نوم دننه کړئ:",
	projectNameDialogShopName = "دکان نوم",
	submit = "وسپارئ",
	projectOptionsDialogIntro = { "سلام، ${it}، تاسو کوم ډول خدمت غواړئ:" },
	projectOptionsDialogShopType0Title = "د ساده شمیرې غوښتنې خدمت",
	projectOptionsDialogShopType0Description = "دکان مالک/ساتونکی کولی شي د اپلیکیشن په ساده ټچ سره شمیرې زنګ ووهي، چې دا پروسه چټکه او موثره کوي.",
	projectOptionsDialogShopType1Title = "د پیرودونکي نمبر خدمت واخلئ",
	projectOptionsDialogShopType1Description = "پیرودونکي شمیره اخیستلای شي او د کال کولو شمیره انتظار کوي، ډاډ ترلاسه کړئ چې د انتظار بهیر نرم او ګړندی دی.",
	projectOptionsDialogShopType2Title = "د نمبر خدمت چمتو کړئ",
	projectOptionsDialogShopType2Description = "د دوکان مالک/ساتونکی نمبر چمتو کوي، او د تنظیم وړ ترتیباتو سره ستاسو د سوداګرۍ اړتیاو سره سم د نمبر زنګ وهلو تجربه چمتو کوي.",
	vendorConsoleStartRunning = "پیل کړئ",
	numberDisplayScreenTitle = "دلته شمېره واخلئ!",
	qrCodeDescription = { "${it} ویب پاڼې ته مراجعه کوونکی QR کوډ" },
	takeNumberScreenInputPhoneNumber = "خپل د تلیفون شمېره دننه کړئ",
	takeNumberScreenPhoneNumber = "د تلیفون شمېره",
	provideNumberPadGetNumber = "شمېره ترلاسه کړئ",
	provideNumberPadCurrentNumber = "اوسنی شمېره",
	provideNumberPadYourNumber = { "ستاسو شمېره ${it} ده" },
	info = "معلومات",
	callNumberPadCurrentWaitingNumber = { "اوسنۍ انتظار شمېره: ${it}" },
	displayNumberTitle = "د نمبر ښودلو پاڼه",
	customerTakeNumberTitle = "د پیرودونکي د نمبر پاڼه",
	provideNumberTitle = "د شمیرې پاڼه چمتو کړئ",
	yourNumberTitle = "ستاسو د نمبر پاڼه",
	callNumberTitle = "د کال شمیره پاڼه",
	setting = "تنظیم",
	callNumberPadSoundSwitch = "غږ آن/بند",
	shutDown = "بند کړه",
	ok = "ښه",
	cancel = "لغوه کړه",
	startRunningShop = "دوکان چلول پیل کړئ",
	getToWork = "راځئ کار ته لاړ شو!",
	uploadSuccess = "آپلوډ بریالی شو!",
	uploadFailed = "آپلوډ پاتې شو!",
	imagePickerDialogTitle = "د خپل دوکان لوګو تازه کړئ",
	imagePickerDialogAction = "انځور وټاکئ",
	cropImageDialogTitle = "ستاسو لوګو ښودلو لپاره تایید کړئ",
	editShopNameDialogTitle = "د خپل دوکان نوم تازه کړئ"
)