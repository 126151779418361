import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "lo", default = false)
val LoStrings = Strings(
    projectNameDialogIntro1 = "ສະບາຍດີ! ທີ່ນີ້ພວກເຮົາຈະສ້າງບໍລິການປີ້ໝາຍເລກ",
	projectNameDialogIntro2 = "ກະລຸນາໃສ່ຊື່ຮ້ານຂອງທ່ານ:",
	projectNameDialogShopName = "ຊື່ຮ້ານ",
	submit = "ຍື່ນສະເຫນີ",
	projectOptionsDialogIntro = { "ສະບາຍດີ, ${it}, ທ່ານຕ້ອງການບໍລິການແນວໃດ:" },
	projectOptionsDialogShopType0Title = "ບໍລິການເອົາເລກງ່າຍໆ",
	projectOptionsDialogShopType0Description = "ເຈົ້າຂອງຮ້ານ/ຜູ້ຮັກສາສາມາດໂທຫາເລກໂດຍການແຕະງ່າຍໆໃນແອັບ, ເຮັດໃຫ້ການປະຕິບັດວຽກໄວແລະມີປະສິດທິພາບ.",
	projectOptionsDialogShopType1Title = "ບໍລິການເອົາໝາຍເລກລູກຄ້າ",
	projectOptionsDialogShopType1Description = "ລູກຄ້າສາມາດເອົາໝາຍເລກແລະລໍຖ້າການໂທເບີເລກ, ຮັບປະກັນວ່າການລໍຖ້າດຳເນີນການຢ່າງຮຽບລື່ນແລະໄວ.",
	projectOptionsDialogShopType2Title = "ສະໜອງບໍລິການເລກ",
	projectOptionsDialogShopType2Description = "ເຈົ້າຂອງຮ້ານ/ຜູ້ຮັກສາສະໜອງໝາຍເລກ, ແລະປັບປຸງປະສົບການໂທຫາໝາຍເລກໃຫ້ເໝາະກັບຄວາມຕ້ອງການທາງທຸລະກິດຂອງທ່ານດ້ວຍການຕັ້ງຄ່າທີ່ປັບແຕ່ງໄດ້.",
	vendorConsoleStartRunning = "ເລີ່ມວິ່ງ",
	numberDisplayScreenTitle = "ເອົາໝາຍເລກຢູ່ນີ້!",
	qrCodeDescription = { "ລະຫັດ QR ອ້າງອີງເວັບໄຊທ໌ ${it}" },
	takeNumberScreenInputPhoneNumber = "ໃສ່ໝາຍເລກໂທລະສັບຂອງທ່ານ",
	takeNumberScreenPhoneNumber = "ໝາຍເລກໂທລະສັບ",
	provideNumberPadGetNumber = "ເອົາໝາຍເລກ",
	provideNumberPadCurrentNumber = "ໝາຍເລກປະຈຸບັນ",
	provideNumberPadYourNumber = { "ໝາຍເລກຂອງທ່ານແມ່ນ ${it}" },
	info = "ຂໍ້ມູນ",
	callNumberPadCurrentWaitingNumber = { "ໝາຍເລກທີ່ລໍຖ້າປັດຈຸບັນ: ${it}" },
	displayNumberTitle = "ໜ້າຈໍສະແດງໝາຍເລກ",
	customerTakeNumberTitle = "ໜ້າໝາຍເລກລູກຄ້າ",
	provideNumberTitle = "ສະໜອງໜ້າເລກເດັດພໍ່",
	yourNumberTitle = "ໜ້າໝາຍເລກຂອງທ່ານ",
	callNumberTitle = "ໜ້າເລກໂທລະສັບ",
	setting = "ການຕັ້ງຄ່າ",
	callNumberPadSoundSwitch = "ສຽງ ເປີດ/ປິດ",
	shutDown = "ປິດ",
	ok = "ຕົກລົງ",
	cancel = "ຍົກເລີກ",
	startRunningShop = "ເລີ່ມເດີນການຮ້ານຄ້າ",
	getToWork = "ເລີ່ມຕົ້ນເຮັດວຽກ!",
	uploadSuccess = "ການອັບໂຫລດສໍາເລັດ!",
	uploadFailed = "ການອັບໂຫລດລົ້ມເຫລວ!",
	imagePickerDialogTitle = "ອັບເດດໂລໂກ້ຮ້ານຂອງທ່ານ",
	imagePickerDialogAction = "ເລືອກຮູບ",
	cropImageDialogTitle = "ຢືນຢັນໃຫ້ສະແດງໂລໂກ້ຂອງທ່ານ",
	editShopNameDialogTitle = "ອັບເດດຊື່ຮ້ານຂອງທ່ານ"
)