package tim.huang.genlayout.ui.utils

import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.layout
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp

internal fun Modifier.bottomBorder(strokeWidth: Dp, color: Color) = composed(
    factory = {
        val density = LocalDensity.current
        val strokeWidthPx = density.run { strokeWidth.toPx() }

        Modifier.drawBehind {
            val width = size.width
            val height = size.height - strokeWidthPx/2

            drawLine(
                color = color,
                start = Offset(x = 0f, y = height),
                end = Offset(x = width , y = height),
                strokeWidth = strokeWidthPx
            )
        }
    }
)

internal fun Modifier.mobileLayout() = layout { measurable, constraints ->
    val width = if (constraints.maxWidth.toFloat() / constraints.maxHeight < 3 / 4f )
        constraints.maxWidth else (constraints.maxHeight * 3 / 4f).toInt()
    val placeable = measurable.measure(constraints.copy(
        maxWidth = width,
        minWidth = width,
    ))
    layout(constraints.maxWidth, constraints.maxHeight) {
        val xPosition = (constraints.maxWidth - placeable.width) / 2
        placeable.place(x = xPosition, y = 0)
    }
}