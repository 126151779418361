import cafe.adriel.lyricist.LyricistStrings
import tim.huang.genlayout.resources.Strings    
            
@LyricistStrings(languageTag = "ko", default = false)
val KoStrings = Strings(
    projectNameDialogIntro1 = "안녕하세요! 여기에서 번호 티켓 서비스를 만들 것입니다",
	projectNameDialogIntro2 = "상점 이름을 입력하세요:",
	projectNameDialogShopName = "가게 이름",
	submit = "제출",
	projectOptionsDialogIntro = { "안녕하세요, ${it}, 어떤 종류의 서비스를 원하십니까:" },
	projectOptionsDialogShopType0Title = "간단한 번호 호출 서비스",
	projectOptionsDialogShopType0Description = "상점 주인/관리자는 앱에서 간단한 탭으로 번호를 호출하여 프로세스를 빠르고 효율적으로 만듭니다.",
	projectOptionsDialogShopType1Title = "고객 번호 서비스",
	projectOptionsDialogShopType1Description = "고객은 번호를 받아 호출 번호를 기다릴 수 있으며, 대기 과정이 원활하고 빠르게 진행되도록 합니다.",
	projectOptionsDialogShopType2Title = "번호 서비스를 제공하다",
	projectOptionsDialogShopType2Description = "상점 주인/관리자는 번호를 제공하고, 맞춤 설정 가능한 설정으로 비즈니스 요구 사항에 맞게 번호 호출 경험을 맞춤화합니다.",
	vendorConsoleStartRunning = "달리기 시작하다",
	numberDisplayScreenTitle = "여기에서 번호를 가져 가세요!",
	qrCodeDescription = { "${it} 웹사이트를 참조하는 QR 코드" },
	takeNumberScreenInputPhoneNumber = "전화번호를 입력하세요",
	takeNumberScreenPhoneNumber = "전화 번호",
	provideNumberPadGetNumber = "번호 받기",
	provideNumberPadCurrentNumber = "현재 번호",
	provideNumberPadYourNumber = { "귀하의 번호는 ${it}입니다" },
	info = "정보",
	callNumberPadCurrentWaitingNumber = { "현재 대기 번호: ${it}" },
	displayNumberTitle = "번호 표시 페이지",
	customerTakeNumberTitle = "고객 번호 페이지",
	provideNumberTitle = "번호 페이지 제공",
	yourNumberTitle = "귀하의 번호 페이지",
	callNumberTitle = "전화번호 페이지",
	setting = "설정",
	callNumberPadSoundSwitch = "소리 켜기/끄기",
	shutDown = "종료",
	ok = "확인",
	cancel = "취소",
	startRunningShop = "가게 운영 시작",
	getToWork = "일을 시작합시다!",
	uploadSuccess = "업로드 성공!",
	uploadFailed = "업로드 실패!",
	imagePickerDialogTitle = "상점 로고를 업데이트하세요",
	imagePickerDialogAction = "이미지를 선택하세요",
	cropImageDialogTitle = "로고를 표시할지 확인하세요",
	editShopNameDialogTitle = "상점 이름을 업데이트하세요"
)